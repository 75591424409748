const UserRoles1 = ["superadmin"]
const UserRoles2 = ["superadmin", "admin"]
const UserRoles3 = ["superadmin", "admin", "reservations_user"]
const UserRoles4 = ["admin", "reservations_user"]

export {
    UserRoles1,
    UserRoles2,
    UserRoles3,
    UserRoles4

}