import { useEffect, useState } from 'react';


/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import { GetMenu } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// self
import { Iinfo, Imenu } from '../../types/interface';
import { MenuTable } from '../../components';



dayjs.extend(weekOfYear)





interface Iprops {
    info: Iinfo | undefined
}
const Menu = (props:Iprops) => {
    const { info } = props;

    const dispatch: AppDispatch = useDispatch()
    
    const [week, setWeek] = useState<number>(dayjs().week())  
    const [menu, setMenu] = useState<Imenu []>([])
   
    const [categories, setCategories] = useState<string []>([])

    const [loading, setLoading] = useState<boolean>(true)
    const [failedFetch, setFailedFetch] = useState<boolean>(false)

    
    useEffect(() => {
        setLoading(true)
        FetchMenu()                       
      
        return () => {
            
        }
    }, [week])
   
    const FetchMenu = async() => {
      
        try {
            await dispatch(GetMenu({}))
                .unwrap()
                .then((res) => {      
                                                                     
                    setMenu([...res?.body?.data?.Menu || []])                                                                            
                    setCategories([...res?.body?.data?.Category || []])                                                                                                  
                    
            })   
        } catch (error) {            
            console.log(error)
            setFailedFetch(true)
        }     
        setLoading(false)
    }
    


    

    return (
        <MenuTable 
            info={info?.Menu}
            TypeOfMenu={"menu"} 
            parentMenu={menu}
            parentCategories={categories}
            //week={week}
            //ChangeWeek={setWeek}
        
            failedFetch={failedFetch}
            fetchLoading={loading}
            //columns={columns}
        />
    )
}



export default Menu



