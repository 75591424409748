import { Fragment, useState, useMemo, forwardRef, ChangeEvent } from 'react'
import Resizer from "react-image-file-resizer";
import { useDropzone } from 'react-dropzone'

// mui
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Typography  from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'




import SaveIcon from '@mui/icons-material/Save'





//* Redux
import { useDispatch, useSelector } from 'react-redux'
import  { AppDispatch }  from '../../store'
import { UpdatePassword, UpdateProfileImage, CreateAccount } from '../../slices/tokenReducer'
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface'
import { UserRoles4 } from '../../functions/UserRoles';
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/* Dropzone Style */
const dropzoneStyle = {
    baseStyle: {
        flex: 1,
        display: 'flex',
        FlexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    focusedStyle: {
        borderColor: '#2196f3'
    },
    acceptStyle: {
        borderColor: '#00e676'
    },
    rejectStyle: {
        borderColor: '#ff1744'
    }

}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Paper className='flex__center-c' elevation={3} sx={{padding:"1rem"}}>            
                {children}
            </Paper>
          </Box>
        )}
      </div>
    );
}
function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Iprops {
    info: Iinfo | undefined
}
const Account = (props:Iprops) => {
    const { info } = props
    
    const dispatch: AppDispatch = useDispatch()
    
    const userdata = useSelector((state: any) => state?.token?.user)
        
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<string | undefined>("")
    
    
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")

    const initnewaccount = {
        email: "",
        password:"",
        userRole:""
    }
    const [newAccount, setNewAccount] = useState(initnewaccount)

    const imageinit = {
        key:"",
        file:"",
        type:""
    }
    const [newProfileImage, setNewProfileImage] = useState(imageinit)

    const snackbarinit = {success: false, error: false, warning: false}
    const [openSnackbar, setOpenSnackbar] = useState(snackbarinit)

  

    //* Reset everything
    const ResetAll = () => {
        setNewProfileImage(imageinit)
        setNewPassword("")
        setOldPassword("")
    }
    //* Save New Password
    const SavePassword = async() => {
        setMessage("")
        if(oldPassword === "" ){
            setMessage(info?.Account?.Error_OldPassword)
            OpenSnackbar("warning")
            setLoading(false)
            return
        }else if(newPassword === ""){
            setMessage(info?.Account?.Error_NewPassword)
            OpenSnackbar("warning")
            setLoading(false)
            return
        }
        setLoading(true)
        const tokenres = await TokenRefresh() 
        if(tokenres !== 200) {setLoading(false);return}

        
        await dispatch(UpdatePassword({email: userdata?.email, oldPassword, newPassword}))
            .unwrap()
            .then(res => {
                if(res?.statusCode === 200){
                    setMessage(info?.Account?.Success_password)
                    OpenSnackbar("success")                    
                    setLoading(false)
                }else{
                    setMessage(res?.body?.message || "Unknow Error")
                    OpenSnackbar("error")                    
                    setLoading(false)
                }
                    setOldPassword("")
                    setNewPassword("")
            })

        
    }
     //* Save New Account
     const SaveAccount = async() => {
        setMessage("")
        if(newAccount?.email === "" ||newAccount?.password === "" || newAccount?.userRole === "" ){
            setMessage(info?.Account?.Error_NewAccountState_Empty)
            OpenSnackbar("warning")
            setLoading(false)
            return
        }
        setLoading(true)
        const tokenres = await TokenRefresh() 
        if(tokenres !== 200) {
            setLoading(false)
            return
        }

        
        await dispatch(CreateAccount(newAccount))
            .unwrap()
            .then(res => {
                if(res?.statusCode === 200){
                    setMessage(info?.Account?.Success_NewAccount)
                    OpenSnackbar("success")                    
                    setLoading(false)
                }else{
                    setMessage(res?.body?.message || "Unknow Error")
                    OpenSnackbar("error")                    
                    setLoading(false)
                }
                setNewAccount(initnewaccount)
            })

        
    }
    //* Save New Profile Image
    const SaveProfileImage = async() => {
        setMessage("")
        if(newProfileImage?.file === "" ){
            setMessage(info?.Account?.Error_NoProfileImage)
            OpenSnackbar("warning")
            setLoading(false)
            return
        }
        setLoading(true)
        const tokenres = await TokenRefresh() 
        if(tokenres !== 200) {setLoading(false);return}

        await dispatch(UpdateProfileImage({email: userdata?.email, newProfileImage}))
            .unwrap()
            .then(res => {
                if(res?.statusCode === 200){
                    setMessage(info?.Account?.Success_ProfileImage)
                    OpenSnackbar("success")                    
                    setLoading(false)
                }else{
                    setMessage(res?.body?.message || "Unknow Error")
                    OpenSnackbar("error")                    
                    setLoading(false)
                }
                    setOldPassword("")
                    setNewPassword("")
            })

        
    }




    //* DROPZONE */ 
    const onDrop = async (acceptedFiles:any) => {
      
        try {
          
            const compressedImage:any = await resizeFile(acceptedFiles[0])
           
            let newFile = {
                key: `profileimages/${userdata?.userId}.webp`,
                file: compressedImage,
                type: "images/webp"
            }
         
            setNewProfileImage(newFile)
                       
        } catch (error) {
           
            setMessage(info?.Account?.FileError)
            OpenSnackbar("error")                
        }  
    }      
    const resizeFile = (file:any) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        900,            // max width
        900,            //max height
        "WEBP",         // file output
        50,             // 100 best quality, 50, middle, 1 lowest quality
        0,
        (uri) => {
        resolve(uri);
        },
        "base64"
    );
    });
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {'image/*': []}});
    const style = useMemo(() => ({
        ...dropzoneStyle?.baseStyle,
        ...(isFocused ? dropzoneStyle?.focusedStyle : {}),
        ...(isDragAccept ? dropzoneStyle?.acceptStyle : {}),
        ...(isDragReject ? dropzoneStyle?.rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);






    //* Snackbar Alert  */
    const OpenSnackbar = (alert:string) => {
        setOpenSnackbar(prev=>({...prev, [alert]:true}))
    }
    const CloseSnackbar = (event?: React.SyntheticEvent | Event , reason?: string, alert:string="") => {               
        setOpenSnackbar(prev=>({...prev, [alert]:false}))
        //setOpenSnackbar(false);        
    } 
   




    //* Tabs, which section to see  */
    const [tabIndex, setTabIndex] = useState(0)
    const ChangeSection = (event:React.SyntheticEvent,newValue:number) => {          
        setTabIndex(newValue)
    }

    const HandleNewAccount = (event: ChangeEvent<HTMLInputElement>) => {
        setNewAccount(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="xl" sx={{padding:"0 !Important"}}>                


                {/* Tabs  */}
                <Box className='flex__center-c'>
                    <Tabs
                        value={tabIndex}
                        onChange={ChangeSection}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                        sx={{maxWidth:"100vw"}}
                    >
                {/*  <Tabs value={tabIndex} onChange={ChangeSection} aria-label="basic tabs example"> */}
                        
                            
                        <Tab label={info?.Account?.UpdateProfilePicture} {...a11yProps(0)}  sx={{color:"primary.main"}} />
                        <Tab label={info?.Account?.UpdatePwd} {...a11yProps(1)} sx={{color:"primary.main"}}/>
                        <Tab label={info?.Account?.NewAccount} {...a11yProps(2)} sx={{color:"primary.main"}}/>
                    </Tabs> 
                                
                </Box>  
                
                 

                {/* Each tab content */}
                {/* 0. change profile img */}
                {/* 1. change password */}
                {/* 2. new account */}
                <CustomTabPanel value={tabIndex} index={0}>
                    
                        <Typography variant='h6'>{info?.Account?.UpdateProfilePicture}</Typography>
                        <Avatar  alt={userdata?.email} src={`https://${process.env.REACT_APP_CDN_URL}/${userdata?.profileImage}`} sx={{ width: 60, height: 60,margin:"1rem 0" }}/>
                        <Typography >{userdata?.email}</Typography>

                        {/* Profile Image */}
                        {/* DropZone */}
                        <div className='flex__center-c' >
                            <div {...getRootProps({style})}>
                                <input {...getInputProps()} />
                                <p>{newProfileImage?.key || info?.Account?.DropAndDrag}</p>
                            </div>
                        </div>

                        <Button
                            sx={{maxWidth: '280px'}}
                            variant="contained"
                            color='success'
                            disabled={loading}
                            onClick={SaveProfileImage}
                            endIcon={<SaveIcon />}
                        >
                        {info?.Account?.Button_UpdateImage}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "teal",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                        )}
                        </Button>   

                        {/* Reset Button */}
                        <Button variant="outlined" color="warning" sx={{minWidth: '180px'}} onClick={ResetAll}>
                            {info?.Account?.Reset}
                        </Button>                                                  
                    

                </CustomTabPanel>             

                <CustomTabPanel value={tabIndex} index={1}>
                    <Typography variant='h5'>{info?.Account?.UpdatePwd}</Typography>
                    <TextField
                        name='oldPassword'
                        id="oldPasswordid"
                        sx={{label:{color:"grey.400"}}}   
                        label="Old Password"
                        size='small'
                        margin='normal'
                        type='password'
                        value={oldPassword}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {                   
                            setOldPassword(event.target.value)
                        }}                                        
                    /> 
                    <TextField                
                        name='newPassword'
                        id="newPasswordid"
                        sx={{label:{color:"grey.400"}}}   
                        label="New Password"                        
                        size='small'
                        type='password'
                        margin='normal'
                        value={newPassword}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {                   
                            setNewPassword(event.target.value)
                        }}                                        
                    /> 

                    <Button
                        sx={{maxWidth: '280px'}}
                        variant="contained"
                        color='success'
                        disabled={loading}
                        onClick={SavePassword}
                        endIcon={<SaveIcon />}
                    >
                    {info?.Account?.Button_UpdatePwd}
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: "teal",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </Button>                                                           

                
                </CustomTabPanel>

                <CustomTabPanel value={tabIndex} index={2}>
                    <Typography variant='h5'>{info?.Account?.NewAccount}</Typography>
                    <TextField
                        name='email'                        
                        sx={{label:{color:"grey.400"}}}   
                        label="Email"
                        size='small'
                        margin='normal'
                        type='text'
                        value={newAccount?.email}
                        onChange={HandleNewAccount}
                    /> 
                    <TextField                
                        name='password'                        
                        sx={{label:{color:"grey.400"}}}   
                        label="Password"                        
                        size='small'
                        type='password'
                        margin='normal'
                        value={newAccount?.password}
                        onChange={HandleNewAccount}                                      
                    /> 
                    <Typography variant='subtitle1'>
                        {info?.Account?.Select_UserRole_Exp}
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{info?.Account?.Select_UserRole}</InputLabel>
                        <Select                                                        
                            value={newAccount?.userRole}
                            label={info?.Account?.Select_UserRole}
                            name="userRole"
                            onChange={(event: SelectChangeEvent)=> setNewAccount(prev => ({
                                    ...prev,
                                    [event?.target.name]:event?.target?.value
                                }))}
                        >
                            {UserRoles4?.map((userRole,i) => (
                                <MenuItem key={`selectuserrole${i}`} value={userRole}>{userRole}</MenuItem>
                            ))}
                                                        
                        </Select>
                    </FormControl>

                    <Button
                        sx={{maxWidth: '280px'}}
                        variant="contained"
                        color='success'
                        disabled={loading}
                        onClick={SaveAccount}
                        endIcon={<SaveIcon />}
                    >
                    {info?.Account?.Button_NewAccount}
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: "teal",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </Button>                                                           

                
                </CustomTabPanel>

         
             
            


         

                {/* Alertbars / Snackbars  */}
                <Snackbar open={openSnackbar?.success} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"success")} >
                    <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","success")} severity="success" sx={{ width: '100%' }} >
                        {message}
                    </Alert>
                </Snackbar>

                <Snackbar open={openSnackbar?.error} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"error")} >
                    <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","error")} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <Snackbar open={openSnackbar?.warning} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"warning")} >
                    <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","warning")} severity="warning" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                
            </Container>
        </Fragment>   
    )
  
}

export default Account