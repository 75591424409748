import { useState, ChangeEvent, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';


import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';




// Redux
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { UpdateInfo } from '../../slices/tokenReducer'


// Self
import { TokenRefresh } from '../../functions/Token';
import { Ieditinfo, Iinfo, Iinfodb } from '../../types/interface';
import { SnackbarComponent } from '../index';




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#03fc41',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
}));




interface Iprops {
    info: Iinfo | undefined
    editInfo: Ieditinfo

}
//* MAIN TABLE */
const InfoTable = (props:Iprops) =>{        

    const { info, editInfo } = props

    const dispatch: AppDispatch = useDispatch()
     
    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    const [loading, setLoading] = useState(false)
    const [jsonInfo, setJsonInfo] = useState<Ieditinfo>(editInfo)
  /*   const [infoDb, setDbInfo] = useState<Iinfodb>(infoFromDb as Iinfodb)
 */
   
    
    //* Updates states when rendering first time or change of props  */
    useEffect(() => {
        setJsonInfo(editInfo)
      /*   setDbInfo(infoFromDb as Iinfodb) */
      return () => {
        
      }
    }, [editInfo])




    
    
    
    
    //* Changes value of string and number */
    const ChangeValue = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,firstKey: string, secondKey: string )=> {
        const { name, value } = event.target;            

        const firstIndex = firstKey as keyof Ieditinfo

        setJsonInfo((prev) => ({
            ...prev,
            [firstKey]:{
                ...prev?.[firstIndex],
                [secondKey]: value  
            }
        }))

        
        
        
        
    }
    //* Change True of False on Boolean values */
    const ChangeActiveState = (event:ChangeEvent<HTMLInputElement>)=> {
        const { name, value } = event.target;  

        type NestedKeyOf<Ieditinfo extends object> = 
        {[Key in keyof Ieditinfo]: Ieditinfo[Key]};

        if(jsonInfo){
            const tempinfojson: NestedKeyOf<Ieditinfo> = structuredClone(jsonInfo)
            //let infojsonCategory = tempinfojson[name]

        }
        

        
        //if(infojsonCategory){infojsonCategory[name] = value}
        //if(value !== undefined) setJsonInfo(prev => ({...prev, [name]:  !value }))        
        
    }
 
    //* Reset All changes  */
    const ResetAll = () => {
        setJsonInfo(editInfo)
    
    }
    //* Save Changes at Server  */
    const SaveChanges = async () => {   
        setMessage("")    

        if(loading){
           return 
        }             

        setLoading(true)

        const tokenres = await TokenRefresh() 
        if(tokenres !== 200) {
            setLoading(false)
            return
        }

        await dispatch(UpdateInfo({ information: jsonInfo }))
        .unwrap()
            .then((res) => {                                                  
                if(res?.statusCode === 200){               						                                   
                    setMessage("Success!")
                    setAlert("success")
                    setLoading(false)
                                                                
                }else{                
                    setMessage(res?.body?.message || "Unknow Error")
                    setAlert("error")
                    setLoading(false)
                }
            })        
                   
        
    }


  return (
    <Box className="flex__center-c" sx={{width:"98vw"}}>

        <Container                                
            sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
        >
            <Button
                sx={{width: '180px'}}
                variant="contained"
                color='success'
                disabled={loading}
                onClick={SaveChanges}
                endIcon={<SaveIcon />}
            >
            {info?.Info?.Save}
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                    color: "teal",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    }}
                />
            )}
            </Button>

            <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={ResetAll}>
                {info?.Info?.Reset}
                </Button>
            
        </Container>


     

        {/* editinfo.json  */}
        {Object.keys(jsonInfo || {}).map((firstKey:string,i) => {
            
            const GetObjectValueWithKey = (str: string ) => {              
                const tempobj = jsonInfo || {}                                                                                                                                                          
                return tempobj[str as keyof typeof tempobj];
            };
            const nestedObject:any = GetObjectValueWithKey(firstKey)
            //const nestedObject = jsonInfo ? jsonInfo?.[firstKey] : undefined;

            return(
                <Box key={`jsoninfo${i}`} sx={{width:"98%"}} className="flex__center-c">
                    <Typography>{firstKey}</Typography>
                    {/* Json Info */}
                    {Object.keys(nestedObject || {}).map((key:string,j) => {
                    
                    // some how coverts the key to something that typescript lets me use as an index key and then return its value                           
                

                    const value = key
                    const typeofeditinfo = typeof value
                    const keyoftestjson = firstKey as keyof typeof jsonInfo
                    if(typeofeditinfo === "number" ){
                        return (
                            <Item key={`textinfonum${i}${j}`}  sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>                       
                                <TextField 
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    fullWidth               
                                    name={key}
                                    sx={{label: {color:"grey.400"}}}      //
                                    
                                    label={key.replaceAll("_"," ")}
                                    size='small'
                                    value={
                                        jsonInfo?.[ keyoftestjson ]?.[ key as keyof typeof jsonInfo[ typeof keyoftestjson ] ] 
                                    }

                                    onChange={(event)=>ChangeValue(event, firstKey, key )}

                                
                                />                
                            </Item>
                            )
                    }else if(typeof value === "string" && key !== "_id"){
                        
                        return (
                            <Item key={`textinfostring${i}${j}`} sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>                      
                                <TextField                      
                                    fullWidth        
                                    sx={{label: {color:"grey.400"}}}       
                                    name={key}
                                    multiline
                                    
                                    label={key.replaceAll("_"," ")}
                                    size='small'
                                    value={ jsonInfo?.[ keyoftestjson ]?.[ key as keyof typeof jsonInfo[ typeof keyoftestjson ] ]  }
                                    onChange={(event)=>ChangeValue(event, firstKey, key )}
                                />                
                            </Item>
                            )
                    }else if(typeof value === "boolean" ){
                        var booleanValue:boolean = !!value
                        return (
                        <ListItem key={`textinfoboolean${i}${j}`}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{info?.Info?.Off}</Typography>
                                <AntSwitch 
                                    checked={booleanValue}
                                    onChange={ChangeActiveState}
                                inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>{info?.Info?.On}</Typography>
                            </Stack>
                        </ListItem>
                        )
                    }else{
                        
                        return
                    }

                    })}        
                </Box>
            )
        })}
                            
    
        
        <SnackbarComponent alert={alert} setAlert={setAlert} setMessage={setMessage} message={message} />


    </Box>
  );
}

export default InfoTable