import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
// Mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

// mui icons
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

// REDUX
import {  AddShop, UpdateShop } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// self
import { Iinfo, Imenu } from '../../types/interface';
import { SwitchComponent, SnackbarComponent } from '../../components'

const TypesOfMenu = ["leftover","takeaway"]
const iteminit = {
    price:0,
    oldPrice:0,
    quantity:0,
    title:"",
    info:"",
    category:"",
    sk:"takeaway",
    active:true,        
}
interface Iprops {        
    item: Imenu    
    setItem: (p:React.SetStateAction<Imenu>) => void
    open: boolean
    Close: (p:React.SetStateAction<boolean>) => void                
    info: Iinfo,
    categories: {[key:string]:string[]}       
    setMenu: (p:React.SetStateAction<Imenu []>) => void
    type: string
}
const LeftoverItem = (props:Iprops) => {
    const { item, open, Close, info, categories,setMenu, type, setItem } = props

    useEffect(() => {
        setDialogItem(item)      
        if(item?.sk && categories?.[item?.sk]) ChangeTypeOfMenu(item?.sk)
        
        return () => {}
    }, [item, categories])
    
    const dispatch: AppDispatch = useDispatch()
    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    const [dialogItem, setDialogItem] = useState<Imenu>(item)
    const [dialogLoading, setDialogLoading] = useState(false)
    const [ selectedCategory, setSelectedCategory] = useState<string []>([])
    //console.log("categories inside",categories)
    //console.log("selectedCategory inside",selectedCategory)
 
    const HandleTextFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setDialogItem(prev => ({...prev,[name]:value}))
    }
    const HandleBoolean = (value:boolean)=>{
        setDialogItem(prev => ({...prev,active: value }))
    }
    const DialogSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setDialogLoading(true)
        if(type === "create"){
            await CreateShopItemApi(dialogItem)        
        }else if(type === "update"){
            await UpdateShopItemApi(dialogItem)        
        }
        
        setDialogLoading(false)
    }
    const CreateShopItemApi  = async(newitems:Imenu):Promise<void> => {
        const id = uuidv4()
        newitems["id"] = id
        newitems["pk"] = id
        //newitems["sk"] = newitems?.sk
        
        await dispatch(AddShop({newItem:newitems, allCategories: categories}))
            .unwrap()
            .then(res => {
                
                if(res?.statusCode === 200){  
                    setMenu(prev => ([...prev, newitems]))                
                    setAlert("success")
                    setMessage("Item Created Successfully")
                    CloseAndReset()
                }else if(res?.message){
                    setAlert("error")
                    setMessage(res?.message)
                }else{
                    setAlert("error")
                    setMessage("something went wrong")
                }
                
             
            })

      
    }
   
    const UpdateShopItemApi  = async(newitems:Imenu):Promise<void> => {  
        await dispatch(UpdateShop({updatedItem:newitems, allCategories: categories}))
            .unwrap()
            .then(res => {
           
                if(res?.statusCode === 200){                                        
                    setMenu(prev => (
                        prev.map(item => item.pk === newitems.pk? newitems : item)        
                    ))                    
                    setAlert("success")
                    setMessage("Item Updated Successfully")
                    CloseAndReset()
                }else if(res?.message){
                    setAlert("error")
                    setMessage(res?.message)
                }else{
                    setAlert("error")
                    setMessage("something went wrong")
                }
                
                //setOpenItemDialog(false)
            })

      
    }
    const CloseAndReset = () => {
        setDialogLoading(false)
        setDialogItem({} as Imenu)
        setItem(iteminit as Imenu)
        Close(false)
    }
    const ChangeTypeOfMenu = (typeofmenu:string) => {     
        setDialogItem(prev => ({...prev,sk: typeofmenu}))
        setSelectedCategory(categories[typeofmenu] || [])        
    }
    return (
        <Box>
            <Dialog
                open={open}
                onClose={CloseAndReset}         
                
            >
                {/* Title and exit button */}
                <DialogTitle className="flex__center-r" sx={{justifyContent:"space-around"}}>

                    <Typography variant="subtitle1" >
                        {type} item
                    </Typography>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={CloseAndReset}
                        
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>


                <DialogContent>                
                    <DialogActions >                                             
                        <form className="flex__center-c" onSubmit={DialogSubmit}>   
                        
                        

                            {/* Button Save */}
                            <Button
                            // sx={{width: '180px'}}
                                variant="contained"
                                color='success'
                                type='submit'
                                disabled={dialogLoading}
                                //onClick={UploadUpdatedEvent}
                                endIcon={<SaveIcon />}
                            >
                                {info?.Event?.Save}
                                {dialogLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                            )}
                            </Button>
                    
                            <Typography>Change Type of Menu</Typography>
                            <Select
                            // color='secondary'
                                value={dialogItem?.sk}
                                onChange={(event)=>ChangeTypeOfMenu(event?.target?.value)}
                                size="small"
                                sx={{ height: 1, mb:"1rem" }}
                                native            
                                fullWidth         
                                disabled={type ==="update"?true:false}
                            >
                                {TypesOfMenu.map((category,i) => <option key={`TypesOfMenu${category}${i}`}>{category}</option> )}                        
                            </Select>
                
                            <Typography>Change Category</Typography>
                            <Select
                                value={dialogItem?.category}                        
                                onChange={(event)=>setDialogItem(prev => ({...prev,category: event?.target?.value}))}
                                size="small"
                                sx={{ height: 1 }}
                                native       
                                fullWidth                         
                            >
                                {(selectedCategory?.concat("")).map((category,i) => <option key={`option${category}${i}`}>{category}</option> )}                        
                            </Select>

                            <TextField       
                                required                         
                                label={`Title`}
                                sx={{m:"0.5rem 0",label:{color:"grey.400"}}}                                                                                 
                                multiline
                                fullWidth 
                                maxRows={4}
                                name={`title`}
                                value={dialogItem?.title}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleTextFields(event);
                                }}
                            />
                            <TextField required sx={{m:"0.5rem 0",label:{color:"grey.400"}}} multiline fullWidth maxRows={4} label={`Info`}
                                name={`info`}
                                value={dialogItem?.info}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleTextFields(event);
                                }}
                            />
                            <TextField required sx={{m:"0.5rem 0",label:{color:"grey.400"}}} multiline fullWidth maxRows={4} label={`Price`} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                name={`price`}
                                value={dialogItem?.price}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleTextFields(event);
                                }}
                            />
                            {dialogItem?.sk === "leftover" && 
                                <TextField required sx={{m:"0.5rem 0",label:{color:"grey.400"}}} multiline fullWidth maxRows={4} label={`oldPrice`}  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    name={`oldPrice`}
                                    value={dialogItem?.oldPrice}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        HandleTextFields(event);
                                    }}
                                />
                            }
                            <TextField required sx={{m:"0.5rem 0",label:{color:"grey.400"}}} multiline fullWidth maxRows={4} label={`Quantity`} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                name={`quantity`}
                                value={dialogItem?.quantity}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleTextFields(event);
                                }}
                            />
                            <SwitchComponent 
                                value={typeof dialogItem?.active === "boolean" ? dialogItem?.active : false}
                                SetValue={HandleBoolean}
                                info={info}
                            />


                    


                            
                        </form>                    
                    </DialogActions>
                </DialogContent>    

               


            </Dialog>
            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />
        </Box>
    )
}



export default LeftoverItem