import { useState } from 'react'
// mui
import Paper from '@mui/material/Paper'
//import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';


// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

/* Drag and drop  */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// REDUX
import { Iinfo } from '../../types/interface';


interface Iprops {
    info: Iinfo
    rows: string [] 
    setRows:(p:React.SetStateAction<string []>) => void
}
const DragAndDropItems = (props:Iprops) => {
    const { info, rows, setRows } = props
   
    const [categoryTextField, setCategoryTextField] = useState<string>("")

    //* Drag and Drop functions */
    const reorder = (list:any, startIndex:any, endIndex:any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };
    const handleDrop = (droppedItem: any) => {
        
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;


        const sourceIndex = droppedItem.source.index;
        const destIndex = droppedItem.destination.index;
        if(droppedItem.type === "MENUTYPES"){    
            const newMenu:any = reorder(rows, sourceIndex, destIndex)
            setRows([...newMenu]);
           
        
        }

    };
    const changeOrder = (dir:string,i:number) =>{   
        var tempArr:any = [...rows]
        var value = rows[i]
        tempArr.splice(i,1)
        if(dir==="up"){
        tempArr.splice(i+1,0,value)
        }else{
        tempArr.splice(i-1,0,value)
        }  
        setRows([...tempArr])
        
    }
    const AddNewCategory = (newCategory:string) => {
        const stringNewCategory = newCategory.toString()
        if(!stringNewCategory) return
        if(rows?.includes(stringNewCategory)) return
        let tempCategoryArray = [...rows]
        tempCategoryArray.push(stringNewCategory)                                      
        
        setRows([...tempCategoryArray])
        setCategoryTextField("")
    }
    const DeleteCategory = (itemToBeDeleted:string) => {
        setRows(prev => (
            prev.filter(item => item !== itemToBeDeleted? item : null)        
        ))  
    }


    return (
     
 
        <Paper sx={{marginTop:"2rem",padding:"1rem 0"}} className="flex__center-c">

            {/* Title */}
         {/*    <Typography variant='h3' paragraph>
                {info?.Event?.Order_Of_Events}
            </Typography> */}


            {/* Add a new Category */}
            <Box className="flex__center-r">
                <TextField 
                    sx={{label:{color:"text.primary"}}}
                    label="Add A New Category"
                    size='small'
                    value={categoryTextField}                    
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCategoryTextField(event?.target?.value) }
                />                                                
                <Button onClick={()=>AddNewCategory(categoryTextField)} sx={{marginLeft:"1rem"}} variant="outlined" size="small" startIcon={<AddIcon />}>
                    {info?.Leftover?.AddNewItem}
                </Button>
            </Box>
         


            {/* Drag and drop elements */}
            <DragDropContext onDragEnd={handleDrop} >
                <Droppable droppableId="Droppable-container" type='MENUTYPES'>
                    {(provided:any) => (
                        <div                                                
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            >
                            {rows?.map((item, i) => (              
                                <Draggable key={`draggable${i}${item}`} draggableId={`draggableId${i.toString()}${item}`} index={i} > 
                                    {(provided) => (                                    
                                        <div                                        
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                    
                                            <Paper sx={{margin:"0.5rem", padding:"0.5rem", minWidth:"15rem",justifyContent:"space-between"}} className='flex__center-r'>
                                                <Box>
                                                    <IconButton onClick={()=>changeOrder("up",i)}> <KeyboardArrowDownIcon /></IconButton>
                                                    <IconButton onClick={()=>changeOrder("down",i)} > <KeyboardArrowUpIcon /></IconButton>
                                                </Box>                                                
                                                {item}                                                                                      
                                                <IconButton onClick={()=>DeleteCategory(item)} > <DeleteIcon color='error'/> </IconButton>
                                            </Paper>
                                                                                                                                                        
                                        </div>                                
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>     

     </Paper>
        
    )
}

export default DragAndDropItems