import { useEffect, Fragment, useState, forwardRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';


/* Data grid imports */
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';


/* Snackbar */
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';



// REDUX
import { GetReservations, UpdateReservations } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { TokenRefresh } from '../../functions/Token';

// self
import { Icalendar, Iinfo, Ireservation } from '../../types/interface';
import { SnackbarComponent,ReservationDialog, SimpleBackdropLoading } from '../../components/index'


/* Snackbar */
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



interface Iprops {
    info: Iinfo
}
const Reservations = (props:Iprops) => {
    const { info } = props
    
	const dispatch: AppDispatch = useDispatch()


    /* Table */
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [loading, setLoading] = useState(false)
   
   
  

    interface ObjectAll {[key:string]:string|number|boolean}
    const [rows, setRows] = useState<ObjectAll []>([])    
    const [rowsCalendar, setRowsCalendar] = useState<Icalendar []>([])    
    const [oldData, setOldData] = useState<ObjectAll []>([])
    const [removedRows, setRemovedRows] = useState<GridRowSelectionModel>([]);

  
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState<Ireservation>({} as Ireservation)
  
    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    

    //* GetReservations from db */
    useEffect(() => {
        setLoading(true)
        try {
            const myfunc = async() => {           
                const res = await TokenRefresh()                                 
                if(res !== 200) {
                    setLoading(false)
                    return
                }                
                await dispatch(GetReservations({}))
                .unwrap()
                .then((res:any) => {
                   
                    if(res?.statusCode === 200){               						                                                           
                        setLoading(false)
                        setOldData([...res?.body?.data || []])
                        setRows([...res?.body?.data || []])                                                                
                    }else{                
                        setMessage(res?.body?.message || info?.Reservations?.FailedFetch || "Failed to fetch data")
                        setAlert("error")
                        setLoading(false)
                    }
                    
                })
            }
            myfunc()
        } catch (error) {
            setMessage(info?.Reservations?.FailedFetch)
            setAlert("error")
            setLoading(false)
        }
        
        return () => {
        
        }
    }, [])


    //* More info dialog
    const HandleOpenInfoDialog = (row:Ireservation) => {
        setOpenDialog(true)
        setSelectedInfo(row)

    }
 

    const RemoveItems = () => {
        let tempRow = [...rows]
        let tempSelect = [...rowSelectionModel]        
        
        for (let i = 0; i < tempSelect.length; i++) {
            const selectedId = tempSelect[i];
            const filteredRow = tempRow?.find((row) => row.id === selectedId)
            const indexOfRemovedItem = tempRow?.indexOf(filteredRow || {})                                    
            tempRow?.splice(indexOfRemovedItem,1)                        
        }                
        setRemovedRows([...tempSelect])
        setRows([...tempRow])
    }
    const SaveChanges = async() => {
        setLoading(true)
        setMessage("")
        if(removedRows.length <= 0) {
            setMessage(info?.Reservations?.NoChangesMade)
            setAlert("warning")
            setLoading(false)
            return
        }
        const res = await TokenRefresh()                                 
        if(res !== 200) return
        await dispatch(UpdateReservations({updatedReservation: removedRows}))
            .unwrap()
            .then((res:any) => {
               
                if(res?.statusCode === 200){               						                                                           
                    setMessage(info?.Reservations?.UpdateSuccess || "Success")
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.Reservations?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    const ResetAll = () => {
        setRows([...oldData])
        setRemovedRows([])
    }


    const columns: GridColDef[] = [
       /*  { field: 'id', headerName: 'ID', width: 90 }, */
       {
            field: 'infobutton',
            headerName: 'More info',
            width: 120,
            editable: false,                        
            renderCell: (params: any) =>  {     
                        
                return (                    
                    <Chip icon={ <InfoIcon /> } color="primary" label={"More Info"} variant="outlined" size='small' sx={{cursor:"pointer"}} onClick={()=>HandleOpenInfoDialog(params?.row)}/>
                    
                )
            }                     
        }, 
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        
          
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
         {
            field: 'email',
            headerName: 'Email',          
            width: 200,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
       {
            field: 'date',
            headerName: 'Date',                    
            type: 'datetime',
            
            width: 110,
            editable: false,
            valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),
            renderCell: (params: any) =>  {               
                let displayVal =  new Date(params.value).toLocaleDateString()
                return (
                    <Tooltip title={displayVal} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{displayVal}</span>
                    </Tooltip>
                    )
            }
        },
        {
            field: 'time',
            headerName: 'Time',          
            type: 'datetime',
            width: 110,
            editable: false,
            valueGetter: ({ value }) => value && new Date(value),
            renderCell: (params: any) =>  {               
                let displayVal = new Date(params.value).toLocaleTimeString("sv-SE", {timeZone: 'Europe/Stockholm', hour: "2-digit", minute: "2-digit" })
                return (
                    <Tooltip title={displayVal} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{displayVal}</span>
                    </Tooltip>
                    )
            }
        },
        {
            field: 'people',
            headerName: 'Guests',          
            width: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        }, 
        {
            field: 'phone',
            headerName: 'Phone',          
            width: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
        {
            field: 'type',
            headerName: 'Type',          
            width: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
        {
            field: 'other',
            headerName: 'Other',          
            width: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        
        },
        

        
       
    ];

    return (
        <Fragment>

            {/* Save and Reset Button */}
            <Container                                
                sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
            >
                <Button
                    sx={{width: '180px'}}
                    variant="contained"
                    color='success'
                    disabled={loading}
                    onClick={SaveChanges}
                    endIcon={<SaveIcon />}
                >
                {info?.Reservations?.Save}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "teal",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />
                )}
                </Button>

                <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={ResetAll}>
                    {info?.Reservations?.Reset}
                    </Button>
               
            </Container>

            <CssBaseline /> 


            {/* Toolbar with Name of the table, selected items and delete button */}
            <Toolbar
        
                sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(rowSelectionModel.length > 0 && {
                    bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                }}
            >
                {rowSelectionModel.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {rowSelectionModel.length} {info?.Reservations?.Selected}
                    
                </Typography>
                
                ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {info?.Reservations?.MainTitle}              
                
                </Typography>
                
                )}
            
            {rowSelectionModel.length > 0 && (
                <Tooltip title={info?.Reservations?.DeleteTooltip}>
                    <IconButton  onClick={RemoveItems}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            )}
        
            </Toolbar>

            {/* Data grid */}
            <Box sx={{ height: "100%", width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5,10,100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    {...rows}
                />
            </Box>


            {/* open information for a single item */}
            <ReservationDialog 
                selectedValue={selectedInfo}
                open={openDialog}
                setOpenDialog={setOpenDialog}
                info={info}
                setRows={setRowsCalendar}
                readOnly={true}
            />
                       
            <SnackbarComponent alert={alert} setAlert={setAlert} message={message} setMessage={setMessage}/>


        </Fragment>   
    )
}

export default Reservations



