import React, { useEffect, useState } from 'react';

// Mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';


// mui icons
import EditIcon from '@mui/icons-material/Edit';


/* Data grid imports */
import { DataGrid, GridColDef,  GridRenderCellParams } from '@mui/x-data-grid';

/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import {  GetShop } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// self
import { Iinfo,  Imenu } from '../../types/interface';
import { DragAndDropItems, LeftoverItem, SimpleBackdropLoading } from '../../components'



dayjs.extend(weekOfYear)





interface Iprops {
    info: Iinfo
}
const Leftover = (props:Iprops) => {
    const { info } = props;

    const dispatch: AppDispatch = useDispatch()
    
  
    const [menu, setMenu] = useState<Imenu []>([])

    const [categories, setCategories] = useState<string []>([])
    //console.log("categories",categories)
    const [loading, setLoading] = useState<boolean>(true)
   // const [failedFetch, setFailedFetch] = useState<boolean>(false)
    const [openItemDialog, setOpenItemDialog] = useState(false)
    const [openUpdateItemDialog, setOpenUpdateItemDialog] = useState(false)
    interface Iallcategories {
        [key:string]: string []
    }
    const [ allCategoryObj, setAllCategoryObj ] = useState<Iallcategories>({} as Iallcategories)
    //console.log("allCategoryObj",allCategoryObj)
    const iteminit = {
        price:0,
        oldPrice:0,
        quantity:0,
        title:"",
        info:"",
        category:"",
        sk:"takeaway",
        active:true,        
    }
    const [item, setItem] = useState<Imenu>(iteminit as Imenu)
    const [tab, setTab] = useState(0)
   
    useEffect(() => {
        setLoading(true)
        FetchMenu()                       
      
        return () => {
            
        }
    }, [])
   
    //* API , GET, POST, PUT, DELETE
    const FetchMenu = async() => {
       
        try {
            await dispatch(GetShop({typeOfMenu: ""}))
                .unwrap()
                .then((res) => {      
                   
                    if(res.statusCode === 200){                        

                        const foundItems = res?.body?.data.filter((item:any) => item?.pk !== "category" )
                        interface IfoundCategory {sk:string,pk:string,categories:string[]}
                        const foundCategory:IfoundCategory [] = res?.body?.data.filter((item:any) => item?.pk === "category" )
                      
                        let temp_cat:Iallcategories = {}
                        foundCategory?.forEach((item) => {
                            temp_cat[item?.sk as keyof IfoundCategory] = item?.categories
                        })
                        setAllCategoryObj(temp_cat || {})
                        setMenu([...foundItems || []])                                                                            
                        //setCategories([...foundCategory?.[0]?.categories || []])
                        setCategories(temp_cat?.takeaway || [])
                                         
                    }else{
                       // setFailedFetch(true)            
                    }
                    
            })   
        } catch (error) {            
            console.log(error)
           // setFailedFetch(true)
        } 
        setLoading(false)
    }
 
    


    const OpenUpdateDialog = (params:GridRenderCellParams<Imenu>) =>{
        setOpenUpdateItemDialog(true)
        setItem(params?.row)
    }
    const columns = [
        {
            field: 'updatebtn',
            headerName: '',
            minWidth:150,
            renderCell: (params: GridRenderCellParams<Imenu>) => (                      
                <Button variant="contained" color='primary' endIcon={<EditIcon />} sx={{padding:"0 0.5rem !important"}} onClick={()=>OpenUpdateDialog(params)}>
                    {info?.Event?.Update_Button}
                </Button>
            ),
        },               
        {
            field: 'active',
            type: 'boolean',
            headerName: 'Active',                                
            minWidth: 200,
            editable: false,                               
        }, 
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 200,
            editable: false,                                
        },
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 150,
            editable: false,
            
        },             
        {
            field: 'sk',
            headerName: 'Type Of Menu',
            minWidth: 150,
            editable: false,
            
        },     
        {
            field: 'price',
            type: 'number',
            headerName: 'Price',                                
            minWidth: 100,
            editable: false,            
           /*  renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            } */
        },
        {
            field: 'oldPrice',
            type: 'number',
            headerName: 'Old Price',                                
            minWidth: 100,
            editable: false,            
          /*   renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            } */
        },
        {
            field: 'quantity',
            type: 'number',
            headerName: 'Quantity',                                 
            minWidth: 100,
            editable: false,            
            /* renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            } */
        }
    ]
    interface Imenutable {
       //info: {[key:string] : string} | undefined
       //type: string   
       //parentRow: Iparentrow [] | undefined        
       //failedFetch: boolean
       //fetchLoading: boolean
        columns: GridColDef []
       
    }
    const MenuTable = (props:Imenutable) => {
        const { columns } = props

        return( 
            <Box sx={{ height: "100%", width: '100%' }}>
                <DataGrid
                    rows={menu || [] }
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        filter: {
                            filterModel: {
                                items: [{ field: 'active', operator: 'is', value: "true" }],
                            },
                        },
                    }}
                    pageSizeOptions={[5,10,100]}                
                    disableRowSelectionOnClick
                    {...menu}
                   
                />
            </Box>
        )
    }
    const ChangeTab = (newValue:number) => {
        setTab(newValue)
        switch (newValue) {
            case 0:
                setCategories(allCategoryObj?.takeaway || [])
                break
            case 1:
                setCategories(allCategoryObj?.leftover || [])
                break
        }
    }
    useEffect(()=>{        
        switch (tab) {
            case 0:
                setAllCategoryObj(prev => ({...prev,takeaway: categories}) )
                break
            case 1:
                setAllCategoryObj(prev => ({...prev,leftover: categories}) )
                break
        }

    },[categories])
    return (
        <Box sx={{width:"100vw"}} className="flex__center-c">

            <Button sx={{m:"1rem 0"}}  variant='outlined' onClick={()=>setOpenItemDialog(true)}>Add new item</Button>

            <MenuTable 
                columns={columns}
            />

            {/* Tabs for categories */}
            <Box>
                {/* Selec which tab should be shown */}
                <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: number)=> ChangeTab(newValue) } centered textColor='primary' indicatorColor="primary">
                    <Tab label={info?.Leftover?.Tab_0} sx={{color:"primary.main",}}/>
                    <Tab label={info?.Leftover?.Tab_1} sx={{color:"primary.main",}}/>                    
                </Tabs>

                <DragAndDropItems
                    rows={categories}
                    setRows={setCategories}
                    info={info}
                />
            </Box>

                    
            <LeftoverItem 
                open={openItemDialog}
                Close={setOpenItemDialog}
                item={item}    
                setItem={setItem}
                info={info }
                categories={allCategoryObj}
                setMenu={setMenu}
                type={"create"}
            />

            <LeftoverItem 
                open={openUpdateItemDialog}
                Close={setOpenUpdateItemDialog}
                item={item}      
                setItem={setItem}                                         
                info={info }
                categories={allCategoryObj}
                setMenu={setMenu}
                type={"update"}
            />

            <SimpleBackdropLoading openbackdrop={loading} />

        </Box>

    )
}



export default Leftover



