import { useState, useEffect } from 'react'

// mui
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const dotStyle = {
    full: {
        color:"secondary",        
    },
    fade: {
        color:"#ffffff",        
    }
}

const Clock = () => {

    const [date, setDate] = useState(new Date())
    const [fade, setFade] = useState(true)

    useEffect(() => {
        const intervall = setInterval(() => Tick(),1000)    
        return () => {
            clearInterval(intervall)
        }
    }, [])
    
    const Tick = () => {
        setDate(new Date())
        setFade(prev => !prev)
    }

    return (
        <Box className="flex__center-r">
            <Typography variant='h1'>
                { date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit"} ).split(":")[0] } 
            </Typography>
            <Box sx={fade ? dotStyle?.fade : dotStyle?.full} className="flex__center-c">
                <Typography variant='h2'>
                :
                </Typography>
            </Box>
            <Typography variant='h1'>
                { date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit"} ).split(":")[1] } 
            </Typography>
        </Box>
  )
}

export default Clock