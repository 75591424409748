import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

/* Data grid imports */
import {  GridColDef } from '@mui/x-data-grid';


/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import { GetSubscribers } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// self
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface';
import { TextTable } from '../../components';



dayjs.extend(weekOfYear)



interface Irow {
    
    [key:string]:string
}

interface Iprops {
    info: Iinfo | undefined
}

const Subscribers = (props:Iprops) => {
    const { info } = props;

    const dispatch: AppDispatch = useDispatch()
        
    const [row, setRow] = useState<Irow []>([] as Irow[])    
    const [loading, setLoading] = useState<boolean>(true)
    const [failedFetch, setFailedFetch] = useState<boolean>(false)
 
    useEffect(() => {
        setLoading(true)
        FetchData()                       
      
        return () => {
            
        }
    }, [])
   

    const AddIdToSubscribersData = (data:Irow []) => {
        const newData = []
        for (let i = 0; i < data.length; i++) {
            const subscribersItem = data[i];
            subscribersItem["id"] = subscribersItem?.EmailAddress
            newData.push(subscribersItem)                    
        }
        return newData
    }

    const FetchData = async() => {

        const res = await TokenRefresh()
        if(res !== 200){
            setLoading(false)
            return
        }

       
        try {
            await dispatch(GetSubscribers({}))
                .unwrap()
                .then((res) => {                     
                    if(res.statusCode === 200){                        
                        let idAddedtoData = AddIdToSubscribersData( res?.body?.data )
                        setRow([...idAddedtoData])
                    }else{
                        setFailedFetch(true)            
                    }
                    
            })   
        } catch (error) {            
            console.log(error)
            setFailedFetch(true)
        }     
        setLoading(false)
    }
    




    
    
 


    //! Columns , Edit this for wanted data types
    const columns: GridColDef[] = [    
        {
            field: 'EmailAddress',
            headerName: 'Email',
            minWidth: 200,            
            editable: false,
            
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        
        
        },              
                            
    ];
    

    return (
        <TextTable 
            info={info?.Subscribers}
            type={"subscribers"} 
            parentRow={row}            
            failedFetch={failedFetch}
            fetchLoading={loading}
            columns={columns}
        />
    )
}



export default Subscribers



