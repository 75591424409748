import { useEffect, Fragment, useState, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid'

// mui
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';


/* Data grid imports */
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import { UpdateSns, DeletePdf, UpdateSubscribers, UpdateReservations } from '../../slices/tokenReducer'; 
import { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { TokenRefresh } from '../../functions/Token';

import { SnackbarComponent } from '../index';


dayjs.extend(weekOfYear)

const defaultWeek = dayjs().week()
const dynamodbList = ["takeaway","leftover"]
const lunchList = ["lunch","lunchweekend"]
const snsList = ["sns"]
const disableList = ["pdf","subscribers","reservationsettings"]
//const disableAddButton = ["subscribers"]


interface Iparentrow {
    [key: string]:string | number
}
interface Iprop {
    info: {[key:string] : string} | undefined
    type: string   
    parentRow: Iparentrow [] | undefined        
    failedFetch: boolean
    fetchLoading: boolean
    columns: GridColDef []
    setParentRow?: (p:React.SetStateAction<string []>) => void
   
}

const TextTable = (props:Iprop) => {
    const { info, type, parentRow, failedFetch, fetchLoading, columns, setParentRow} = props
    
    const dispatch: AppDispatch = useDispatch()


    // Rerenders the states from parent
    useEffect(() => {
        setRow( parentRow )        
        setRowReadOnly( parentRow)        
        if(failedFetch) {
            setMessage(info?.FailedFetch)
            setAlert("error")                        
        }
        setLoading(fetchLoading)
        return () => {
            
        }
    }, [parentRow,failedFetch,fetchLoading,info?.FailedFetch]);
    

 
              
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [loading, setLoading] = useState(fetchLoading)    
    const [row, setRow] = useState<Iparentrow [] | undefined>(parentRow)    
    const [rowReadOnly, setRowReadOnly] = useState<Iparentrow [] | undefined>(parentRow)    

    
    const [message, setMessage] = useState<string | undefined>("")    
    const [alert, setAlert] = useState<string>("")    
    

    const [newItems, setNewItems] = useState<Iparentrow []>([])
    const [updatedItems, setUpdatedItems] = useState<Iparentrow []>([])
    const [removedItems, setRemovedItems] = useState<GridRowSelectionModel>([])

   
    //* Uploads Changed data to serer
    const SaveChanges = async() => {
        setLoading(true)
        setMessage("")        
     
        const DistpatchSelector = () => {

            // sns
            if(snsList.includes(type)){
                return dispatch(UpdateSns({newItems, removedItems}))            

            // pdf
            }else if(type === "pdf"){
                const arrayOfAllPds = rowReadOnly?.map(item => item?.key)
                return dispatch(DeletePdf({removedItems, allPdfs:arrayOfAllPds}))
            }else if(type === "subscribers"){
                return dispatch(UpdateSubscribers({removedItems}))      
            }else if(type === "reservationsettings"){
                return dispatch(UpdateReservations({updatedReservation:removedItems}))       
            }else{
                return dispatch(UpdateSns({newItems, removedItems}))            
            }
        }
        const res = await TokenRefresh()                                 
        
        if(res !== 200) {setLoading(false) ;return}
        await DistpatchSelector().unwrap()
            .then((res) => {               
               
                if(res?.statusCode === 200){               						                                                           
                    setMessage(info?.UpdateSuccess || "Success")
                    if(type === "pdf" && setParentRow){
                        setParentRow(prev  => 
                            
                                prev.filter(item => !removedItems.includes(item) 
                            ) 
                        )
                    }
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    //* Resets Everything to before
    const ResetAll = () => {
        setRow([...rowReadOnly || []])
        
        setNewItems([])
        setUpdatedItems([])
        setRemovedItems([])
    }

   

  
    //* If type === "lunch" then when a week is changed, fetch the new lunchmenu  */
   /*  const FetchMenu = async(week:string) => {
        try {                        
            setLoading(true)
            if(type === "lunch"){        
                await dispatch(GetLunch({week:week}))
                    .unwrap()
                    .then((data:any) => {                     
                        setLoading(false)                                                   
                        setRow([...data?.Menu || []])                                                                
                        setRowReadOnly([...data?.Menu || []])
                        setCategories([...data?.Category || []])
                        setReadOnlyCategories([...data?.Category || []])        
                  
                
                })
            }else{
                await dispatch(GetMenu({}))
                    .unwrap()
                    .then((data:any) => {                     
                        setLoading(false)                                                   
                        setRow([...data?.Menu || []])                                                                
                        setRowReadOnly([...data?.Menu || []])
                        setCategories([...data?.Category || []])
                        setReadOnlyCategories([...data?.Category || []])           
                
                })
            }

            
        } catch (error) {
            setMessage(info?.FailedFetch)
            setAlert("error")
            setLoading(false)
        }
    } */
        
   
    //* Removes items from row
    const RemoveItems = () => {
        let tempRow = [...row || [] ]
        let tempNewMenuItems = [...newItems || [] ]
        let tempUpdatedMenuItems = [...updatedItems || [] ]
        let tempSelect = [...rowSelectionModel]        
        

        // loop through all items which has been selected for removal
        for (let i = 0; i < tempSelect.length; i++) {
            const selectedId = tempSelect[i];
            const foundMenuIdIndex = tempRow?.findIndex((row) => row.id === selectedId)

            // if it is takeaway or other row which uses dynamodb, remove items from a separate list
            
                
            const foundNewItemIdIndex = tempNewMenuItems?.findIndex((row) => row.id === selectedId)
            
            // if it is a newly added in newMenuItem state then remove that from that state array
            if(foundNewItemIdIndex !== -1) {
                tempNewMenuItems?.splice(foundNewItemIdIndex,1)
                setNewItems([...tempNewMenuItems])

            // if it is a old item in row state then add the id to the remove list
            }else if(foundMenuIdIndex !== -1){
                setRemovedItems(prev => ([...prev, selectedId]))
                const foundUpdatedItemIdIndex = tempUpdatedMenuItems?.findIndex((row) => row.id === selectedId)

                // if this is an old item which has been updated then remove it from the updateditem list
                if(foundUpdatedItemIdIndex !== -1){
                    tempUpdatedMenuItems?.splice(foundUpdatedItemIdIndex,1)
                    setUpdatedItems([...tempUpdatedMenuItems])
                }
            }
            
                        
            // if it is standard row or lunch which uses s3 , remove item from row list
            if(foundMenuIdIndex !== -1) {                         
                tempRow?.splice(foundMenuIdIndex,1)                        
            }
            
        }                
       
        setRow([...tempRow])

        
    }       
    //* Add New Menu Item
    const AddNewMenuItem = () => {
        if(disableList.includes(type)){
            return
        }

        const tempId = uuidv4()
        const newItem: Iparentrow = {
            id: tempId,
            Endpoint:"",
            SubscriptionArn:""
        }             
        setNewItems([newItem,...newItems || [] ])
        setRow([newItem,...row || [] ])
    }
    


    const CompareSimpleObjects = (oldRow:Iparentrow,newRow:Iparentrow) => {
     
        let filteredObject = {} as Iparentrow
        filteredObject["id"] = oldRow.id
        Object.keys(oldRow).map((oldKey,i) => {
            if(oldKey === "id") return
            const oldItem = oldRow[oldKey]
            Object.keys(newRow).map((newKey,i) => {
            const newItem = newRow[newKey]
            if(newKey === "id") return
            
            if(oldItem !== newItem && oldKey === newKey) {
                filteredObject[newKey] = newItem
            }
            
            })	
        })

     
        return filteredObject 
    }

    return (
        <Fragment>

            {/* Save and Reset Button */}
            <Container                                
                sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
            >   
                {/* Save Button */}
                <Button
                    sx={{minWidth: '180px'}}
                    variant="contained"
                    color='success'
                    disabled={loading}
                    onClick={SaveChanges}
                    endIcon={<SaveIcon />}
                >
                {info?.Save}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "teal",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />
                )}
                </Button>

                {/* Reset Button */}
                <Button variant="outlined" color="warning" sx={{minWidth: '180px'}} onClick={ResetAll}>
                    {info?.Reset}
                </Button>

                

            
            </Container>

            <CssBaseline /> 


            {/* Toolbar with Name of the table, selected items and delete button */}
            <Toolbar
        
                sx={{
                    width:"100%",
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(rowSelectionModel.length > 0 && {
                    bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                }}
            >
                {rowSelectionModel.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {rowSelectionModel.length} {info?.Selected}
                    
                </Typography>
                
                ) : (
                <Box className="flex__center-r">
                        
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h5"
                        id="tableTitle"
                        
                    >
                        {info?.MainTitle}              
                    
                    </Typography>
                    {!disableList?.includes(type) &&
                        <Button onClick={AddNewMenuItem} sx={{marginLeft:"1rem"}} variant="outlined" size="small" startIcon={<AddIcon />}>
                            {info?.AddNewItem}
                        </Button>
                    }
                </Box>
                
                
                )}
            
            {rowSelectionModel.length > 0 && (
                <Tooltip title={info?.DeleteTooltip}>
                    <IconButton  onClick={RemoveItems}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            )}
        
            </Toolbar>

            {/* Data grid */}
            <Box sx={{ height: "100%", width: '100%' }}>
                <DataGrid
                    rows={row || [] }
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5,10,100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    {...row}
                    processRowUpdate={ (newRow: any, oldRow: any) => {          
                        //console.log("oldRow",oldRow)
                        //console.log("newRow",newRow)
                        let rowId = row?.findIndex( row => row.id === newRow?.id)

                        // if it is takeaway or other row which uses dynamodb, change correct item in a separate list
                      
                        let readOnlylMenuId = rowReadOnly?.findIndex( row => row.id === newRow?.id)
                        //console.log("readOnlylMenuId",readOnlylMenuId)
                        let newItemId = newItems?.findIndex( row => row.id === newRow?.id)
                        let updatedItemId = updatedItems?.findIndex( row => row.id === newRow?.id)

                        // if it is a new item then change that item in the newItems list
                        if(newItemId !== -1){                                
                            let tempNewMenuItemsArr = [...newItems || [] ]
                            tempNewMenuItemsArr[newItemId ? newItemId : 0 ] = newRow
                            setNewItems([...tempNewMenuItemsArr])        

                        // if its for SNS then old items CANT be changed, only new items
                        }else if(snsList.includes(type)){                            
                            return oldRow
                        // if it is an old item which have been changed before
                        }else if(updatedItemId !== -1 && readOnlylMenuId !== -1 && rowReadOnly ){                                
                            let tempUpdateMenuItemsArr = [...updatedItems || [] ]
                            let tempNewRow = CompareSimpleObjects(rowReadOnly[readOnlylMenuId ? readOnlylMenuId : 0] ,newRow)

                            // if the old item has more keys than just id then add it to the updatedItems list
                            if(Object.keys(tempNewRow).length > 1){
                                tempUpdateMenuItemsArr[updatedItemId ? updatedItemId : 0 ] = tempNewRow
                                setUpdatedItems([...tempUpdateMenuItemsArr])        

                            // if the old item has only ONE key Which should be id then remove it from the updatedItems list
                            }else if(Object.keys(tempNewRow).length === 1){
                                tempUpdateMenuItemsArr.splice(updatedItemId,1)
                                setUpdatedItems([...tempUpdateMenuItemsArr])
                            }

                        // if it is an old item which has NOT been changed before
                        }else if(rowReadOnly){                                
                            let tempNewRow = CompareSimpleObjects(rowReadOnly[readOnlylMenuId ? readOnlylMenuId : 0] ,newRow)
                            setUpdatedItems([tempNewRow,...updatedItems || [] ])
                        }

                        
                        let tempRowArr = [...row || [] ]
                        tempRowArr[rowId ? rowId : 0 ] = newRow
                        setRow([...tempRowArr])
                        return newRow
                    }}
                    onProcessRowUpdateError={(error: any) => console.log("error",error) }
                
                />
            </Box>
              

            <SnackbarComponent alert={alert} message={message} setAlert={setAlert} setMessage={setMessage}/>

        </Fragment>   

    )
}

export default TextTable