import { useState, useEffect, forwardRef , memo } from 'react'
// mui
import Box from '@mui/material/Box'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

/* Snackbar */
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
 //* Alertbars / Snackbars 
 interface Isnack {
    message: string | undefined;
    //open: {success: boolean, error: boolean, warning:boolean} 
    alert: string 
    setMessage: (props:string)=> void
    setAlert: (props:string)=> void
}


const SnackBarComponent = (props:Isnack) => {
    const { message, alert, setMessage, setAlert } = props

    const snackbarinit = {success: false, error: false, warning:false}
    const alertList = ["success", "error", "warning"]
    const [openSnackbar, setOpenSnackbar] = useState(snackbarinit)  
    
    useEffect(() => {
        OpenSnackbar(alert)
    
        return () => {
        
        }
    }, [alert])
    

    //* Snackbar Alert  */
    const OpenSnackbar = (alert:string) => {
        if(alertList.includes(alert)) setOpenSnackbar(prev=>({...prev, [alert]:true}))
    }
    const CloseSnackbar = (event?: React.SyntheticEvent | Event , reason?: string, alert:string="") => {               
        setOpenSnackbar(prev=>({...prev, [alert]:false}))
        setMessage("")
        setAlert("")
    }

    return (
        <Box>
            <Snackbar open={openSnackbar?.success} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"success")} >
            <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","success")} severity="success" sx={{ width: '100%' }} >
                {message}
            </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar?.error} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"error")} >
            <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","error")} severity="error" sx={{ width: '100%' }}>
                {message}
            </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar?.warning} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"warning")} >
            <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","warning")} severity="warning" sx={{ width: '100%' }}>
                {message}
            </Alert>
            </Snackbar>
        </Box>

    )
}

export default SnackBarComponent