import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import jwt_decode from 'jwt-decode'




const serverAddress = process.env.NODE_ENV === "production" ? `https://api.${process.env.REACT_APP_COMPONENT}.se` : "http://localhost:5000"


interface decodedAccTokenInit  {
    exp: number
    iat: number
    roles: string
    tokenId: string
    userId: string
    email: string
    profileImage: string
}

//* Info
export const UpdateInfo = createAsyncThunk(
    "put/UpdateInfo/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updateinfo`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateInfoDb = createAsyncThunk(
    "put/UpdateInfoDb/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updateinfodb`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetInfoDb = createAsyncThunk(
    "get/GetInfoDb/admin",
    async(info: any,{ getState } ) => {
        try {                           
            const res = await axios.get(`${serverAddress}/getinfo`)        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetEditInfo = createAsyncThunk(
    "get/GetEditInfo",
    async(info: any,{ getState } ) => {
        try {                           
            const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/info/editinfo.json`,
                {
                    params:{ t: new Date().getTime()} // removes cache ??
                }
            )
            return { body: {data: res.data}, statusCode: res.status}

        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})


//* Event
export const AddEvent = createAsyncThunk(
    "put/AddEvent/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/addevent`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateEvent = createAsyncThunk(
    "put/UpdateEvent/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updateevent`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const DeleteEvent = createAsyncThunk(
    "put/DeleteEvent/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/deleteevent`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetEvent = createAsyncThunk(
    "get/GetEvent",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/event/event.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})



//* Sns
export const GetSns = createAsyncThunk(
    "get/GetSns/admin",
    async(info: any,{ getState } ) => {                
    try {

        const state:any = getState()
        
        let token = state?.token?.AccToken                  
        const res = await axios.get(`${serverAddress}/getsns/?AccToken=${token}`)
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const UpdateSns = createAsyncThunk(
    "put/UpdateSns/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatesns`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})

//* Pdf
export const UpdatePdf = createAsyncThunk(
    "put/UpdatePdf/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatepdf`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetPdf = createAsyncThunk(
    "get/GetPdf",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/pdf/pdf.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const DeletePdf = createAsyncThunk(
    "put/DeletePdf/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/deletepdf`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})


//* Shop
export const AddShop = createAsyncThunk(
    "post/AddShop/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.post(`${serverAddress}/addshop`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateShopItem = createAsyncThunk(
    "put/UpdateShopItem/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updateshopitem`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const RemoveShopItem = createAsyncThunk(
    "delete/RemoveShopItem/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
                    
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/removeshopitem`,info)   

            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetShop = createAsyncThunk(
    "get/GetShop",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getshop/?typeofmenu=${info?.typeOfMenu}`)
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})




export const GetGallery = createAsyncThunk(
    "get/GetGallery",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/gallery/gallery.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const GetNewsletterGallery = createAsyncThunk(
    "get/GetNewsletterGallery",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/newsletter/newsletter.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})


//* Newsletter
export const SendNewsletter = createAsyncThunk(
    "post/SendNewsletter/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.post(`${serverAddress}/sendnewsletter`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateNewsletterImage = createAsyncThunk(
    "put/UpdateNewsletterImage/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatenewsletterimage`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})




//* Reservations
export const GetReservations = createAsyncThunk(
    "get/GetReservations/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            let token = state?.token?.AccToken  
            const res = await axios.get(`${serverAddress}/getreservations/?AccToken=${token}`)        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateReservations = createAsyncThunk(
    "put/UpdateReservations/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatereservations`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetOneReservation = createAsyncThunk(
    "get/GetOneReservation/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            let token = state?.token?.AccToken  
            const res = await axios.get(`${serverAddress}/getonereservation/?AccToken=${token}&date=${info?.date}`)        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateReservationStatus = createAsyncThunk(
    "put/UpdateReservationStatus/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatereservationstatus`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const AddReservationClosed = createAsyncThunk(
    "put/AddReservationClosed/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.post(`${serverAddress}/addreservationclosed`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})


//* Subscribers
export const GetSubscribers = createAsyncThunk(
    "get/GetSubscribers/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            let token = state?.token?.AccToken  
            const res = await axios.get(`${serverAddress}/getsubscribers/?AccToken=${token}`)        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateSubscribers = createAsyncThunk(
    "put/UpdateSubscribers/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatesubscribers`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})




//* Payments
export const GetPayments = createAsyncThunk(
    "get/GetPayments/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            let token = state?.token?.AccToken  
            const res = await axios.get(`${serverAddress}/getpayments/?AccToken=${token}`)        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdatePayments = createAsyncThunk(
    "put/UpdatePayments/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatepayments`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdatePaymentsFast = createAsyncThunk(
    "put/UpdatePaymentsFast/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatepaymentsfast`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})



//* Menu
export const UpdateMenu = createAsyncThunk(
    "put/UpdateMenu/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatemenu`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetMenu = createAsyncThunk(
    "get/GetMenu",
    async(info: any,{ getState } ) => {        
        
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/menu/menu.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )    
    
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const GetLunch = createAsyncThunk(
    "get/GetLunch",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/menu/lunch${info?.week}.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
    return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const GetLunchWeekend = createAsyncThunk(
    "get/GetLunchWeekend",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/menu/lunchweekend${info?.week}.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})



//* Lunch
export const UpdateLunch = createAsyncThunk(
    "put/UpdateLunch/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatelunch`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})

//* Takeaway
export const UpdateShop = createAsyncThunk(
    "put/UpdateShop/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updateshop`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})




//* Gallery
export const UpdateGallery = createAsyncThunk(
    "put/UpdateGallery/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updategallery`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})












//* User
export const Login = createAsyncThunk(
    "post/Login/admin",    
    async(info: any,{ getState } ) => {             
    try {
        
        const res = await axios.post(`${serverAddress}/login`,info,{withCredentials:true})
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const Logout = createAsyncThunk(
    "delete/Logout/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()        
        
        let decodedAccToken:any = jwt_decode(state?.token?.AccToken  );  
    
        const res = await axios.delete(`${serverAddress}/logout/?userId=${decodedAccToken?.userId}` ,{withCredentials:true})        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const UpdateProfileImage = createAsyncThunk(
    "put/UpdateProfileImage/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()                        
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updateprofileimage`, info,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        })        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const UpdatePassword = createAsyncThunk(
    "put/updatepassword/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()                        
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updatepassword`, info,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        })        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const CreateAccount = createAsyncThunk(
    "post/CreateAccount/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()                        
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/createaccount`, info)        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const RefreshToken = createAsyncThunk(
    "get/RefreshToken/admin",
    async(info: any,{ getState } ) => {                
    try {
        const res = await axios.get(`${serverAddress}/refreshtoken/`,{ withCredentials: true })
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const ForgotPassword = createAsyncThunk(
    "get/ForgotPassword",    
    async(info: any,{ getState } ) => {                                     
        const res = await axios.post(`${serverAddress}/forgotpassword`,info )        
        return res.data
})
export const ConfirmForgotPassword = createAsyncThunk(
    "get/ConfirmForgotPassword",    
    async(info: any,{ getState } ) => {                                     
        const res = await axios.put(`${serverAddress}/confirmforgotpassword`,info )        
        return res.data
})



const initaluser = { email:"", userId:"", profileImage:"", roles:""}
const initalStateValue = { AccToken:"" , user: initaluser, status: "inital", refreshingToken: false}


const postSlice = createSlice({
    name:"token",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(RefreshToken.pending,(state, action) => {
            state.refreshingToken = true;                        
        })
        builder.addCase(RefreshToken.fulfilled,(state, action) => {
            state.refreshingToken = false;
            if(action?.payload?.statusCode === 200){
                state.AccToken = action?.payload?.body?.AccToken
                let decodedAccToken:decodedAccTokenInit = jwt_decode(action?.payload?.body?.AccToken);
                state.user = {
                    userId: decodedAccToken?.userId,    
                    roles: decodedAccToken?.roles, 
                    email: decodedAccToken?.email, 
                    profileImage: decodedAccToken?.profileImage
                }
            }else{
                state.AccToken = ""
            }
            
        })
        builder.addCase(RefreshToken.rejected,(state, action) => {
            state.refreshingToken = false;            
            state.AccToken = ""
            
            
        })
      
        builder.addCase(Logout.rejected,(state, action) => {
            state.AccToken = ""
            state.user = initaluser                        
        }) 
        builder.addCase(Logout.fulfilled,(state, action) => {
            state.AccToken = ""
            state.user = initaluser                       
        }) 
        builder.addCase(UpdateProfileImage.fulfilled,(state, action) => {                                            
            state.user = action?.payload?.body?.data            
            state.AccToken = action?.payload?.body?.AccToken                        
        }) 


        builder.addCase(Login.fulfilled,(state, action) => {            
           
            try {                            
                let decodedAccToken:decodedAccTokenInit = jwt_decode(action?.payload?.body?.AccToken);
             
                state.user = {
                    userId: decodedAccToken?.userId,    
                    roles: decodedAccToken?.roles, 
                    email: decodedAccToken?.email, 
                    profileImage: decodedAccToken?.profileImage
                }

            } catch (error) {
                state.AccToken = ""
                state.user = initaluser
            }
        }) 
        
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
        //AddMatcher triggers on all /fulfilled in this case
        builder.addMatcher((action) => action.type.endsWith('/admin/fulfilled'),(state,action)=>{               
            if(action?.payload?.statusCode === 401){
                //return {AccToken: "", user:{}, status: "succeeded"}
                state.AccToken = ""
                state.user = initaluser
                
            }           
            if(action?.payload?.body?.AccToken && !action.type.startsWith('put/UpdateProfileImage') ){
              
                try {                                    
                    let decodedAccToken:decodedAccTokenInit = jwt_decode(action?.payload?.body?.AccToken); 
                
                    state.AccToken = action?.payload?.body?.AccToken
                    
                    state.user = { 
                        userId: decodedAccToken?.userId,
                        roles: decodedAccToken?.roles, 
                        email: decodedAccToken?.email, 
                        profileImage: decodedAccToken?.profileImage
                    }
                } catch (error) {
                    state.AccToken = ""
                    state.user = initaluser
                }
                //return {AccToken : action?.payload?.body?.AccToken , user: { userId: decodedAccToken?.userId, role: decodedAccToken?.roles}, status: "succeeded"}
            }
           
           
        })        
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{      
            /*    if(action?.payload?.statusCode === 401){
                   return {AccToken: ""}
               }           
               if(action?.payload?.body?.AccToken){
                   return {AccToken : action?.payload?.body?.AccToken}                                
               }
               return */
              return
           })        
    }
})

const { reducer } = postSlice

export default reducer

 