import { useEffect, useState, forwardRef, memo, useMemo } from 'react';

import dayjs from 'dayjs';

// mui
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CircularProgress from '@mui/material/CircularProgress';

// mui icons
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
/* Snackbar */
import MuiAlert, { AlertProps } from '@mui/material/Alert';

//redux
import  { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'

// self
import { GetInfoDb, GetNewsletterGallery, GetReservations, SendNewsletter, UpdateNewsletterImage } from '../../slices/tokenReducer'; 
import { TokenRefresh } from '../../functions/Token';
import { FetchMenuData } from '../../functions/FetchMenuData';
import { SnackbarComponent,TimeTable,SimpleBackdropLoading, YesNoDialog, DragDrop, ShowNewsletter } from '../../components/index'
import { Iinfo, Iimage, Iinfodb, Imenu } from '../../types/interface';

/* Snackbar */
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const defaultWeek = dayjs().week()
const lunchList = ["lunch","lunchweekend"]
const allList = ["menu","lunch","lunchweekend","takeaway","standard"]
//* array with week numbers 1 - 52 for chosing week to see specific lunch menu
const allWeekNumbersArray = Array.from({length: 52}, (_, i) => (i + 1).toString())

interface Iprops {
    info: Iinfo
}
interface Imenufull {
    [key:string]: Imenu []
}

const Newsletter = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()

    /* Date picker */
    const [date, setDate] = useState<any>(dayjs())
    const [fetchLoading, setFetchLoading] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const [message, setMessage] = useState("")
    const imageinit ={key:"",    type:"",    file:""}
    const [image, setImage] = useState<Iimage>(imageinit)
    const [newsletterJson, setNewsletterJson] = useState<string []>([])
    const [newsletterJsonReadOnly, setNewsletterJsonrReadOnly] = useState<string [] >([])

    const [type, setType] = useState("menu")
    const [week, setWeek] = useState<number>(dayjs().week())  
    const [menu, setMenu] = useState<Imenufull>({} as Imenufull)    
    const [category, setCategory] = useState<string []>([])
    const [removedImagesArr, setRemovedImagesArr] = useState<string []>([])    
    const [alert, setAlert] = useState("")
    const [openYesNoDialog, setOpenYesNoDialog] = useState(false)
    const [openShowNewsletter, setOpenShowNewsletter] = useState(false)    
    const [tab, setTab] = useState(0)
    const [textField, setTextField] = useState({
        message:"",
        topic:""
    })
    
    

    //*Fetch json or Db data
    useEffect(() => {       
        async function func(){
            if(type === "standard"){
                setMenu({})
            }else{            
                setFetchLoading(true)
                const { menu, category, statusCode, alert, message } = await FetchMenuData(type,week)
              
                if(statusCode === 200){
                    setMenu(menu as Imenufull)
                    setCategory(category)
                }else{
                    setMessage(message)
                    setAlert(alert)
                }
                setFetchLoading(false)
            }

            await dispatch(GetNewsletterGallery({}))
                .unwrap()
                .then(res => {
                  
                    if(res?.statusCode === 200){
                        setNewsletterJson(res?.body?.data?.Newsletter)
                        setNewsletterJsonrReadOnly(res?.body?.data?.Newsletter)
                    }
                })
            
        }
        func()
        
        return () => {
            
        }
    }, [week,type])

    // Send the newsletter
    const SubmitNewsletter = async() => {
        try {                     

            const tokenres = await TokenRefresh() 
            if(tokenres !== 200) {           
                return
            }
            


            await dispatch(SendNewsletter({ 
                newImage: image,
                oldImages:newsletterJson,
                type,
                week,
                textField,
                menu
            }))
                .unwrap()
                .then(res => {
                    if(res?.statusCode === 200 ){
                        setAlert("success")
                        setMessage(info?.Newsletter?.Snackbar_Success)                    
                    }else{
                        setAlert("error")
                        setMessage( res?.body?.message || info?.Newsletter?.Snackbar_Error)
                    }
                })
            }
        catch (error) {
            setAlert("error")
            setMessage(info?.Newsletter?.Snackbar_Error)
        }
    }
  

    // Change which menu that should be displayed
    const ChangeTypeOfMenu = (event:SelectChangeEvent) => {
        const { value } = event?.target
        setType( value )
    }


    //* TypeSelector => type = menu | lunch12 | takeaway etc...    
    const TypeSelector = () => {
        return(
            <Box className='flex__center-c'>
                {/* Select whick type of menu */}
                <FormControl sx={{m:"1rem 0"}} >
                    <InputLabel sx={{color:"text.primary"}}>{info?.Newsletter?.Selected_Label}</InputLabel>
                    <Select                        
                        value={type}
                        sx={{minWidth:"15rem"}}
                        label={info?.Newsletter?.Selected_Label}
                        onChange={ChangeTypeOfMenu}                                            
                    >   
                        {allList?.map(type => (
                            <MenuItem key={`selecttype${type}`} value={type}>{type}</MenuItem>
                        ))}                                        
                    </Select>
                </FormControl>

                {/*  Pick Which Week is selected */}
                { lunchList.includes(type) &&
                <Box className="flex__center-c" sx={{margin:"1rem 0"}}>
                    <Autocomplete
                        color="text.secondary"
                        disablePortal                        
                        sx={{label:{color:"text.primary", minWidth: "25rem"}}}
                        options={allWeekNumbersArray}
                        onChange={(event: React.SyntheticEvent, value: string | null , reason: string, details?: any):void => setWeek(value ? parseInt(value) : defaultWeek)  }
                        value={week ? week.toString() : defaultWeek.toString() }                        
                        renderInput={(params) => <TextField {...params} label={info?.Pdf?.Autocomplete_TextField} />}
                    />
                    
                </Box>}
            </Box>
        )
    }

    const HandleTextFields = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event?.target
        setTextField(prev => 
            ({
                ...prev, [name]: value
            }))
    }

    //* Removes Image selected from newsletterJson and adds to removedImagesArr
    const RemoveOldImage = (key:string,k:number) => {         
        let tempImageArr = [...newsletterJson]     
        tempImageArr?.splice(k,1)       
        setNewsletterJson(tempImageArr) 
        setRemovedImagesArr(prev => [...prev, key])
        
    }
    //* Resets the newsletterJson to its original state && setRemovedImagesArr is set to empty []
    const ResetImages = () => {        
        setRemovedImagesArr([])
        setNewsletterJson(newsletterJsonReadOnly)
    }
    //* Selects image to be used from s3
    const SelectOldImage = (key:string) => {
        setImage({key:key, file:"", type:""})
    }
    const DeleteOldImagesAPI = async() => {
        try{
            setLoading(true)

            if(removedImagesArr?.length <= 0 || !removedImagesArr){
                setAlert("warning")
                setMessage(info?.Newsletter?.Snackbar_Error_NoRemovedImages)
                setLoading(false)
                return
            }


            

            const tokenres = await TokenRefresh() 
            if(tokenres !== 200) {           
                setLoading(false)
                return
            }

            await dispatch(UpdateNewsletterImage({                 
                updatedNewsletterJson:newsletterJson,
                removedImages: removedImagesArr
            }))
                .unwrap()
                .then(res => {
                    if(res?.statusCode === 200 ){
                        setImage(imageinit)
                        setAlert("success")
                        setMessage(info?.Newsletter?.Snackbar_Success_Deleted)                    
                    }else{
                        setAlert("error")
                        setMessage( res?.body?.message || info?.Newsletter?.Snackbar_Error)
                    }
                })
        }
        catch (error) {
            setAlert("error")
            setMessage(info?.Newsletter?.Snackbar_Error)
            setLoading(false)
    
        }
        setLoading(false)
    }
    
    


    return (
        <Box className="flex__center-c">      

            {/* Selec which tab should be shown */}
            <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: number)=> setTab(newValue) } centered textColor='primary' indicatorColor="primary">
                <Tab label={info?.Newsletter?.Tab_Create} sx={{color:"primary.main",}}/>
                <Tab label={info?.Newsletter?.Tab_Preview} sx={{color:"primary.main",}}/>
                <Tab label={info?.Newsletter?.Tab_Images} sx={{color:"primary.main",}}/>
            </Tabs>


            {/* TAB 0 => CREATE Newsletter */}
            {tab === 0 &&
                <Box className="flex__center-c">

                    <Button color='success' variant="contained" onClick={()=>setOpenYesNoDialog(true)} endIcon={<SendIcon />} sx={{margin:"1rem 0"}}>
                            {info?.Newsletter?.Button_Send}
                    </Button>

                    <CssBaseline />      


                    {/* Textfields / add image / select type of newsletter */}
                    <Container sx={{paddingTop:"2rem", display:'flex', flexDirection:'column', alignItems:'center'}} >        

                        

                    {/*    <Button variant='outlined' onClick={()=>setOpenShowNewsletter(true)}>
                            {info?.Newsletter?.Button_ShowMail}
                        </Button>
    */}
                        {TypeSelector()}
                                    
                        

                        <DragDrop setMessage={setMessage} setAlert={setAlert} setImage={setImage} info={info} image={image}
                            category={"newsletter"}
                            width={1080}
                            height={1080} 
                            type={"WEBP"} 
                            quality={80} 
                        />

                        {/* If an old image from s3 is selected show it here */} 
                {/*      {!image?.file && image?.key &&
                            <img
                                src={`https://${process.env.REACT_APP_CDN_URL}/${image?.key}`}            
                                style={{ width: '10rem', height: '10rem', objectFit:'cover'}}
                                alt={"newsletter"}
                                loading="lazy"
                            />
                        } */}


                        {/* Textfields, topic / message */}
                        <Paper sx={{p:"0.5rem",mt:"2rem"}}>
                            
                            <TextField name="topic"  onChange={HandleTextFields} value={textField?.topic} multiline fullWidth minRows={1} label={"Enter a Topic"} sx={{m:"2rem 0"}}>  

                            </TextField>


                            <TextField name="message"  onChange={HandleTextFields} value={textField?.message} multiline fullWidth minRows={4} label={"Enter message"} sx={{m:"2rem 0"}}>  

                            </TextField>
                        </Paper>
                                                                        
                    </Container>


                    
                </Box>
            }
            {/* TAB 1 => Preview Newsletter */}
            {tab === 1 && 
                <Box className="flex__center-c">
                    {/* Show Preview of newsletter */}
                    <ShowNewsletter menu={menu} textField={textField} open={openShowNewsletter} setOpen={setOpenShowNewsletter} image={image?.file || `https://${process.env.REACT_APP_CDN_URL}/${image?.key}`}/>
                </Box>
            }
            {/* TAB 2 => See Uploaded Images */}
            {tab === 2 && 
                <Box sx={{ flexGrow: 1, p: 2 }}>

                    {/* Save and reset buttons */}
                    <Box className="flex__center-c" sx={{mb:"2rem"}}>
                        <Button
                            sx={{width: '180px'}}
                            variant="contained"
                            color='success'
                            disabled={loading}
                            onClick={DeleteOldImagesAPI}
                            endIcon={<DeleteForeverIcon />}
                        >
                        {info?.Newsletter?.Button_DeletImages}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "teal",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                        )}
                        </Button>
                        <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={ResetImages}>
                            {info?.Newsletter?.Button_Reset}
                        </Button>
                    </Box>

                    {/* Images */}                                                        
                    <Grid
                        container
                        spacing={2}
                        sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                            borderRight: 'var(--Grid-borderWidth) solid',
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                        },
                    }}
                    >
                        {newsletterJson?.map((item,k) => {
                        
                            return(
                                <Box className="flex__center-c"  key={`newsletteroldimgkey${item}${k}`}>
                                    <Button variant='outlined' fullWidth onClick={()=>SelectOldImage(item)}> {info?.Newsletter?.Button_SelectImage} </Button>                                  
                                    <ImageListItem>       
                                
                                    <ImageListItemBar
                                            sx={{
                                                background:
                                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                            }}
                                            //title={item}
                                            position="top"
                                            actionIcon={
                                                <IconButton
                                                sx={{ color: 'white' }}
                                                aria-label={`star ${item}`}
                                                onClick={() => RemoveOldImage(item,k)}
                                                >
                                                <ClearIcon                                                
                                                    
                                                    color="primary"
                                                    sx={{ cursor: 'pointer',color: "#ffffff"}}                                                
                                                />
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                            />
                                    <img
                                        src={`https://${process.env.REACT_APP_CDN_URL}/${item}?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`https://${process.env.REACT_APP_CDN_URL}/${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    
                                        style={{ width: '164px', height: '164px', objectFit:'cover'}}
                                        alt={item}
                                        loading="lazy"
                                    />
                                    </ImageListItem>
                                </Box>
                        )})}
                    </Grid>
                </Box>
            }

            {/* Remove Dialog */}
            <YesNoDialog info={info} submitFunc={SubmitNewsletter} openYesNoDialog={openYesNoDialog} setOpenYesNoDialog={setOpenYesNoDialog}/>
         
            {/* Alertbars / Snackbars  */}
            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />

            <SimpleBackdropLoading openbackdrop={fetchLoading} />    

        </Box>   
    )
    
}

export default Newsletter