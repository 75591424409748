import React, { useEffect, useMemo, useState } from 'react';

//mui
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'


// REDUX
import { GetReservations } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { TokenRefresh } from '../../functions/Token';


import { Calendar, dayjsLocalizer, Views } from 'react-big-calendar'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// self
import { Iinfo, Ireservation, Icalendar } from '../../types/interface';
import { SnackbarComponent,ReservationDialog, SimpleBackdropLoading } from '../../components/index'
import { GetTimeRead } from '../../functions/GetTime';

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs().tz("Europe/Stockholm")
const localizer = dayjsLocalizer(dayjs);

const ColoredDateCellWrapper = ({ children }:any) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

interface Iprops {
    info: Iinfo
}


const ReservationCalendar = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()

    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<Icalendar []>([])
    const [selectedValue, setSelectedValue] = useState({} as Ireservation);
    const [openDialog, setOpenDialog] = useState(false);

    //* GetReservations from db 
    useEffect(() => {
        
        try {            
            FetchReservations()
        } catch (error) {
            setMessage(info?.Reservations?.FailedFetch)
            setAlert("error")
            setLoading(false)
        }
        
        return () => {
        
        }
    }, [])
    const FetchReservations = async() => {
        setLoading(true)
        const res = await TokenRefresh()                                 
        if(res !== 200) {
            setLoading(false)
            return
        }                
        await dispatch(GetReservations({}))
        .unwrap()
        .then((res:any) => {
            
            if(res?.statusCode === 200){               						                                                           
                setLoading(false)
                //setOldData([...res?.body?.data || []])
                const now = new Date()
                
                const temp = [
                {
                    id: 0,
                    title: 'All Day Event very long title',
                    //allDay: true,
                    start: new Date(1709119800000),
                    end: new Date(1709122500000),
                },
                {
                    id: 1,
                    title: 'Long Event',
                    start: new Date(2015, 3, 7),
                    end: new Date(2015, 3, 10),
                },

                {
                    id: 2,
                    title: 'DTS STARTS',
                    start: new Date(2016, 2, 13, 0, 0, 0),
                    end: new Date(2016, 2, 20, 0, 0, 0),
                },

                {
                    id: 3,
                    title: 'DTS ENDS',
                    start: new Date(2016, 10, 6, 0, 0, 0),
                    end: new Date(2016, 10, 13, 0, 0, 0),
                },

                {
                    id: 4,
                    title: 'Some Event',
                    start: new Date(2015, 3, 9, 0, 0, 0),
                    end: new Date(2015, 3, 10, 0, 0, 0),
                },
                {
                    id: 5,
                    title: 'Conference',
                    start: new Date(2015, 3, 11),
                    end: new Date(2015, 3, 13),
                    desc: 'Big conference for important people',
                },
                {
                    id: 6,
                    title: 'Meeting',
                    start: new Date(2015, 3, 12, 10, 30, 0, 0),
                    end: new Date(2015, 3, 12, 12, 30, 0, 0),
                    desc: 'Pre-meeting meeting, to prepare for the meeting',
                },
                {
                    id: 7,
                    title: 'Lunch',
                    start: new Date(2015, 3, 12, 12, 0, 0, 0),
                    end: new Date(2015, 3, 12, 13, 0, 0, 0),
                    desc: 'Power lunch',
                },
                {
                    id: 8,
                    title: 'Meeting',
                    start: new Date(2015, 3, 12, 14, 0, 0, 0),
                    end: new Date(2015, 3, 12, 15, 0, 0, 0),
                },
                {
                    id: 9,
                    title: 'Happy Hour',
                    start: new Date(2015, 3, 12, 17, 0, 0, 0),
                    end: new Date(2015, 3, 12, 17, 30, 0, 0),
                    desc: 'Most important meal of the day',
                },
                {
                    id: 10,
                    title: 'Dinner',
                    start: new Date(2015, 3, 12, 20, 0, 0, 0),
                    end: new Date(2015, 3, 12, 21, 0, 0, 0),
                },
                {
                    id: 11,
                    title: 'Planning Meeting with Paige',
                    start: new Date(2015, 3, 13, 8, 0, 0),
                    end: new Date(2015, 3, 13, 10, 30, 0),
                },
                {
                    id: 11.1,
                    title: 'Inconvenient Conference Call',
                    start: new Date(2015, 3, 13, 9, 30, 0),
                    end: new Date(2015, 3, 13, 12, 0, 0),
                },
                {
                    id: 11.2,
                    title: "Project Kickoff - Lou's Shoes",
                    start: new Date(2015, 3, 13, 11, 30, 0),
                    end: new Date(2015, 3, 13, 14, 0, 0),
                },
                {
                    id: 11.3,
                    title: 'Quote Follow-up - Tea by Tina',
                    start: new Date(2015, 3, 13, 15, 30, 0),
                    end: new Date(2015, 3, 13, 16, 0, 0),
                },
                {
                    id: 12,
                    title: 'Late Night Event',
                    start: new Date(2015, 3, 17, 19, 30, 0),
                    end: new Date(2015, 3, 18, 2, 0, 0),
                },
                {
                    id: 12.5,
                    title: 'Late Same Night Event',
                    start: new Date(2015, 3, 17, 19, 30, 0),
                    end: new Date(2015, 3, 17, 23, 30, 0),
                },
                {
                    id: 13,
                    title: 'Multi-day Event',
                    start: new Date(2015, 3, 20, 19, 30, 0),
                    end: new Date(2015, 3, 22, 2, 0, 0),
                },
                {
                    id: 14,
                    title: 'Today',
                    start: new Date(new Date().setHours(new Date().getHours() - 3)),
                    end: new Date(new Date().setHours(new Date().getHours() + 3)),
                },
                {
                    id: 15,
                    title: 'Point in Time Event',
                    start: now,
                    end: now,
                },
                {
                    id: 16,
                    title: 'Video Record',
                    start: new Date(2015, 3, 14, 15, 30, 0),
                    end: new Date(2015, 3, 14, 19, 0, 0),
                },
                {
                    id: 17,
                    title: 'Dutch Song Producing',
                    start: new Date(2015, 3, 14, 16, 30, 0),
                    end: new Date(2015, 3, 14, 20, 0, 0),
                },
                {
                    id: 18,
                    title: 'Itaewon Meeting',
                    start: new Date(2015, 3, 14, 16, 30, 0),
                    end: new Date(2015, 3, 14, 17, 30, 0),
                },
                {
                    id: 19,
                    title: 'Online Coding Test',
                    start: new Date(2015, 3, 14, 17, 30, 0),
                    end: new Date(2015, 3, 14, 20, 30, 0),
                },
                {
                    id: 20,
                    title: 'An overlapped Event',
                    start: new Date(2015, 3, 14, 17, 0, 0),
                    end: new Date(2015, 3, 14, 18, 30, 0),
                },
                {
                    id: 21,
                    title: 'Phone Interview',
                    start: new Date(2015, 3, 14, 17, 0, 0),
                    end: new Date(2015, 3, 14, 18, 30, 0),
                },
                {
                    id: 22,
                    title: 'Cooking Class',
                    start: new Date(2015, 3, 14, 17, 30, 0),
                    end: new Date(2015, 3, 14, 19, 0, 0),
                },
                {
                    id: 23,
                    title: 'Go to the gym',
                    start: new Date(2015, 3, 14, 18, 30, 0),
                    end: new Date(2015, 3, 14, 20, 0, 0),
                },
                {
                    id: 24,
                    title: 'DST ends on this day (Europe)',
                    start: new Date(2022, 9, 30, 0, 0, 0),
                    end: new Date(2022, 9, 30, 4, 30, 0),
                },
                {
                    id: 25,
                    title: 'DST ends on this day (America)',
                    start: new Date(2022, 10, 6, 0, 0, 0),
                    end: new Date(2022, 10, 6, 4, 30, 0),
                },
                {
                    id: 26,
                    title: 'DST starts on this day (America)',
                    start: new Date(2023, 2, 12, 0, 0, 0),
                    end: new Date(2023, 2, 12, 4, 30, 0),
                },
                {
                    id: 27,
                    title: 'DST starts on this day (Europe)',
                    start: new Date(2023, 2, 26, 0, 0, 0),
                    end: new Date(2023, 2, 26, 4, 30, 0),
                },
                ]
                
                setRows(ReConstructReservationsForCalendar([...res?.body?.data || []]) )
                //setRows(temp)
            }else{                
                setMessage(res?.body?.message || info?.Reservations?.FailedFetch || "Failed to fetch data")
                setAlert("error")
                setLoading(false)
            }
            
        })
    }
    //console.log("rows",rows)
    
    const ReConstructReservationsForCalendar = (data:Ireservation []) => {
        let returnArr = []
        for (let i = 0; i < data.length; i++) {
            const reservation = data[i];                        
            if(reservation?.pk?.split("#")[0] === "closed"){
                returnArr.push({
                    id: i,
                    title: `CLOSED` ,
                    start: new Date(reservation?.time),
                    end: new Date(reservation?.time + reservation?.duration),
                    resource: reservation
                })
            }else{            
                returnArr.push({
                    id: i,
                    title: `${reservation?.firstName} ${reservation?.lastName} - ${reservation?.people} Guests` ,
                    start: new Date(reservation?.time),
                    end: new Date(reservation?.time + reservation?.duration),
                    resource: reservation
                })
            }
        }
        return returnArr
    }

    const { components, defaultDate, max, views,min } = useMemo(
        () => ({
            components: {
                timeSlotWrapper: ColoredDateCellWrapper,
            },
            defaultDate: new Date(),
            min:  dayjs().hour(7).minute(0).second(0).millisecond(0).toDate(),//dates.add(dates.endOf(new Date(2015, 17, 1,21,0,0,0), 'day'), -1, 'hours'),
            max: dayjs().hour(23).minute(0).second(0).millisecond(0).toDate(),
            //max:  dayjs().endOf('day').subtract(1, 'hours').toDate(),//dates.add(dates.endOf(new Date(2015, 17, 1,21,0,0,0), 'day'), -1, 'hours'),            
            //min: dayjs().hour(7).minute(0).second(0).millisecond(0),
            //min: new Date().setHours(7,0,0,0),
            //max: new Date().setHours(21,0,0,0),
            views: Object.keys(Views).map((k:any) => Views[k as keyof typeof Views]),
        }),
        []
    )


    return (
        <Box sx={{minHeight:"100vh",maxWidth:"100vw",justifyContent:"flex-start",mt:"1rem"}} className="flex__center-c">
            <Paper sx={{width:"96vw"}}>
                <Calendar
                    components={components}
                    defaultDate={defaultDate}
                    events={rows}
                    localizer={localizer}
                    //max={dayjs(max).toDate()}           // start time for clock ex 10pm
                    //min={dayjs(min).toDate()} // start time for clock ex 7am
                    max={max}           // start time for clock ex 10pm
                    min={min}           // start time for clock ex 10pm
                    //min={new Date(min)} // start time for clock ex 7am
                    showMultiDayTimes
                    formats={{
                        timeGutterFormat:"HH:mm",            
                        agendaTimeRangeFormat:(range: {start: Date, end: Date}) => `${  GetTimeRead(range.start) }-${ GetTimeRead(range.end) }`,
                        eventTimeRangeFormat:(range: {start: Date, end: Date}) => `${  GetTimeRead(range.start) }-${ GetTimeRead(range.end) }`,
                    }}
                    
                    onSelectEvent={(event:Icalendar)=>{
                        const tempData = event?.resource
                        setSelectedValue(tempData)
                        setOpenDialog(true)
                    }}
                    eventPropGetter={(event: Icalendar, start: Date, end: Date, isSelected: boolean) => {                        
                        const style_here = {backgroundColor:"#478F26"} 
                        const style_nothere = {backgroundColor:"#D48831"}
                        const style_closed = {backgroundColor:"#8d8d8d"}
                        if(event?.resource?.pk?.split("#")[0] === "closed"){
                            return {style:style_closed}
                        }else if(event?.resource?.status){
                            return {style:style_here}
                        }else{
                            return {style:style_nothere}
                        }
                        
                        
                    }}
                    step={30}
                    views={views}
                />
            </Paper>

        
            <ReservationDialog 
                selectedValue={selectedValue}
                open={openDialog}
                setOpenDialog={setOpenDialog}
                info={info}
                setRows={setRows}
            />

            <SnackbarComponent alert={alert} setAlert={setAlert} setMessage={setMessage} message={message} />

            <SimpleBackdropLoading openbackdrop={loading} />

        </Box>
    )
}

export default ReservationCalendar