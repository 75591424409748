import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//mui
import  TextField  from '@mui/material/TextField';
import  Typography  from '@mui/material/Typography';
import  Button  from '@mui/material/Button';
import  Box  from '@mui/material/Box';
import  CircularProgress  from '@mui/material/CircularProgress';


// REDUX
import { ConfirmForgotPassword } from '../../slices/tokenReducer' 
import { AppDispatch }  from '../../store'



const ForgotPassword = () => {
  		
	const dispatch:AppDispatch  = useDispatch()

	let { id, userid } = useParams();  
	const [msg, setMsg] = useState<string>("")	
  	const [password, setPassword] = useState<string>("")
    const [ loading, setLoading] = useState<boolean>(false)

  	const Submit = async(e:any) => {	
		e.preventDefault()
        setLoading(true)
		setMsg("")      
		await dispatch(ConfirmForgotPassword({id, userId: userid, password}))
            .unwrap()
            .then((res:any) => {	
                
                if(res?.statusCode === 200){
                    setMsg("Ditt lösenord skall vara bytt")
                    setLoading(false)
                }else{
                    setMsg("Något gick fel")
                    setLoading(false)
                }								
		})
	}	 


  return (
    <Box className='flex__center-c' height={"100vh"}>
      <Box component="form" className='flex__center-c' onSubmit={Submit}>
			<Typography variant='h5' >Byt lösenordet</Typography>            
			<TextField name='email' margin='normal' type='password' value={password} onChange={(e)=>setPassword(e?.target?.value)} label="Nytt Lösenord"></TextField>
			<Button type="submit" variant="contained" disabled={loading}>
                Uppdatera lösenord
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "teal",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Button>              	  
			<Typography variant="subtitle1">{msg}</Typography>			
		</Box>	

    </Box>
  )
}

export default ForgotPassword