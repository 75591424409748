import { MouseEvent, useState, KeyboardEvent, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'

// mui
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';



// mui icons
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import FilterIcon from '@mui/icons-material/Filter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TocIcon from '@mui/icons-material/Toc';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import WebIcon from '@mui/icons-material/Web';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SmsIcon from '@mui/icons-material/Sms';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

// Redux
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { Logout } from '../../slices/tokenReducer'

//self
import { UserRoles2,UserRoles3 } from '../../functions/UserRoles';


interface Iprops {
    User: {
        email: string
        profileImage: string
        roles: string
        userId: string
    }
}
export default function Navbar(props:Iprops) {
    const { User } = props
    
	const dispatch: AppDispatch = useDispatch()

    const [state, setState] = useState(false);

    const navigate = useNavigate()

    //* Changes state of navbar/drawer menu when hamburger menu is clicked on => open navbar/ drawer from the left */
    const toggleDrawer = (open: boolean) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState(open);
    };



    //* Navigation settings, Change Link path and text etc here  */
    const NavigationElements = [
        {
            text: "Home",
            icon: <HomeIcon />,
            link: "/"
        },        
        {
            text: "Menu",
            icon: <RestaurantMenuIcon />,
            link: "/menu",
            auth: UserRoles2
        },
        {
            text: "Lunch",
            icon: <RestaurantMenuIcon />,
            link: "/lunch",
            auth: UserRoles2
        },
        {
            text: "Lunch Weekend",
            icon: <RestaurantMenuIcon />,
            link: "/lunchweekend",
            auth: UserRoles2
        },
        {
            text: "Gallery",
            icon: <FilterIcon />,
            link: "/gallery",
            auth: UserRoles2
        },
        {
            text: "Takeaway For Kitchen",
            icon: <AddShoppingCartIcon />,
            link: "/takeawayauto",
            auth: UserRoles2
        },
        {
            text: "Takeaway",
            icon: <AddShoppingCartIcon />,
            link: "/takeaway",
            auth: UserRoles2
        },
        {
            text: "Reservations",
            icon: <MenuBookIcon />,
            link: "/reservations",
            auth: UserRoles3
        },
        {
            text: "Reservation Settings",
            icon: <TocIcon />,
            link: "/reservationsettings",
            auth: UserRoles2
        },
        {
            text: "Reservation Calendar",
            icon: <EditCalendarIcon />,
            link: "/reservationcalendar",
            auth: UserRoles2
        },        
        {
            text: "Events",
            icon: <EditCalendarIcon />,
            link: "/event",
            auth: UserRoles2
        },
        {
            text: "General Settings",
            icon: <WebIcon />,
            link: "/info",
            auth: UserRoles2
        },
        {
            text: "Payments",
            icon: <AccountBalanceIcon />,
            link: "/payments",
            auth: UserRoles2
        },
        {
            text: "Sms",
            icon: <SmsIcon />,
            link: "/sns",
            auth: UserRoles2
        },
        {
            text: "Create PDF",
            icon: <PictureAsPdfIcon />,
            link: "/pdf",
            auth: UserRoles2
        },
        {
            text: "Send Newsletter",
            icon: <MailIcon />,
            link: "/newsletter",
            auth: UserRoles2
        },
        {
            text: "Subscribers",
            icon: <SubscriptionsIcon />,
            link: "/subscribers",
            auth: UserRoles2
        }
        
        
    ]

    const handleProfileNavigation = async(link: string) => {
        if(link === "/logout"){
            await dispatch(Logout({}))
                .unwrap()
                .then((res:any) => {       
                    
                    //window.location.replace("/")
                })
                return
        }else{
            navigate(link)
            return
        }
    }

    //* ProfileNavigation Settings, Change Link path and text etc here  */
    const settings = [
        {
            text: "Dashboard",            
            link: "/"
        },        
        {
            text: "Account",            
            link: "/account"
        },        
        {
            text: "Logout",            
            link: "/logout"
        },
    ]
    //* Creates the drawer/navbar list  */
    const list = () => (
        <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        >
        <List>
            {NavigationElements.map((obj, index) => {
                /* if(obj?.auth?.includes(User?.roles)){ */
                    return (
                        <ListItem key={obj?.text} disablePadding >
                            <ListItemButton onClick={()=> navigate(obj?.link)}>
                            <ListItemIcon>
                                {obj?.icon}
                            </ListItemIcon>
                                <ListItemText primary={obj?.text} color='primary.main'/>
                            </ListItemButton>
                        </ListItem>
                    )            
                /* } */
                
            })}

        </List>
        <Divider />
     {/*    <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding >
                <ListItemButton>
                <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
                </ListItemButton>
            </ListItem>
            ))}
        </List> */}
        </Box>
    );





    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }
        
    const drawerWidth: number = 240;
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
        })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    }));


    //* Profile Component */
    const ProfileElement = () => {
        
        const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
        const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
            setAnchorElUser(event.currentTarget);
        };
        const handleCloseUserMenu = () => {
            setAnchorElUser(null);
        };
        return (
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar  alt={User?.email} src={`https://${process.env.REACT_APP_CDN_URL}/${User?.profileImage}`} />
                </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                     <MenuItem sx={{cursor:"auto"}}>
                        <Typography>{User?.email}</Typography>
                    </MenuItem>
                        <Divider variant='middle'/>
                    

                {settings.map((obj) => (                                        
                    <MenuItem key={obj?.text} onClick={handleCloseUserMenu}>
                        <Button onClick={()=>handleProfileNavigation(obj?.link)}  > 
                            <Typography textAlign="center">{obj?.text}</Typography>
                        </Button>
                    </MenuItem>                    
                ))}
                </Menu>
            </Box>
        )
    }


    return (
        <Box>


            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>

                        {/* Hamburger Menu */}
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer(true)}
                        >  
                            <MenuIcon />
                        
                        
                        </IconButton>


                        <Typography variant="h4" sx={{ flexGrow: 1 }}>
                            Dashboard
                        </Typography>

                        {/* Profile Component */}
                        <ProfileElement />

                    </Toolbar>
                
                </AppBar>
            </Box>

            {/* LEFT SIDE NAVBAR  */}                    
            <Fragment >          
                <Drawer
                    anchor={`left`}
                    open={state}
                    onClose={toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
            </Fragment>
        
        </Box>
    );
}