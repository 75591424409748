import React from 'react'

//mui
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Iinfo } from '../../types/interface'

interface Iprops {
    info: Iinfo
    labels: string []
    tab: number
    setTab:  (p: React.SetStateAction<number>) => void
}
const TabsComponent = (props:Iprops) => {
    const { info, labels, tab, setTab } = props
    return (        
        <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: number)=> setTab(newValue) } centered textColor='primary' indicatorColor="primary">
            {labels?.map( (label, i) => {
                return <Tab label={label} sx={{color:"primary.main",}} key={`tabscomponent${label}${i}`} />
            })}                        
        </Tabs>
    )
}

export default TabsComponent