import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

/* Data grid imports */
import {  GridColDef } from '@mui/x-data-grid';


/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import { GetSns } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// self
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface';
import { TextTable } from '../../components';



dayjs.extend(weekOfYear)



interface Irow {
    [key: string]:string
}

interface Iprops {
    info: Iinfo | undefined
}

const Sns = (props:Iprops) => {
    const { info } = props;

    const dispatch: AppDispatch = useDispatch()
        
    const [row, setRow] = useState<Irow []>([])    
    const [loading, setLoading] = useState<boolean>(true)
    const [failedFetch, setFailedFetch] = useState<boolean>(false)


    useEffect(() => {
        setLoading(true)
        FetchData()                       
      
        return () => {
            
        }
    }, [])
   

    const AddIdToSnsData = (data:Irow []) => {
        const newData = []
        for (let i = 0; i < data.length; i++) {
            const snsItem = data[i];
            snsItem["id"] = snsItem?.SubscriptionArn
            newData.push(snsItem)                    
        }
        return newData
    }

    const FetchData = async() => {

        const res = await TokenRefresh()
        if(res !== 200){
            setLoading(false)
            return
        }

        const tempmenu = {
            data: [
                {                    
                    Endpoint: "0707946979",
                    SubscriptionArn: "ARn:::asdasdaq:123127"
                },
                {                    
                    Endpoint: "0707946979",
                    SubscriptionArn: "ARn:::asdasdaq:123126"
                },
                {                    
                    Endpoint: "0707946979",
                    SubscriptionArn: "ARn:::asdasdaq:123125"
                },
                {                    
                    Endpoint: "0707946979",
                    SubscriptionArn: "ARn:::asdasdaq:123124"
                }
            ],
            statusCode: 200
        }
        try {
            await dispatch(GetSns({}))
                .unwrap()
                .then((res) => {      
                  
                    
                    if(res.statusCode === 200){                        
                        let idAddedtoData = AddIdToSnsData( res?.body?.data?.Subscriptions )
                        setRow([...idAddedtoData])
                    }else{
                        setFailedFetch(true)            
                    }
                    
            })   
        } catch (error) {            
            console.log(error)
            setFailedFetch(true)
        }     
        setLoading(false)
    }
    




    
    
 


    //! Columns , Edit this for wanted data types
    const columns: GridColDef[] = [    
        {
            field: 'Endpoint',
            headerName: 'Phone Number (+46...)',
            minWidth: 200,
            description:"Phone number must have (+46xxx-xxx-xxx) format (landskod / country code)",
            editable: true,
            
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        
        
        },              
                            
    ];
    

    return (
        <TextTable 
            info={info?.Sns}
            type={"sns"} 
            parentRow={row}            
            failedFetch={failedFetch}
            fetchLoading={loading}
            columns={columns}
        />
    )
}



export default Sns



