import { Itakeawayreturnitem } from "../types/interface"
interface Ifetchmenu {
    title: string
    info: string        
    price: number
    category: string
}

interface Imenu {
    [key: string]: Itakeawayreturnitem []
}
export const ReStructureMenu = (menuArr: Ifetchmenu[], menuCategories: string []) => {    
     
    const returnObj:Imenu = {} as Imenu
    menuCategories?.forEach((item) => returnObj[item] = [] )
        
    for (let i = 0; i < menuArr?.length; i++) {
        const menuitem = menuArr[i];
        returnObj[menuitem?.category]?.push({
            title: menuitem?.title,
            price: menuitem?.price,
            info: menuitem?.info,
            pk:"",
            active:true
        })                                                        
    }

    return returnObj
}
interface Ifetchtakeaway {
    pk: string
    title: string
    info: string        
    price: number
    category: string
    active:boolean
}
interface Itakeawayreturn {
    [key:string] : Itakeawayreturnitem []
}
export const ReStructureTakeaway = (menuArr: Ifetchtakeaway[], menuCategories: string []) => {    
     
    const returnObj:Itakeawayreturn = {} as Itakeawayreturn
    menuCategories?.forEach((item) => returnObj[item] = [] )
        
    for (let i = 0; i < menuArr?.length; i++) {
        const menuitem = menuArr[i];
        returnObj[menuitem?.category]?.push({
            title: menuitem?.title,
            price: menuitem?.price,
            info: menuitem?.info,
            active: menuitem?.active,
            pk: menuitem?.pk,
        })                                                        
    }

    return returnObj
}

interface Ifetchleftover {
    pk: string
    title: string
    info: string        
    price: number
    oldPrice: number
    category: string
    active:boolean
}
export const ReStructureLeftover = (menuArr: Ifetchleftover[], menuCategories: string []) => {    
     
    const returnObj:any = {}
    menuCategories?.forEach((item) => returnObj[item] = [] )
        
    for (let i = 0; i < menuArr?.length; i++) {
        const menuitem = menuArr[i];
        returnObj[menuitem?.category]?.push({
            title: menuitem?.title,
            price: menuitem?.price,
            oldPrice: menuitem?.oldPrice,
            info: menuitem?.info,
            pk: menuitem?.pk,
        })                                                        
    }

    return returnObj
}