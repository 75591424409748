import React from 'react'

//mui
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper'

import { Imenu  } from '../../types/interface';
import './ShowNewsletter.css'
interface Iprops {
    open: boolean;
    menu: {[key:string]: Imenu[]}  ;
    setOpen: (value: boolean) => void;
    image: string
    textField: {[key:string]: string}  
}
const ShowNewsletter = (props:Iprops) => {
    const { menu, setOpen, open, image, textField } = props;
 
    //*  Open Close Delete and update Dialog    
    const CloseDialog = () => {              
        setOpen(false)                
    }; 

  

    return(
        <Paper>
        {/* <Dialog onClose={CloseDialog} open={open}> */}

            <Box>
                
                <div className='nl_first-div' >  

                    
                    <h1 className="nl_h1">Sjöporten Restaurang</h1> 
                    <h2 className="nl_h2">{textField?.topic}</h2> 
                    <div className="nl_img">
                        <img  src={image} alt='newsletter'/>
                    </div>
                    {menu && Object?.keys(menu)?.map( (key: string, i:number) => {
                
                        
                    return (
                            <div key={`categorynewsletter${key}${i}`}>
                                <h2 className="nl_h2">
                                    {key?.split("_").join(" ")}
                                </h2>
                        

                            
                            
                                {menu?.[key].map((menuItem: Imenu,j:number) => {
                            
                                    if(!menuItem?.active){
                                        return
                                    }else{
                                        return(
                                            <div className="nl_menuitem" key={`itemnewsletter${key}${i}${j}`}>
                                                <h4 className="nl_h4">{menuItem?.title}</h4>
                                                <p className="nl_p">{menuItem?.info}</p>
                                                <p className="nl_p">{menuItem?.price}</p>
                                            </div>
                                        )
                                    }
                                })}



                               
                            
                            </div>
                    )

                    })}
                     <p className="nl_message">{textField?.message}</p>
                </div>
            </Box>

        {/* </Dialog> */}
        </Paper>
    )
}

export default ShowNewsletter