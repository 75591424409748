import { useState, useEffect, useMemo } from 'react'
import { Document, Text, Page, StyleSheet, View, Svg, G, Path, Font  } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer'
import { useDropzone } from 'react-dropzone'

// mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';


// mui icons
import SaveIcon from '@mui/icons-material/Save';


/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'

/* Data grid imports */
import {  GridColDef } from '@mui/x-data-grid';



// REDUX
import { GetLunch, GetLunchWeekend,GetMenu, UpdatePdf, GetPdf, GetShop } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// Self
import { TokenRefresh } from '../../functions/Token';
import { ReStructureMenu } from '../../functions/ReStructureMenu'
import { Iinfo, Itakeawayreturnitem } from '../../types/interface'
import { theme } from '../../App';
import { Typography } from '@mui/material';
import { SnackbarComponent, SimpleBackdropLoading, TextTable } from '../../components';
//import '../../fonts/OpenSans.ttf'a

//! If you want to url below, use curl https://fonts.googleapis.com/css2?family=Cinzel&family=Open+Sans:wght@300&display=swap and copy the recived url below.
Font.register({ family: 'Cinzel', src: 'https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnTYo.ttf' });
Font.register({ family: 'Open Sans', src: 'https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf' });


dayjs.extend(weekOfYear)

const defaultWeek = dayjs().week()
const lunchList = ["lunch","lunchweekend"]
const allList = ["menu","lunch","lunchweekend","takeaway"]
//* array with week numbers 1 - 52 for chosing week to see specific lunch menu
const allWeekNumbersArray = Array.from({length: 52}, (_, i) => (i + 1).toString())

/* Dropzone Style */
const dropzoneStyle = {
    baseStyle: {
        flex: 1,
        display: 'flex',
        FlexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    focusedStyle: {
        borderColor: '#2196f3'
    },
    acceptStyle: {
        borderColor: '#00e676'
    },
    rejectStyle: {
        borderColor: '#ff1744'
    }

}

//! Columns , Edit this for wanted data types
const columns: GridColDef[] = [    
    {
        field: 'key',
        headerName: 'Name',
        minWidth: 200,
        //description:"Phone number must have (+46xxx-xxx-xxx) format (landskod / country code)",
        editable: false,                       
    },              
                        
];

interface Iprops {
    info: Iinfo | undefined
    
}
interface Imenu {
    [key: string]: Itakeawayreturnitem []
}
const Pdf = (props:Iprops) => {
    const { info } = props
    
    const dispatch: AppDispatch = useDispatch()
   

    const styles = StyleSheet.create({
        page: {
            textAlign: 'center',
                        
            fontSize: 30,
            
        },
        text: {
            color: '#228b22',
        },
        layout: {
            height:"100%",
            marginTop: 30,        
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            position:"relative",
        },
        catItem: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',        
            marginTop: 9,
           
            //marginBottom: margin1
        },
        menuItem: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',        
            marginTop: 8
        },
        image: {        
            position:"absolute",
            top:0, left:0, right:0, bottom:0,        
            width:"100%", height:"95%",    
            objectFit:"contain",
            zIndex:50,                                                                  
        },
        path: {                
            opacity:0.5
        },
        title:{
            fontSize:"35px",
            color: theme.palette.primary.main,
            fontFamily: "Cinzel",
            marginTop:36
        },
        subTitle:{
            fontSize:"25px",
            color: theme.palette.primary.main,
            fontFamily: "Cinzel"
        },
        weekTitle:{
            fontSize:"20px",
            color: theme.palette.primary.main,
            fontFamily: "Open Sans"
        },
        h2:{
            fontSize:"20px",
            fontFamily: "Open Sans"
        },
        catText:{
            fontSize:"15px",
            fontFamily: "Cinzel"
        },
        h5:{
            fontSize:"12px",
            fontFamily: "Cinzel",
            maxWidth: "90%",
            textAlign: "center",
            textTransform:"capitalize"
        },
        p:{
            fontSize:"10px",
            fontFamily: "Open Sans",
            maxWidth: "90%",
            textAlign: "center",
            textTransform: "lowercase",
            fontWeight:300,
            
        },
        message:{            
            fontSize:"10px",
            fontFamily: "Open Sans",
            maxWidth: "90%",            
            textAlign: "center"
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
          },
    });
    const [menu, setMenu] = useState<Imenu>({})
    const [type, setType] = useState("menu")
    const [category, setCategory] = useState([])
    
    // creates an array with zeros at a certain length, can and have been done better but this one does not spontaneously crash
    const createArray = (catarr:string []) => {
        const arr = []
        if( catarr?.length > 0) {
            for (let i = 0; i < catarr.length; i++) {
                arr.push(0)                
            }
        }
        return arr
        
    }

    const [marginArray, setMarginArray] = useState<number []>([])
    const [week, setWeek] = useState<number>(dayjs().week())  
    const [loading, setLoading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(true)
    const [title, setTitle] = useState("")
    const [tab, setTab] = useState(0)
    const initalPdfFile = {file:"",type:"",key:""}
    const [pdfFile, setPdfFile] = useState(initalPdfFile)

    const [pdfMessage, setPdfMessage] = useState("")
    
    const [message, setMessage] = useState<string | undefined>("")
    const [alert, setAlert] = useState("")
    const [allPdfs, setAllPdfs] = useState<string []>([])
    const [allPdfsReadOnly, setAllPdfsReadOnly] = useState<string []>([])
   
    useEffect(() => {
        setTitle(`${type}${lunchList?.includes(type) ? week : ""}`)
        FetchApiData()                      
        FetchPdfArray()
        
        return () => {
            
        }
    }, [week,type])

    const FetchApiData =  async() => {
        try {
            const DispatchSelector =  () => {
                switch (type) {
                    case "lunch":
                        return dispatch(GetLunch({week:week}))            
                    case "lunchweekend":
                        return dispatch(GetLunchWeekend({week:week}))            
                    case "menu":
                        return dispatch(GetMenu({}))            
                    case "takeaway":
                    case "leftover":
                        return dispatch(GetShop({typeOfMenu:type}))                                                    
                    default:
                        return dispatch(GetMenu({}))                                    
                }
            }

            await DispatchSelector().unwrap()
                .then((res) => {      
                    
                    if(res?.statusCode === 200){
                        
                        if(type === "takeaway" || type === "leftover"){
                            const foundItems = res?.body?.data?.filter((item:any) => item.sk === "takeaway" && item.pk !== "category" )
                            const foundCategory = res?.body?.data.filter((item:any) => item.sk === "takeaway" && item.pk === "category" )
                        
                            
                            setMenu(ReStructureMenu(foundItems, foundCategory[0].categories))
                            setMarginArray(createArray( foundCategory[0].categories ) )
                            setCategory(foundCategory[0].categories)
                        }else{
                            setMenu(ReStructureMenu(res?.body?.data?.Menu, res?.body?.data?.Category))                                                
                            setMarginArray(createArray( res?.body?.data?.Category))
                            setCategory(res?.body?.data?.Category)
                        }
                        
                    }else{
                        setAlert("error")
                        setMessage(info?.Pdf?.FailedFetch)
                        setMenu({})
                        //setFailedFetch(true)            
                    }
                    setFetchLoading(false)
                    
            })              
        } catch (error) {            
            console.log(error)
            setAlert("error")
            setMessage(info?.Pdf?.FailedFetch)
            //setFailedFetch(true)
        } 
    }
    const FetchPdfArray = async() => {
        try {
            await dispatch(GetPdf({}))
                .unwrap()
                .then((res) => {                                          
                    if(res?.statusCode === 200){
                        setAllPdfs([...res?.body?.data?.Pdf || []])
                        setAllPdfsReadOnly([...res?.body?.data?.Pdf || []])
                    }else{
                        setAlert("error")
                        setMessage(info?.Pdf?.FailedFetch)                              
                    }                                        
            })              
        } catch (error) {            
            console.log(error)
            setAlert("error")
            setMessage(info?.Pdf?.FailedFetch)
            
        } 
    }


    // Changes margin between categories
    const ChangeMargin = (index:number,amount:number)  => {
        let tempArr = [...marginArray]
        tempArr[index] = tempArr[index] + amount
        return tempArr
    }
    // Change which menu that should be displayed
    const ChangeTypeOfMenu = (event:SelectChangeEvent) => {
        const { value } = event?.target
        setType( value )
    }
 
    // Uploads Changed data to serer
    const SaveChanges = async() => {

     
        setLoading(true)
        setMessage("")        
    
        const res = await TokenRefresh()                                 
        
        if(res !== 200) {setLoading(false) ;return}
        await dispatch(UpdatePdf({pdfFile, allPdfs }))
            .unwrap()
            .then((res:any) => {               
                if(res?.statusCode === 200){
                  
                    setMessage(info?.Pdf?.UpdateSuccess || "Success")                
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.Pdf?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    // Changes tab between pdf creator and upload
    const ChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };


    //* Pdf file created 
    const PdfComponent = () => (
    
        <PDFViewer /* width={"95%"} */ className="flex__center-c" style={{height:"90vh",width:"95vw"}}>
            <Document title={title}>
                <Page style={styles?.page} size={"A4"} wrap >
                    
                    {/* <View style={styles?.layout} > THIS FUCKER IS THE THING FUCKING UP THE PADDING AND MARGIN FOR WRAPPING TO NEW PAGE; CUNT */}
                
                        <Svg fixed style={styles.image}
                            width="1500.000000pt" height="1444.000000pt" viewBox="0 0 1500.000000 1444.000000"
                            preserveAspectRatio="xMidYMid meet">


                            <G transform="translate(0.000000,1444.000000) scale(0.100000,-0.100000)"
                            fill={ theme.palette.primary.light }  stroke="none">
                            <Path style={styles.path} d="M7418 14040 c-9 -6 -21 -25 -27 -43 -5 -17 -107 -664 -226 -1437
                            -119 -773 -239 -1549 -266 -1725 -27 -176 -60 -392 -74 -480 -64 -423 -227
                            -1470 -229 -1472 -2 -2 -335 240 -746 543 -96 71 -255 188 -353 259 -99 72
                            -220 160 -270 198 -51 37 -121 88 -157 114 -36 26 -198 144 -360 263 -901 661
                            -871 640 -906 640 -23 0 -38 -7 -48 -22 -35 -49 -21 -78 123 -270 36 -48 98
                            -131 136 -184 39 -53 108 -149 155 -213 152 -206 733 -999 785 -1070 47 -66
                            227 -311 396 -541 397 -541 407 -555 391 -561 -10 -3 -39 -9 -67 -13 -64 -9
                            -710 -109 -915 -141 -85 -13 -508 -79 -940 -145 -3004 -463 -3175 -490 -3200
                            -509 -28 -20 -35 -41 -26 -77 8 -29 45 -49 111 -58 28 -4 104 -16 170 -26 122
                            -20 876 -136 1530 -236 198 -30 414 -63 480 -74 66 -11 372 -58 680 -105 308
                            -47 675 -103 815 -125 140 -22 446 -69 680 -105 234 -36 487 -75 562 -87 97
                            -15 135 -24 130 -32 -4 -7 -47 -67 -97 -134 -49 -68 -104 -142 -120 -165 -17
                            -23 -62 -85 -101 -137 -39 -52 -133 -180 -209 -285 -76 -104 -169 -230 -205
                            -280 -147 -199 -338 -460 -385 -525 -60 -84 -367 -502 -480 -655 -216 -293
                            -353 -480 -382 -523 -37 -53 -43 -100 -15 -125 34 -31 75 -21 153 36 79 56
                            365 265 566 413 66 49 195 143 285 208 89 66 222 163 293 216 72 53 195 143
                            274 200 79 58 187 137 240 176 53 39 186 137 296 218 294 215 395 290 540 396
                            72 53 144 106 162 118 l31 22 6 -37 c5 -32 175 -1134 681 -4423 53 -344 100
                            -640 106 -658 17 -54 76 -70 117 -32 12 12 22 23 22 25 0 2 36 239 81 527 44
                            288 87 564 94 613 54 351 270 1749 290 1875 14 85 33 214 44 285 19 124 48
                            316 211 1364 38 250 71 455 72 457 3 2 209 -148 468 -339 121 -90 551 -405
                            610 -447 27 -19 149 -109 272 -199 402 -296 878 -645 948 -696 38 -27 140
                            -102 227 -166 87 -64 176 -128 197 -143 69 -47 136 -27 136 41 0 21 -19 55
                            -62 114 -34 46 -76 103 -93 126 -53 75 -286 393 -408 558 -65 88 -178 243
                            -252 345 -74 102 -180 246 -235 320 -55 74 -149 203 -210 287 -60 83 -162 223
                            -226 310 -64 87 -134 181 -154 208 -20 28 -86 118 -146 200 -60 83 -137 188
                            -172 235 -35 46 -59 86 -55 87 7 2 802 126 1118 174 254 38 1042 160 1195 184
                            85 13 319 49 520 80 645 99 1401 215 1560 240 85 14 277 43 425 65 149 23 285
                            47 302 55 52 23 58 98 11 123 -19 10 -662 112 -1968 312 -140 21 -577 89 -970
                            150 -393 61 -787 122 -875 135 -88 13 -349 54 -580 89 -231 36 -483 75 -560
                            87 -77 11 -150 24 -162 27 -20 6 -8 25 133 217 86 116 193 262 238 325 45 63
                            118 163 162 221 43 59 130 176 191 260 62 85 202 276 310 424 229 312 443 604
                            493 674 35 48 217 296 359 489 42 57 84 115 93 130 23 35 23 85 0 105 -43 39
                            -65 27 -353 -183 -151 -111 -306 -224 -345 -253 -91 -66 -330 -241 -549 -401
                            -93 -69 -219 -161 -280 -205 -60 -44 -244 -179 -407 -298 -163 -120 -377 -276
                            -475 -348 -98 -71 -234 -172 -302 -222 -69 -51 -126 -91 -128 -90 -1 2 -25
                            149 -53 328 -28 178 -71 452 -95 609 -24 157 -94 609 -155 1005 -160 1042
                            -238 1548 -255 1650 -8 50 -51 326 -95 615 -45 289 -95 613 -111 720 -25 159
                            -34 199 -51 217 -22 24 -61 29 -90 13z m167 -1385 c46 -303 61 -407 129 -845
                            30 -195 107 -690 170 -1100 64 -410 156 -1007 206 -1328 49 -320 90 -594 90
                            -609 0 -27 -12 -51 -257 -508 -83 -154 -177 -330 -209 -390 -141 -265 -169
                            -315 -176 -315 -5 0 -8 1209 -7 2688 0 1522 4 2678 9 2667 5 -11 25 -128 45
                            -260z m-2853 -2598 c62 -45 151 -110 198 -145 113 -83 723 -529 880 -644 69
                            -50 181 -133 250 -183 69 -50 204 -149 300 -220 96 -70 189 -139 207 -154 23
                            -19 99 -160 298 -554 147 -291 265 -530 263 -532 -2 -2 -571 563 -1263 1255
                            -693 693 -1257 1260 -1253 1260 4 0 58 -37 120 -83z m5659 -79 c-37 -51 -154
                            -210 -187 -254 -8 -12 -81 -111 -161 -220 -81 -110 -185 -253 -233 -319 -48
                            -66 -122 -168 -166 -226 -43 -59 -130 -176 -191 -260 -62 -85 -195 -266 -295
                            -403 l-183 -249 -525 -262 c-289 -145 -527 -261 -530 -259 -5 6 2492 2504
                            2503 2504 4 0 -10 -24 -32 -52z m-4411 -2121 c58 -29 193 -99 300 -155 219
                            -113 543 -280 728 -374 67 -35 122 -66 122 -70 0 -5 -1223 -8 -2717 -7 -1562
                            1 -2705 5 -2688 10 17 5 410 67 875 139 2153 331 3228 499 3270 509 3 0 52
                            -23 110 -52z m7225 -762 c-5 -2 -57 -11 -115 -19 -58 -9 -337 -51 -620 -95
                            -283 -44 -868 -134 -1300 -201 -432 -66 -825 -127 -875 -135 -49 -8 -326 -51
                            -615 -95 -289 -44 -552 -84 -584 -90 l-59 -10 -151 79 c-83 43 -232 120 -331
                            171 -203 104 -434 223 -638 329 l-137 71 2717 -1 c1495 0 2714 -2 2708 -4z
                            m-6115 -178 c0 -1 -586 -587 -1302 -1302 -716 -715 -1293 -1286 -1283 -1270
                            10 17 32 48 49 70 17 21 81 107 141 191 61 83 254 348 430 587 176 240 348
                            476 383 524 35 48 112 153 171 233 59 80 144 195 189 256 l82 111 133 70 c195
                            103 606 323 812 434 164 89 195 104 195 96z m288 -4117 l-3 -1371 -88 573
                            c-48 315 -193 1254 -321 2087 l-234 1513 322 651 321 650 3 -1366 c1 -751 1
                            -1983 0 -2737z m1655 2700 c709 -709 1286 -1290 1283 -1290 -7 0 -95 63 -341
                            244 -115 86 -295 217 -399 293 -104 76 -219 161 -256 188 -38 28 -142 104
                            -232 169 -89 65 -185 136 -213 156 -180 134 -412 304 -476 350 -70 50 -81 63
                            -118 135 -22 44 -69 134 -104 200 -35 66 -103 197 -152 290 -48 94 -91 175
                            -95 180 -4 6 -47 87 -95 180 -48 94 -92 176 -98 183 -59 75 194 -175 1296
                            -1278z"/>
                            </G>
                        </Svg>

                        
                        <Text style={styles?.title}>{info?.Pdf?.Pdf_Title}</Text>
                                                                                        
                        <Text style={styles?.subTitle}>{ info?.Pdf?.[ `Pdf_SubTitle_${title}` ] }</Text>

                        {lunchList?.includes(type) &&  <Text style={styles?.weekTitle}>{info?.Pdf?.Pdf_Week} {week}</Text> }
                        

                        {menu && Object?.keys(menu)?.map( (key: string, i:number) => {
                            
                            return(
                                <View key={`categoryview${i}`} style={[ {marginBottom: marginArray[i]}, styles?.catItem]}  >
                                    <Text style={styles?.catText}>{key?.split("_").join(" ")}</Text>
                                    {menu?.[key].map((menuItem: any,j:number) => {
                                        
                                        return(
                                            <View key={`menuitemview${i}${j}`} style={styles?.menuItem} >
                                                <Text style={styles?.h5}>{menuItem?.title}</Text>
                                                <Text style={styles?.p}>{menuItem?.info}</Text>
                                                <Text style={styles?.p}>{menuItem?.price}</Text>
                                            </View>
                                        )
                                    })}
                                </View>
                            )
                        })}

                        <View style={{maxWidth:"100%",display:"flex",justifyContent:"center",alignItems:"center",marginTop: 48}}>
                            <Text style={ styles?.message }>{pdfMessage}</Text>
                        </View>
                        
                {/*      </View> */}
                 {/*    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed /> */}
                </Page>
            </Document>
        </PDFViewer>
    
    )
    //* TypeSelector => type = menu | lunch12 | takeaway etc...
    const TypeSelector = () => {
        return(
            <Box className='flex__center-c'>
                {/* Select whick type of menu */}
                <FormControl sx={{m:"1rem 0"}} >
                    <InputLabel sx={{color:"text.primary"}}>{info?.Pdf?.Selected_Label}</InputLabel>
                    <Select                        
                        value={type}
                        sx={{minWidth:"15rem"}}
                        label={info?.Pdf?.Selected_Label}
                        onChange={ChangeTypeOfMenu}                                            
                    >   
                        {allList?.map(type => (
                            <MenuItem key={`selecttype${type}`} value={type}>{type}</MenuItem>
                        ))}                                        
                    </Select>
                </FormControl>

                {/*  Pick Which Week is selected */}
                { lunchList.includes(type) &&
                <Box className="flex__center-c" sx={{margin:"1rem 0"}}>
                    <Autocomplete
                        color="text.secondary"
                        disablePortal                        
                        sx={{label:{color:"text.primary", minWidth: "25rem"}}}
                        options={allWeekNumbersArray}
                        onChange={(event: React.SyntheticEvent, value: string | null , reason: string, details?: any):void => setWeek(value ? parseInt(value) : defaultWeek)  }
                        value={week ? week.toString() : defaultWeek.toString() }                        
                        renderInput={(params) => <TextField {...params} label={info?.Pdf?.Autocomplete_TextField} />}
                    />
                    
                </Box>}
            </Box>
        )
    }





    //* Resize and covert image to webp via drag and drop
    const ToBase64 = (file:any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const onDrop = async (acceptedFiles:any) => {
      
        try {          
            const res:any = await ToBase64(acceptedFiles[0])           
            
            let newFile = {
                key: `pdf/${title}.pdf`,
                file: res,
                type: "application/pdf"
            }
            setPdfFile(newFile)
            
            
        } catch (error) {           
            setMessage(info?.Pdf?.FileError)
            setAlert("error")                
        }  
    }
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {'application/pdf': []}});
    const style = useMemo(() => ({
        ...dropzoneStyle?.baseStyle,
        ...(isFocused ? dropzoneStyle?.focusedStyle : {}),
        ...(isDragAccept ? dropzoneStyle?.acceptStyle : {}),
        ...(isDragReject ? dropzoneStyle?.rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    // Converts string []  => [ {id:string,etc...}, {id:string,etc...} ]  datagrid needs it 
    const ConvertArrayForDataGrid = (array:string []) => {
        const returnArray = []
        for (let i = 0; i < array.length; i++) {
            const stringElem = array[i];
            returnArray.push({id:stringElem, key:stringElem})
            
        }
        return returnArray
    }

    
    
   
    return (
        <Box className="flex__center-c" sx={{justifyContent:"flex-start"}} width={"100vw"} minHeight={"100vh"}>

            <Tabs value={tab} onChange={ChangeTab} centered textColor='primary' indicatorColor="primary">
                <Tab label={info?.Pdf?.Tab_Create} sx={{color:"primary.main",}}/>
                <Tab label={info?.Pdf?.Tab_Upload} sx={{color:"primary.main",}}/>
                <Tab label={info?.Pdf?.Tab_Delete} sx={{color:"primary.main",}}/>
            </Tabs>


            {/* TAB 1 => CREATE PDF */}
            {tab === 0 ?
            <Box className="flex__center-c">
                
            {TypeSelector()}


            {/* Margin selector title */}
            <Typography variant='h4'>
                {info?.Pdf?.Margin_Typography}
            </Typography>


            {/* Margin selector for each category */}
            <Box className="flex__center-c" alignItems="flex-start"> 
                {marginArray?.map( (key: any, i:number) => {                                                
                    return(
                        <Box key={`marginbuttons${i}`} className="flex__center-r"  sx={{justifyContent:"space-between", width:"100%",mb:"0.2rem"}} >

                            <Typography variant='h5'>
                                { `${category[i]} & ${ category[i+1] ? category[i+1] : "Message" }` }
                            </Typography>


                            <Box>
                                <Button onClick={()=>setMarginArray( ChangeMargin(i,10)  ) } variant="contained" size='small' color='success'> 
                                    {info?.Pdf?.Button_Increase}
                                </Button>
                                <Button onClick={()=>setMarginArray( ChangeMargin(i,-10) )} variant="contained" size='small' color='error'> 
                                    {info?.Pdf?.Button_Decrease}
                                </Button>
                            </Box>

                        </Box>
                    )
                })}
            </Box>

            {/* PDF COMPONENT */}
            {!fetchLoading && 
                <PdfComponent />
            }
            </Box>
            : 


                /* TAB 2 => UPLOAD PDF */
                tab === 1 ? 
                    <Box className="flex__center-c">

                            
                        {/* Save Button */}
                        <Button
                            sx={{minWidth: '180px',m:"1rem"}}
                            variant="contained"
                            color='success'
                            disabled={loading}
                            onClick={SaveChanges}
                            endIcon={<SaveIcon />}
                        >
                        {info?.Pdf?.Button_Save}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "teal",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                        )}
                        </Button>

                        

                        <Typography variant='h4'>
                            {pdfFile?.key ? `Upload: ${pdfFile?.key} ? `: ``} 
                        </Typography>

                        <Button onClick={()=>setPdfFile(initalPdfFile)} variant='outlined' sx={{m:"0.5rem 0"}}>
                            {info?.Pdf?.Button_ClearFile}
                        </Button>

                        {TypeSelector()}

                        {/* DropZone */}
                        <div className="container">
                            <div {...getRootProps({style})}>
                                <input {...getInputProps()} />
                                <p>{info?.Pdf?.DropAndDrag}</p>
                            </div>
                        </div>                    
                    </Box>
                :
                /* TAB 3 => DELETE PDFs */
                    <Box  sx={{display:"block",width:"100%"}}>
                    
                        <TextTable 
                            info={info?.Pdf}
                            type={"pdf"} 
                            parentRow={ ConvertArrayForDataGrid(allPdfsReadOnly) }
                            setParentRow={ setAllPdfsReadOnly }
                            failedFetch={false}
                            fetchLoading={loading}
                            columns={columns}
                        />
                    </Box>
          
                
            
            }
            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />

            <SimpleBackdropLoading openbackdrop={fetchLoading} />    
            
        </Box>
    )
}

export default Pdf