import React from 'react'

import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import SaveIcon from '@mui/icons-material/Save'

import { Iinfo } from '../../types/interface'

interface Iprops{
    loading: boolean
    save: ()=>Promise<void>
    reset:()=>Promise<void>
    info:Iinfo
}
const SaveAndReset = (props:Iprops) => {
    const { loading, save, reset, info } = props
    return (
        <Container                                
            sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
        >
            <Button
                sx={{width: '180px'}}
                variant="contained"
                color='success'
                disabled={loading}
                onClick={save}
                endIcon={<SaveIcon />}
            >
            {info?.ReservationSettings?.Save}
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                    color: "teal",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    }}
                />
            )}
            </Button>

            <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={reset}>
                {info?.ReservationSettings?.Reset}
            </Button>
                            
        </Container> 
    )
}

export default SaveAndReset