import { useEffect, useState, forwardRef, useMemo, FormEvent } from 'react';
import { v4 as uuidv4 } from 'uuid'
import Resizer from "react-image-file-resizer";

// mui
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';



// Icons
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/* Data grid imports */
import { GridRenderCellParams, DataGrid, GridColDef } from '@mui/x-data-grid';

/* Drag and drop  */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// DropZone
import { useDropzone } from 'react-dropzone'



/* Snackbar */
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// REDUX
import { GetEvent, DeleteEvent, UpdateEvent } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface';


/* Switch component */
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#03fc41',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
}));


/* Dropzone Style */
const dropzoneStyle = {
    baseStyle: {
        flex: 1,
        display: 'flex',
        FlexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    focusedStyle: {
        borderColor: '#2196f3'
    },
    acceptStyle: {
        borderColor: '#00e676'
    },
    rejectStyle: {
        borderColor: '#ff1744'
    }

}

/* Snackbar */
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface InewImages {
    type: string
    file: string
    key: string
}
interface Ievent {
    [key:string]:string | string []
    id:string
    images: string[]
    info:string
    title:string
}
//type testInfo = Iinfo["Event"]
interface Iprops {
    info: Iinfo | undefined
}

const Event = (props:Iprops) => {
    const { info } = props;
    const dispatch: AppDispatch = useDispatch()

    const snackbarinit = {success: false, error: false, warning:false}

    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<Ievent []>([])            
    const [openSnackbar, setOpenSnackbar] = useState(snackbarinit)
    const [updateIndex, setUpdateIndex] = useState<number>(0)    
    const [message, setMessage] = useState<string | undefined>("")         
    const [newImages, setNewImages] = useState<InewImages []>([])
    const [newImageKeys, setNewImageKeys] = useState<string []>([])
    const [newAddedItems, setNewAddedItems] = useState<string []>([])
    const [removedImagesArr, setRemovedImagesArr] = useState<string []>([])
    const [frontPageId, setFrontPageId] = useState<string>("")
    const [deleteId, setDeleteId] = useState<string>("")
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [updateDialog, setUpdateDialog] = useState(false)
    
   

    //! Columns , Edit this for wanted data types
    const columns: GridColDef[] = [   
    {
        field: 'updatebtn',
        headerName: '',
        minWidth:150,
        renderCell: (params: GridRenderCellParams<Ievent>) => (                      
            <Button variant="contained" endIcon={<EditIcon />} sx={{padding:"0 0.5rem !important"}} onClick={()=>OpenUpdateDialog(params)}>
                {info?.Event?.Update_Button}
            </Button>
        ),
    },
    {
        field: 'removebtn',
        headerName: '',
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<Ievent>) => {
            if(newAddedItems?.includes(params?.row?.id)){
                return (
                    <Button variant="outlined" endIcon={<DeleteForeverIcon />} sx={{padding:"0 0.5rem !important"}} onClick={()=>RemoveNewAddedItem(params)} color='warning'>
                        {info?.Event?.Delete_Button}
                    </Button>
                )        
            }else{
                return (
                    <Button variant="outlined" endIcon={<DeleteForeverIcon />} sx={{padding:"0 0.5rem !important"}} onClick={()=>OpenDeleteDialog(params)} color='error'>
                        {info?.Event?.Delete_Button}
                    </Button>
                )
            }
            
        }
    },
    
    {   
        field: 'title',
        headerName: 'Title',
        minWidth: 200,
        editable: false,            
        renderCell: (params: any) =>  {               
            return (
                <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
                )
        }
    
    
    },        
    {
        field: 'info',
        headerName: 'Info',          
        minWidth: 200,
        editable: false,
        renderCell: (params: any) =>  {               
            return (
                <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
                )
        }
    },
    {
        field: 'images',
        headerName: 'Number of Images',                                
        minWidth: 200,
        editable: false,            
        renderCell: (params: any) =>  {               
            return (
                <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                    <span className="table-cell-trucate">{params?.value?.length}</span>
                </Tooltip>
                )
        }
    },
    
            

    ];







    //* Add New Event Item
    const AddNewEventItem = () => {
        const id = uuidv4()
        setNewAddedItems([...newAddedItems,id])
        setRows([{
            id: id,
            title:"",
            info:"",            
            images: []
        },...rows])
    }
    //* If a new added item is being removed, it should only be removed without an api call 
    const RemoveNewAddedItem = (params:GridRenderCellParams<Ievent>) => {
        let rowIndex = rows?.findIndex( row => row.id === params?.row?.id)
        let newAddedItemsIndex = newAddedItems?.findIndex( id => id === params?.row?.id)
        let tempNewAddedItems = [...newAddedItems]
        let tempRow = [...rows]
        tempRow?.splice(rowIndex, 1)
        tempNewAddedItems?.splice(newAddedItemsIndex, 1)
        setRows([...tempRow])
        setNewAddedItems([...tempNewAddedItems])
    }

    
    //* GetMenu from Json */
    useEffect(() => {
        setLoading(true)        
        try {
            GetNewEvent()                       
        } catch (error) {
            setMessage(info?.Event?.FailedFetch)
            OpenSnackbar("error")
            setLoading(false)
        }
        
        return () => {
        
        }
    }, [])
    //* If TypeOfMenu === "Event" then when a week is changed, fetch the new Eventmenu  */
    const GetNewEvent = async() => {
        try {                        
            setLoading(true)
            const tempData = {
                Event: [
                {
                    "id": "0688d40a-28fe-4e90-9aa4-9843aeab712d",
                    "title": "Massa nytt",
                    "info": "Kom hit vetja",
                    "images": [
                        "event/0688d40a-28fe-4e90-9aa4-9843aeab712d_0.webp",
                        "event/0688d40a-28fe-4e90-9aa4-9843aeab712d_1.webp"
                    ]
                },
                {
                    "id": "1d3dcea6-93ed-4f2f-9d4e-4aa5c6c37ce7",
                    "title": "test",
                    "info": "test",
                    "images": [
                        "event/1d3dcea6-93ed-4f2f-9d4e-4aa5c6c37ce7_0.webp"
                    ]
                },
                {
                    "id": "8d05ab72-4466-44e2-a0f0-37220b379378",
                    "title": "helt nytt event",
                    "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n\n",
                    "images": [
                        "event/8d05ab72-4466-44e2-a0f0-37220b379378_0.webp"
                    ]
                },
                {
                    "id": "646ac18c-4008-46a8-9672-a83c9874dd54",
                    "title": "Ett nytt event, Vi vann!",
                    "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna\n\n\n",
                    "images": [
                        "event/646ac18c-4008-46a8-9672-a83c9874dd54_0.webp"
                    ]
                }
            ],
            FrontPage:"0688d40a-28fe-4e90-9aa4-9843aeab712d"
        }
                
            await dispatch(GetEvent({}))
                .unwrap()
                .then((res) => {
                    if(res?.statusCode === 200) {
                        setLoading(false)                                                   
                        setRows([...res?.body?.data?.Event])                                                                                                       
                        setFrontPageId(res?.body?.data?.FrontPage)
                    }else{
                        setMessage(info?.Event?.FailedFetch)
                        OpenSnackbar("error")
                        setLoading(false)
                    }
            
            })
          
            

            
        } catch (error) {
            setMessage(info?.Event?.FailedFetch)
            OpenSnackbar("error")
            setLoading(false)
        }
    }
    

    //* Snackbar Alert  */
    const OpenSnackbar = (alert:string) => {
        setOpenSnackbar(prev=>({...prev, [alert]:true}))
    }
    const CloseSnackbar = (event?: React.SyntheticEvent | Event , reason?: string, alert:string="") => {               
        setOpenSnackbar(prev=>({...prev, [alert]:false}))
    }


   

    //* Drag and Drop functions */
    const reorder = (list:any, startIndex:any, endIndex:any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };
    const handleDrop = (droppedItem: any) => {
        
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;


        const sourceIndex = droppedItem.source.index;
        const destIndex = droppedItem.destination.index;
        if(droppedItem.type === "MENUTYPES"){    
            const newMenu:any = reorder(rows, sourceIndex, destIndex)
            setRows([...newMenu]);
           
        
        }

    };
    const changeOrder = (dir:string,i:number) =>{   
        var tempArr:any = [...rows]
        var value = rows[i]
        tempArr.splice(i,1)
        if(dir==="up"){
        tempArr.splice(i+1,0,value)
        }else{
        tempArr.splice(i-1,0,value)
        }  
        setRows([...tempArr])
        
    }


 


    //*  Open Close Delete and update Dialog    
    const CloseDeleteDialog = () => {
        setUpdateIndex(0)
        setDeleteId("")
        setDeleteDialog(false)
    };    
    const CloseUpdateDialog = () => {
        setUpdateIndex(0)
        setNewImageKeys([])
        setNewImages([])
        setUpdateDialog(false)
    };    
    const OpenDeleteDialog = (params:GridRenderCellParams<Ievent>) => {        
        let rowIndex = rows?.findIndex( row => row.id === params?.row?.id)
        setUpdateIndex(rowIndex)        
        setDeleteId(params?.row?.id)
        setDeleteDialog(true)
    }            
    const OpenUpdateDialog = (params:GridRenderCellParams<Ievent>) => {             
        let rowIndex = rows?.findIndex( row => row.id === params?.row?.id)
        setUpdateIndex(rowIndex)
        setUpdateDialog(true)
    }



    //* API DELETE (PUT) => Removes Chosen event, send id, frontpageId and rows. 
    const RemoveEvent = async() => {
        setMessage("")    
        if(loading){
           return 
        }             
        setLoading(true)

        const res = await TokenRefresh()                                 
        if(res !== 200) {
            setLoading(false)
            return
        }

        await dispatch(DeleteEvent({
            oldEvents: rows,
            frontPageId: frontPageId,
            id: deleteId,

        }))
            .unwrap()
            .then((res:any) => {                                                  
                if(res?.statusCode === 200){               						                                   
                    setMessage("Success!")
                    OpenSnackbar("success")
                    setTimeout(() =>{
                        window.location.reload()
                        setLoading(false)
                        
                    },2000)
                                                                
                }else{                
                    setMessage(res?.body?.message || "Unknow Error")
                    OpenSnackbar("error")
                    setLoading(false)
                }
            })        
                   
        
    }; 
    //* API PUT =>  Updates the selected Event with new images or new data or removed images depending
    const UploadUpdatedEvent = async(event: FormEvent<HTMLFormElement>) => {
  
        event.preventDefault()
        setMessage("")    
        if(loading){
         
            return 
        }             
       

        if(newImageKeys.length > 10){
         
            setMessage(info?.Event?.SnackbarErrorMaxImages)
            OpenSnackbar("error")                
            return
        }

 
      
        setLoading(true)
        const res = await TokenRefresh()                                 
        if(res !== 200) {
         
            setLoading(false)
            return
        }

        

        let inputData = []
     
        let numberOfImages = newImages?.length > 0 ? newImages?.length : 1


        var updatedEvents  = structuredClone(rows)
        updatedEvents[updateIndex]["images"] = updatedEvents[updateIndex]?.images.concat(newImageKeys)
        

        //* Dispatch Loop Function 
        const DispatchItem = async (j:number,updatedEvents: Ievent [], lastIndex:number) => {
                              
            var returndata = {
              
            }              
            let dispatchData
            if(j === lastIndex){
                dispatchData = { 
                    newImage: newImages?.[j]?.file, //
                    newImageType: newImages?.[j]?.type, //  for only uploading new image to s3
                    newImageKey: newImages?.[j]?.key, //

          
                    updatedEvent: updatedEvents, // Only for updating JSON event data for s3 , when j === 0

                    removedImages: removedImagesArr, // if old images has been removed, removes old images from s3, when j === 0
                    index: j ,
                    lastIndex,
                
                    frontPageId: frontPageId,
                 
                }
            }else{
                dispatchData = { 
                    newImage: newImages?.[j]?.file,
                    newImageType: newImages?.[j]?.type,
                    newImageKey: newImages?.[j]?.key,
                    index: j,
                    lastIndex,
                 
                 }
            }
     

            await dispatch(UpdateEvent(dispatchData))
                .unwrap()
                .then((res) => {         
                                                                                                                   
                    returndata = { statusCode: res?.statusCode, message: res?.body?.message }
                 
                })    
            return returndata
        }
    
        
        for(let i = 0; i< numberOfImages; i++){        
            const returnData:any = await DispatchItem(i, updatedEvents, numberOfImages - 1)                
            if(returnData.statusCode !== 200) {
                setMessage(returnData?.message || "Unknow Error")
                OpenSnackbar("error")
                setLoading(false)
                return
            }
            inputData = returnData
        }
        if(inputData.statusCode === 200){
            setMessage(info?.Event?.SnackbarSuccessUploaded)
            OpenSnackbar("success")
            setTimeout(() =>{
                window.location.reload()
                setLoading(false)
                
            },2000)
         
        }


                   
      
    }
    




    //* Removes Image selected from rows[updatedIndex].images and adds to removedImagesArr
    const RemoveOldImage = (key:string,k:number) => {
         
        let tempImageArr = [...rows]
     
        tempImageArr[updateIndex].images.splice(k,1) 
       
        //? should we have this ? setRows([...tempImageArr])

        setRemovedImagesArr(prev => [...prev, key])
        
    }
    //* Removes a new Image from newImages []
    const RemoveNewImage = (j: number) => {            
        let tempImageArr = [...newImages]
        let tempImageArrKeys = [...newImageKeys] 
        tempImageArr.splice(j,1)            
        tempImageArrKeys.splice(j,1)   
       
        setNewImages([...tempImageArr])
        setNewImageKeys([...tempImageArrKeys])
    }
    //* Changes title and info data in rows []
    const HandleUpdateRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;            
        var tempArr = rows
        tempArr[updateIndex][name] = value;            
        setRows([...tempArr] )
    }
    //* Updates FrontpageId
    const handleUpdateFronPageId = () => {                                
        setFrontPageId( frontPageId === rows[updateIndex].id ? "" : rows[updateIndex].id)                       
    }



   

    //* Drag and drop to Base64 => newImages []  */
    const resizeFile = (file:any) =>
        new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            900,            // max width
            900,            //max height
            "WEBP",         // file output
            100,             // 100 best quality, 50, middle, 1 lowest quality
            0,
            (uri) => {
            resolve(uri);
            },
            "base64"
        );
    });   
    const onDrop = async(acceptedFiles:any) => {                              
        try{

            const type = "images/webp"
            
            //const res:any = await ToBase64(acceptedFiles[0])                                     
            const compressedImage:any = await resizeFile(acceptedFiles[0]); 
            
            let tempArrEvent =[...rows[updateIndex]?.images || [] ]
            const cloneNewImages = [...newImages]
            let tempArrNewImages = cloneNewImages.map((obj) => obj?.key ) 
           
            const totalimages = tempArrEvent.concat(tempArrNewImages)

       

            let sortArr = []
            for(let i = 0; i<totalimages.length; i++) {
                const response = totalimages[i].split("_")[1].split(".")[0]	
                sortArr.push(parseInt(response))
            }

                         
            let nextValue = sortArr.sort()[sortArr.length - 1] + 1 || 0
       

            let tempKey = `event/${rows?.[updateIndex].id}_${nextValue}.${type.split("/").slice(-1)[0]}`
            tempArrEvent.push(tempKey)
         
            setNewImageKeys(prev => [...prev, tempKey])
            

            let tempArr = newImages ? newImages  : []
            tempArr.push({file: compressedImage, type: type, key:tempKey })
            setNewImages([...tempArr])


            

        } catch (error) {
            console.log(error)
            setMessage(info?.Event?.FileError)
            OpenSnackbar("error")                
        }          
    }
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {'image/*': []}});
    const style = useMemo(() => ({
        ...dropzoneStyle?.baseStyle,
        ...(isFocused ? dropzoneStyle?.focusedStyle : {}),
        ...(isDragAccept ? dropzoneStyle?.acceptStyle : {}),
        ...(isDragReject ? dropzoneStyle?.rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
        

    return (
        <Box>

        


            {/* Toolbar with Name of the table, selected items and delete button */}
            <Toolbar        
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },               
                }}
            >               
                <Box className="flex__center-r">
                        
                    <Typography
                   /*      sx={{ flex: '1 1 100%' }} */
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {info?.Event?.MainTitle}              
                    
                    </Typography>
                    <Button  onClick={AddNewEventItem} sx={{marginLeft:"1rem"}} variant="outlined" size="small" startIcon={<AddIcon />}>
                        {info?.Event?.AddNewItem}
                    </Button>
                </Box>                                                        
        
            </Toolbar>

            {/* Data grid */}
            <Box sx={{ height: "100%", width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5,10,100]}                    
                    disableRowSelectionOnClick                                     
                    {...rows}
                    processRowUpdate={ (newRow: any, oldRow: any) => {
                        
                        let rowIndex = rows?.findIndex( row => row.id === newRow?.id)
                        
                        let tempRowArr = [...rows]
                        tempRowArr[rowIndex] = newRow
                        setRows([...tempRowArr])
                        return newRow
                    }}
                    onProcessRowUpdateError={(error: any) => console.log("error",error) }
                   
                />
            </Box>


     
            {/* Drag and drop array for categories */}
            <Paper sx={{marginTop:"2rem",padding:"1rem 0"}} className="flex__center-c">

                {/* Title */}
                <Typography variant='h3' paragraph>
                    {info?.Event?.Order_Of_Events}
                </Typography>
                


                {/* Drag and drop elements */}
                <DragDropContext onDragEnd={handleDrop} >
                    <Droppable droppableId="Droppable-container" type='MENUTYPES'>
                    {(provided:any) => (
                        <div
                        
                        
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        {rows?.map((item:Ievent, i) => (              
                            <Draggable key={`draggable${i}`} draggableId={`draggableId${i.toString()}`} index={i} > 
                            {(provided) => (
                            
                                    <div

                                    
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                    >
                                
                                        <Paper sx={{margin:"0.5rem", padding:"0.5rem", minWidth:"15rem",justifyContent:"space-between"}} className='flex__center-r'>
                                            <Box>
                                                <KeyboardArrowDownIcon className="ImArrow" onClick={()=>changeOrder("up",i)}/>
                                                <KeyboardArrowUpIcon className="ImArrow" onClick={()=>changeOrder("down",i)}/>
                                            </Box>
                                            
                                            {item?.title}                                                                                      
                                        </Paper>
                                                                                                                                                    
                                    </div>
                            
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>     
     
            </Paper>


          

            {/* Alertbars / Snackbars  */}
            <Snackbar open={openSnackbar?.success} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"success")} >
                <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","success")} severity="success" sx={{ width: '100%' }} >
                    {message}
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar?.error} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"error")} >
                <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","error")} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar?.warning} autoHideDuration={6000} onClose={(event, reason)=>CloseSnackbar(event,reason,"warning")} >
                <Alert onClose={(event:React.SyntheticEvent | Event)=>CloseSnackbar(event,"","warning")} severity="warning" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>




            {/* Remove Dialog */}
            <Dialog
              open={deleteDialog}
              onClose={CloseDeleteDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {info?.Event?.Delete_Title}
              </DialogTitle>
              <DialogContent>                
                <DialogActions className='row__to__column_300'>             

                    <Button variant="outlined"  onClick={CloseDeleteDialog}>
                        {info?.Event?.Delete_Cancel}
                    </Button>

                    <Button
                        sx={{width: '200px'}}
                        variant="contained"
                        color='error'
                        disabled={loading}
                        onClick={RemoveEvent}                    
                    >
                    {info?.Event?.Delete_Accept}
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: "white",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </Button>

                </DialogActions>
              </DialogContent>    
            </Dialog>

            {/* Update Dialog */}
            <Dialog
              open={updateDialog}
              onClose={CloseUpdateDialog}
           
            >
                {/* Title and exit button */}
                <DialogTitle className="flex__center-r" sx={{justifyContent:"space-around"}}>

                    <Typography variant="subtitle1" >
                        {info?.Event?.Update_Title}
                    </Typography>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={CloseUpdateDialog}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>


                <DialogContent>                
                    <DialogActions >                                             
                        <form className="flex__center-c" onSubmit={UploadUpdatedEvent}>
                      

                            {/* Button Save */}
                            <Button
                                sx={{width: '180px'}}
                                variant="contained"
                                color='success'
                                type='submit'
                                disabled={loading}
                                //onClick={UploadUpdatedEvent}
                                endIcon={<SaveIcon />}
                            >
                                {info?.Event?.Save}
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                            )}
                            </Button>

                            <TextField       
                                required                         
                                label={`Title`}
                                sx={{m:"0.5rem 0",label:{color:"grey.400"}}}                                                                                 
                                multiline
                                fullWidth 
                                maxRows={4}
                                name={`title`}
                                value={rows[updateIndex]?.title}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleUpdateRow(event);
                                }}
                            />
                            <TextField    
                                required                          
                                label={`Info`}
                                sx={{m:"0.5rem 0",label:{color:"grey.400"}}}
                                multiline
                                fullWidth 
                                maxRows={4}
                                name={`info`}
                                value={rows[updateIndex]?.info}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleUpdateRow(event);
                                }}
                            />
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{info?.Event?.Off}</Typography>
                                <AntSwitch 
                                    checked={frontPageId === rows?.[updateIndex]?.id}
                                    onChange={ handleUpdateFronPageId }
                                inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>{info?.Event?.On}</Typography>
                            </Stack>


                            {/* Drag n Drop for Images */}
                            <div className="container">
                                <div {...getRootProps({style})}>
                                    <input {...getInputProps()} />
                                    <p>{info?.Event?.DropAndDrag}</p>
                                </div>
                            </div>



                            {/* Old Images */}
                            <Box sx={{ flexGrow: 1, p: 2 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                    '--Grid-borderWidth': '1px',
                                    borderTop: 'var(--Grid-borderWidth) solid',
                                    borderLeft: 'var(--Grid-borderWidth) solid',
                                    borderColor: 'divider',
                                    '& > div': {
                                        borderRight: 'var(--Grid-borderWidth) solid',
                                        borderBottom: 'var(--Grid-borderWidth) solid',
                                        borderColor: 'divider',
                                    },
                                }}
                            >
                                {rows[updateIndex]?.images?.map((item,k) => {
                                
                                    return(
                                    
                                    <ImageListItem key={item}>             
                                        <ImageListItemBar
                                                sx={{
                                                    background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                }}
                                                //title={item}
                                                position="top"
                                                actionIcon={
                                                    <IconButton
                                                    sx={{ color: 'white' }}
                                                    aria-label={`star ${item}`}
                                                    onClick={() => RemoveOldImage(item,k)}
                                                    >
                                                    <ClearIcon                                                
                                                        
                                                        color="primary"
                                                        sx={{ cursor: 'pointer',color: "#ffffff"}}                                                
                                                    />
                                                    </IconButton>
                                                }
                                                actionPosition="left"
                                                />
                                        <img
                                            src={`https://${process.env.REACT_APP_CDN_URL}/${item}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`https://${process.env.REACT_APP_CDN_URL}/${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        
                                            style={{ width: '164px', height: '164px', objectFit:'cover'}}
                                            alt={item}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                )})}
                                </Grid>
                            </Box>


                            {/* New Images */}
                            <Box sx={{ flexGrow: 1, p: 2 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                    '--Grid-borderWidth': '1px',
                                    borderTop: 'var(--Grid-borderWidth) solid',
                                    borderLeft: 'var(--Grid-borderWidth) solid',
                                    borderColor: 'divider',
                                    '& > div': {
                                        borderRight: 'var(--Grid-borderWidth) solid',
                                        borderBottom: 'var(--Grid-borderWidth) solid',
                                        borderColor: 'divider',
                                    },
                                    }}
                                >
                                {newImages?.map((item,j) => {
                                    
                                    return(
                                    
                                    <ImageListItem key={`newImages${j}`}>             
                                        <ImageListItemBar
                                                sx={{
                                                    background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                }}
                                                //title={item}
                                                position="top"
                                                actionIcon={
                                                    <IconButton
                                                    sx={{ color: 'white' }}
                                                    aria-label={`star ${j}`}
                                                    onClick={() => RemoveNewImage(j)}
                                                    >
                                                    <ClearIcon                                                
                                                        
                                                        color="primary"
                                                        sx={{ cursor: 'pointer',color: "#ffffff"}}                                                
                                                    />
                                                    </IconButton>
                                                }
                                                actionPosition="left"
                                                />
                                        <img
                                            src={`${item.file}`}
                                            srcSet={`${item.file}`}
                                            style={{ width: '164px', height: '164px', objectFit:'cover'}}
                                            alt={`newupload${j}`}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                )})}
                            </Grid>
                            </Box>

                            
                        </form>                    
                    </DialogActions>
              </DialogContent>    
            </Dialog>

        </Box>   
    )
}

export default Event



