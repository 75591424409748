import { useEffect, useState } from 'react';

/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import { GetShop } from '../../slices/tokenReducer'; 
import   { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

// self
import { Iinfo, Imenu } from '../../types/interface';
import { MenuTable } from '../../components';



dayjs.extend(weekOfYear)





interface Iprops {
    info: Iinfo | undefined
}
const Lunch = (props:Iprops) => {
    const { info } = props;

    const dispatch: AppDispatch = useDispatch()
    
    const [week, setWeek] = useState<number>(dayjs().week())  
    const [menu, setMenu] = useState<Imenu []>([])
  
    const [categories, setCategories] = useState<string []>([])

    const [loading, setLoading] = useState<boolean>(true)
    const [failedFetch, setFailedFetch] = useState<boolean>(false)


    useEffect(() => {
        setLoading(true)
        FetchMenu()                       
      
        return () => {
            
        }
    }, [week])
   
    const FetchMenu = async() => {
       
        try {
            await dispatch(GetShop({typeOfMenu:"takeaway"}))
                .unwrap()
                .then((res) => {      
                   
                    if(res.statusCode === 200){                        
                        
                        const foundItems = res?.body?.data.filter((item:any) => item?.sk === "takeaway" && item?.pk !== "category" )
                        const foundCategory = res?.body?.data.filter((item:any) => item?.sk === "takeaway" && item?.pk === "category" )
                                                                                        
                        setMenu([...foundItems || []])                                                                            
                        setCategories([...foundCategory?.[0]?.categories || []])
                       
                                            
                    }else{
                        setFailedFetch(true)            
                    }
                    
            })   
        } catch (error) {            
            console.log(error)
            setFailedFetch(true)
        } 
        setLoading(false)
    }
    


    return (
        <MenuTable 
            info={info?.Takeaway}
            TypeOfMenu={"takeaway"} 
            parentMenu={menu}
            parentCategories={categories}          
                
            failedFetch={failedFetch}
            fetchLoading={loading}
        
        />
    )
}



export default Lunch



