//mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

//self
import { Iinfo } from '../../types/interface'
import { Clock } from '../../components';



interface Iprops {
    info: Iinfo
}
const Welcomepage = (props:Iprops) => {
    const { info } = props
    
    return (        
        <Box className="flex__center-c" sx={{minHeight:"75vh"}}>
            <Typography variant='h3'>                
                {new Date().toDateString()}                            
            </Typography>
            <Clock />
        </Box>
        
    )
}

export default Welcomepage