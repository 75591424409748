import { useState, useEffect, ChangeEvent } from 'react'
import dayjs from 'dayjs';

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { GridColDef } from '@mui/x-data-grid';

// redux
import { GetReservations, AddReservationClosed, GetInfoDb, UpdateInfoDb } from '../../slices/tokenReducer';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';

import { TokenRefresh } from '../../functions/Token';
import { TimeDatePicker, TabsComponent, SaveAndReset, SnackbarComponent, TextTable } from '../../components/index';
import { Iinfo,Iinfodb } from '../../types/interface'





const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#03fc41',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
}));







const inittime = dayjs().hour(0).minute(0).second(0).millisecond(0).valueOf()
interface Iprops {
    info: Iinfo
}
const ReservationSettings = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()
    
    const [infoDb, setInfoDb] = useState<Iinfodb>({} as Iinfodb)
    const [infoDbReadOnly, setInfoDbReadOnly] = useState<Iinfodb>({} as Iinfodb)
    const [ startTime, setStartTime] = useState<number>(inittime);
    const [ endTime, setEndTime] = useState<number>(inittime);
    const [ startTimeDialog, setStartTimeDialog ] = useState<boolean>(false)
    const [ endTimeDialog, setEndTimeDialog ] = useState<boolean>(false)
    const [ tab, setTab ] = useState<number>(0)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ alert, setAlert ] = useState<string>("")
    const [ message, setMessage ] = useState<string>("")
    const [ closedMessage, setClosedMessage ] = useState<string>("")
    interface Ireservationdata {
        [key:string]: string | number
    }
    const [ reservations, setReservations ] = useState<Ireservationdata []>([])

   
        

    const Submit = async() => {
        setAlert("")
        setMessage("")      
        if(endTime < startTime){
            setAlert("warning")
            setMessage("You can set the end time before the start time")      
            //ResetStartEndTime()    
            return  
        }
        setLoading(true)
        const res = await TokenRefresh()                                 
        
        if(res !== 200) {setLoading(false) ;return}

        const date = dayjs(startTime).hour(0).minute(0).second(0).millisecond(0).valueOf()
        //const dateLast = dayjs(endTime).hour(0).minute(0).second(0).millisecond(0).valueOf()
        await dispatch(AddReservationClosed({startTime: startTime, endTime: endTime, other: closedMessage, date:date}))
            .unwrap()
            .then((res) => {               
               
                if(res?.statusCode === 200){               						                                                           
                    setMessage(info?.ReservationSettings?.UpdateSuccess || "Success")                    
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.ReservationSettings?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    const SubmitInfoDb = async() => {
        setAlert("")
        setMessage("")      
        
        setLoading(true)
        const res = await TokenRefresh()                                 
        
        if(res !== 200) {setLoading(false) ;return}

        
        
        await dispatch(UpdateInfoDb({dbInformation: infoDb}))
            .unwrap()
            .then((res) => {               
               
                if(res?.statusCode === 200){               						                                                           
                    setMessage(info?.ReservationSettings?.UpdateSuccess || "Success")                    
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.ReservationSettings?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    const ResetStartEndTime = async() => {
        setStartTime(inittime)
        setEndTime(inittime)
    }
    const ResetInfoDb = async() => {
        setInfoDb(infoDbReadOnly)        
    }
    useEffect(() => {
        FetchData()
        FetchInfoDb()
    },[])
    const FetchData = async() => {
        await dispatch(GetReservations({}))
            .unwrap()
            .then(res => {
                if(res?.statusCode === 200){
                    interface Ires {
                        pk: string
                        id: string
                        type: string
                        startTime: number
                        endTime: number
                        people: number
                        message: string
                        duration: number
                    }
                    const filteredData = res?.body?.data?.filter((item:Ires) => item?.pk?.split("#")[0] === "closed"  )
                    setReservations( filteredData || [])
                }else{
                    setMessage("Failed to fetch Data")
                    setAlert("error")
                }
            })
    }
    const FetchInfoDb = async() => {
        await dispatch(GetInfoDb({}))
        .unwrap()
        .then(res => {
        
            if(res?.statusCode === 200){
                setInfoDb(res?.body?.data)
                setInfoDbReadOnly(res?.body?.data)
                
            }
            
        })
    }
    //! Columns , Edit this for wanted data types
    const columns: GridColDef[] = [    
        {
            field: 'time',
            headerName: 'StartTime',
            minWidth: 200,            
            editable: false,
            valueGetter: (params) => new Date(params?.value).toLocaleString()
        },
        {
            field: 'duration',
            headerName: 'EndTime',
            minWidth: 200,            
            editable: false,
            valueGetter: (params) => new Date(params?.row?.time + params?.row?.duration).toLocaleString()
        },        
        {
            field: 'other',
            headerName: 'Message',
            minWidth: 200,
            flex:1,
            editable: false,
            
        },
                            
    ];

    //* Changes value of string and number */
    interface ObjectAll { [key:string]:string | boolean | number | {} | string []}
    const ChangeValueDb = (input: ObjectAll | string | number | boolean, name: string)=> {        
        setInfoDb(prev => ({...prev, [name]: input}))
    }
    //* Change True of False on Boolean values */
    const ChangeActiveStateDb = (event:ChangeEvent<HTMLInputElement>/* , value: boolean | undefined */)=> {
        const { name } = event.target;  

        const GetObjectValueWithKey = (str: string ) => {              
            const tempobj = infoDb || {}                                                                                                                                                          
            return tempobj[str as keyof typeof tempobj];
        };
        const val = GetObjectValueWithKey(name)
        
        setInfoDb(prev => ({...prev, [name]:  !val }))        
        
        
    }
    

    return (
        <Box sx={{minHeight:"100vh",width:"100vw",justifyContent:"flex-start",mt:"1rem"}} className="flex__center-c">

            <TabsComponent setTab={setTab} tab={tab} labels={[info?.ReservationSettings?.Tab_0, info?.ReservationSettings?.Tab_1, info?.ReservationSettings?.Tab_2]} info={info}/>


            {tab === 0 &&
            <Paper className="flex__center-c" sx={{p:"1rem",mt:"2rem"}}>
        
                <SaveAndReset loading={loading} reset={ResetStartEndTime} save={Submit} info={info}/>

                <Typography variant='subtitle1'>{info?.ReservationSettings?.Text_Description}</Typography>

                <Box className="flex" sx={{justifyContent:"space-around", width:"100%",mt:"2rem"}}>
                    
                    <Box className="flex__center-c">
                        <Button color="primary" variant="contained" onClick={()=>setStartTimeDialog(true)} >{info?.ReservationSettings?.Text_OpenStartTime}</Button>
                        
                        <Typography variant='h4'> { new Date(startTime).toLocaleDateString("sv-SE", {timeZone: 'Europe/Stockholm' }) } </Typography>
                        <Typography variant='h4'> { new Date(startTime).toLocaleTimeString("sv-SE", {timeZone: 'Europe/Stockholm', hour: "2-digit", minute: "2-digit" }) } </Typography>
                        

                    </Box>  
                    <Box className="flex__center-c">
                        <Button color="primary" variant='contained' onClick={()=>setEndTimeDialog(true)}>{info?.ReservationSettings?.Text_OpenEndTime}</Button>
                        <Typography variant='h4'> { new Date(endTime).toLocaleDateString("sv-SE", {timeZone: 'Europe/Stockholm' }) } </Typography>
                        <Typography variant='h4'> { new Date(endTime).toLocaleTimeString("sv-SE", {timeZone: 'Europe/Stockholm', hour: "2-digit", minute: "2-digit" }) } </Typography>
                    </Box>

                </Box>

                <TextField 
                    sx={{label:{color:"text.primary"},mt:"3rem",width:"100%"}}
                    label="Write a message.."
                    size='small'
                    value={closedMessage}                    
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => setClosedMessage(event?.target?.value) }
                />   
                
            </Paper>
            }
            {tab === 1 &&
            <Box className="flex__center-c" width={"100vw"}>
                <TextTable 
                    info={info?.ReservationSettings}
                    type={"reservationsettings"} 
                    parentRow={reservations}
                    
                    failedFetch={false}
                    fetchLoading={loading}
                    columns={columns}
                />
            
                
            </Box>
            }
            {tab === 2 &&
            <Box className="flex__center-c" width={"98vw"} sx={{gap:"2rem",mt:"2rem"}}>
                   {/* Message for Shop  */}
             {/*    <Paper sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>                       
                    <TextField                      
                        fullWidth               
                        sx={{label: {color:"grey.400"}}}
                        name={`Shop_Message`}                        
                        label={info?.Info?.Shop_Message}
                        size='small'
                        value={infoDb.Shop_Message || "" }
                        onChange={(event)=>ChangeValueDb(event.target.value, event.target.name)}
                    
                    />                
                </Paper> */}
                 <SaveAndReset loading={loading} reset={ResetInfoDb} save={SubmitInfoDb} info={info}/>

                {/* Switches for turning on or off reservation system/ contact form / shop etc... */}
                <Paper sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.Reservation_Active}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{info?.Info?.Off}</Typography>
                        <AntSwitch 
                            name='Reservation_Active'
                            checked={ infoDb?.Reservation_Active === undefined ? false : infoDb.Reservation_Active }
                            onChange={(event)=>ChangeActiveStateDb(event/* , infoDb?.[event.target.name] */)}
                        inputProps={{ 'aria-label': 'ant design' }} />
                        <Typography>{info?.Info?.On}</Typography>
                    </Stack>
                </Paper>

                <Paper sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.Shop_Active}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{info?.Info?.Off}</Typography>
                        <AntSwitch 
                            name='Shop_Active'
                            checked={ infoDb?.Shop_Active === undefined ? false : infoDb.Shop_Active}
                            onChange={(event)=>ChangeActiveStateDb(event/* , infoDb?.[event.target.name] */)}
                        inputProps={{ 'aria-label': 'ant design' }} />
                        <Typography>{info?.Info?.On}</Typography>
                    </Stack>
                </Paper>
            
                {/* Lunch Settings */}
                <Paper elevation={5}  sx={{p:"1rem",width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationLunch}
                    <TimePicker  value={ dayjs().hour(infoDb?.lunchOpenTime?.$H || 0).minute(infoDb?.lunchOpenTime?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchOpenTime")} ampm={false} label="Open" sx={{m:"0.5rem 0", label: {color:"grey.400"}}}      />
                    <TimePicker value={ dayjs().hour(infoDb?.lunchCloseTime?.$H || 0).minute(infoDb?.lunchCloseTime?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchCloseTime")} ampm={false} label="Close" sx={{label: {color:"grey.400"}}} />
                </Paper>
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationLunch}
                    <TimePicker value={ dayjs().hour(infoDb?.lunchDuration?.$H || 0).minute(infoDb?.lunchDuration?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchDuration")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationLunch_morePeople}
                    <TimePicker value={ dayjs().hour(infoDb?.lunchDuration_morePeople?.$H || 0).minute(infoDb?.lunchDuration_morePeople?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchDurationWeekend")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>

                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationLunchWeekend}
                    <TimePicker value={ dayjs().hour(infoDb?.lunchOpenTimeWeekend?.$H || 0).minute(infoDb?.lunchOpenTimeWeekend?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchOpenTimeWeekend")} ampm={false} label="Open" sx={{m:"0.5rem 0", label: {color:"grey.400"}}} />
                    <TimePicker value={ dayjs().hour(infoDb?.lunchCloseTimeWeekend?.$H || 0).minute(infoDb?.lunchCloseTimeWeekend?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchCloseTimeWeekend")} ampm={false} label="Close" sx={{label: {color:"grey.400"}}}/>
                </Paper>                
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationLunchWeekend}
                    <TimePicker value={ dayjs().hour(infoDb?.lunchDurationWeekend?.$H || 0).minute(infoDb?.lunchDurationWeekend?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchDurationWeekend")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationLunchWeekend_morePeople}
                    <TimePicker value={ dayjs().hour(infoDb?.lunchDurationWeekend_morePeople?.$H || 0).minute(infoDb?.lunchDurationWeekend_morePeople?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "lunchDurationWeekend")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>


                {/* Dinner Settings */}
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDinner}
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerOpenTime?.$H || 0).minute(infoDb?.dinnerOpenTime?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerOpenTime")} ampm={false} label="Open" sx={{m:"0.5rem 0", label: {color:"grey.400"}}} />
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerCloseTime?.$H || 0).minute(infoDb?.dinnerCloseTime?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerCloseTime")} ampm={false} label="Close" sx={{label: {color:"grey.400"}}}/>
                </Paper>
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationDinner}
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerDuration?.$H || 0).minute(infoDb?.dinnerDuration?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerDuration")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationDinner_morePeople}
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerDuration_morePeople?.$H || 0).minute(infoDb?.dinnerDuration_morePeople?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerDuration")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>

                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDinnerWeekend}
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerOpenTimeWeekend?.$H || 0).minute(infoDb?.dinnerOpenTimeWeekend?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerOpenTimeWeekend")} ampm={false} label="Open" sx={{m:"0.5rem 0", label: {color:"grey.400"}}} />
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerCloseTimeWeekend?.$H || 0).minute(infoDb?.dinnerCloseTimeWeekend?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerCloseTimeWeekend")} ampm={false} label="Close" sx={{label: {color:"grey.400"}}}/>
                </Paper>                
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationDinnerWeekend}
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerDurationWeekend?.$H || 0).minute(infoDb?.dinnerDurationWeekend?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerDurationWeekend")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>
                <Paper elevation={5}  sx={{p:"1rem", width:"97%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                    {info?.Info?.ReservationDurationDinnerWeekend_morePeople}
                    <TimePicker value={ dayjs().hour(infoDb?.dinnerDurationWeekend_morePeople?.$H || 0).minute(infoDb?.dinnerDurationWeekend_morePeople?.$m || 0)  } views={['hours', 'minutes']} onAccept={(event:any)=>ChangeValueDb({$H: event?.$H, $m: event?.$m}, "dinnerDurationWeekend")} ampm={false} sx={{m:"0.5rem 0", label: {color:"grey.400"}}}  />
                </Paper>


                {/* Total number of tables at the restaurant  */}
                <Paper sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>                       
                    <TextField 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        fullWidth               
                        sx={{label: {color:"grey.400"}}}
                        name={`TotalNumberOfTables`}
                        
                        label={info?.Info?.TotalNumberOfTables}
                        size='small'
                        value={infoDb.TotalNumberOfTables || 0 }
                        onChange={(event)=>ChangeValueDb(parseInt(event.target.value), event.target.name)}
                    
                    />                
                </Paper>

                {/* Max number of guests at a table */}
                <Paper sx={{width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>                       
                    <TextField 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        fullWidth               
                        name={`MaxNumberOfGuestPerReservation`}
                        
                        label={info?.Info?.MaxNumberOfGuestPerReservation}
                        size='small'
                        value={infoDb.MaxNumberOfGuestPerReservation || 0}
                        onChange={(event)=>ChangeValueDb(parseInt(event.target.value), event.target.name)}
                    
                    />                
                </Paper>


                {/* Chaning Time Intervall between Reservations 15,30, 60 minutes between */}
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="simple-select-label">{info?.Info?.timeIntervallBetweenEachReservation}</InputLabel>
                    <Select
                        labelId="simple-select"
                        id="simple-select"
                    /*     defaultValue={`${3600000}`} */
                        value={`${infoDb?.timeIntervallBetweenEachReservation || 3600000 }`}            
                        label={info?.Info?.timeIntervallBetweenEachReservation}
                        name={`timeIntervallBetweenEachReservation`}
                        onChange={(event)=>ChangeValueDb(event.target.value, event.target.name)}
                    >       
                    <MenuItem value={`${3600000}`}>{info?.Info?.timeIntervallBetweenEachReservation_Full}</MenuItem>
                    <MenuItem value={`${1800000}`}>{info?.Info?.timeIntervallBetweenEachReservation_Half}</MenuItem>
                    <MenuItem value={`${900000}`}>{info?.Info?.timeIntervallBetweenEachReservation_Quarter}</MenuItem>
                    </Select>
                    <FormHelperText>{info?.Info?.timeIntervallBetweenEachReservation_Text}</FormHelperText>
                </FormControl>



               

          

           
       
            </Box>
            }

            

            {/* Start Timer */}
            <TimeDatePicker info={info} setDate={setStartTime} date={startTime} close={setStartTimeDialog} open={startTimeDialog} />

            {/* End Timer */}
            <TimeDatePicker info={info} setDate={setEndTime} date={endTime} close={setEndTimeDialog} open={endTimeDialog} />

            <SnackbarComponent alert={alert} message={message} setAlert={setAlert} setMessage={setMessage}/>

        </Box>
    )
}

export default ReservationSettings