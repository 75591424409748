import { useEffect, Fragment, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import Tooltip from '@mui/material/Tooltip';

import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';


import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';



/* Data grid imports */
import Box from '@mui/material/Box';
import {GridRenderCellParams,useGridApiContext, DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

// REDUX
import { GetPayments, UpdatePayments } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface';
import { SnackbarComponent } from '../../components';


interface Iprops {
    info: Iinfo | undefined
}
const Payments = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()

    /* Table */
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [loading, setLoading] = useState(false)          


    

    interface ObjectAll {[key:string]:string|number|boolean}
    const [rows, setRows] = useState<ObjectAll []>([])    
    const [oldData, setOldData] = useState<ObjectAll []>([])
    const [removedRows, setRemovedRows] = useState<GridRowSelectionModel>([]);
    const [updatedRows, setUpdatedRows] = useState<ObjectAll []>([]);
  

    const [message, setMessage] = useState<string | undefined>("")    
    const [alert, setAlert] = useState<string>("")    
    const [openInfoDialog, setOpenInfoDialog] = useState(false)
    const [selectedInfo, setSelectedInfo] = useState<Ipaymentinfo>({} as Ipaymentinfo)
   

    //* GetPayments from db */
    useEffect(() => {
        setLoading(true)
        try {
            const myfunc = async() => {           
                const res = await TokenRefresh()                                 
                if(res !== 200) {
                    setLoading(false)
                    return     
                }           
                await dispatch(GetPayments({}))
                .unwrap()
                .then((res:any) => {
                   
                    if(res?.statusCode === 200){               						                                                           
                        const filteredData  = res?.body?.data?.filter((item:Ipaymentinfo) => item?.id)
                        setLoading(false)
                        setOldData([...filteredData || []])
                        setRows([...filteredData || []])                                                                
                    }else{                
                        setMessage(res?.body?.message || info?.Payments?.FailedFetch || "Failed to fetch data")
                        setAlert("error")
                        setLoading(false)
                    }
                    
                })
            }
            myfunc()
        } catch (error) {
            setMessage(info?.Payments?.FailedFetch)
            setAlert("error")
            setLoading(false)
        }
        
        return () => {
        
        }
    }, [])


    interface Ipaymentinfo {    
        [key:string]: string|number|boolean | {[key:string]:string|number|boolean}
        "CreatedOn": number
        "lastName": string
        "status": string
        "paymentIntent": string
        "email": string
        "firstName": string
        "OrderSend": string
        "stripeInfo": {},
        "store_data": boolean,
        "id": string
        "pk": string
        "swishInfo": {
            [key:string]: string|number|boolean  
            "amount": number
            "datePaid":string
            "paymentReference":string
            "errorMessage":string
            "errorCode":string
            "message":string
            "payeePaymentReference":string
            "dateCreated":string
            "payerAlias":string
            "callbackUrl":string
            "currency":string
            "id":string
            "payeeAlias":string
            "status":string
        },
        "approveNewsletter": boolean,
        "phone": string
        "type": string
        
    }
    //* More info dialog
    const HandleOpenInfoDialog = (row:Ipaymentinfo) => {
        setOpenInfoDialog(true)
        setSelectedInfo(row)

    }
    const HandleCloseInfoDialog = () => {
        setOpenInfoDialog(false)
        setSelectedInfo({} as Ipaymentinfo)

    }
  


    //* Adds removed items to removedRows state && removes items from updatedRows state if it exists
    const RemoveItems = () => {
        let tempRow = [...rows]
        let tempUpdatedRowArr = [...updatedRows]
        let tempSelect = [...rowSelectionModel]        
        let tempRemovedRows = [...removedRows] 

        tempRemovedRows = tempRemovedRows.concat(tempSelect)
        for (let i = 0; i < tempSelect.length; i++) {
            const selectedId = tempSelect[i];
            const filteredRow = tempRow?.find((row) => row.id === selectedId)
            const indexOfRemovedItem = tempRow?.indexOf(filteredRow || {})                                    
            tempRow?.splice(indexOfRemovedItem,1)         
            
            let rowIdUpdated = updatedRows?.findIndex( row => row.id === selectedId)                                     
            rowIdUpdated !== -1 && tempUpdatedRowArr?.splice(rowIdUpdated,1)         
            

        }   
        setUpdatedRows([...tempUpdatedRowArr])              
        setRemovedRows([...tempRemovedRows])
        setRows([...tempRow])
    }
    const SaveChanges = async() => {
        setLoading(true)
        setMessage("")
        if(removedRows.length <= 0 && updatedRows.length <= 0) {
            setMessage(info?.Payments?.NoChangesMade)
            setAlert("warning")
            setLoading(false)
            return
        }
        if(removedRows.length + updatedRows.length > 90 ) {
            setMessage(info?.Payments?.ToManyChanged_Warning)
            setAlert("warning")
            setLoading(false)
            return
        }
        const res = await TokenRefresh()                                 
        if(res !== 200) {setLoading(false);return}

        let dataToSend = []
        for (let i = 0; i < updatedRows.length; i++) {
            const updatedRow = updatedRows[i]
            dataToSend.push({
                OrderSend: updatedRow?.OrderSend,
                pk: updatedRow?.pk
            })
        }

        
        await dispatch(UpdatePayments({removedRows: removedRows, updatedRows: dataToSend}))
            .unwrap()
            .then((res) => {
               
                if(res?.statusCode === 200){               						                                                           
                    setMessage(info?.Payments?.UpdateSuccess || "Success")
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.Payments?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    const ResetAll = () => {
        setRows([...oldData])
        setRemovedRows([])
        setUpdatedRows([])
    }



    const paymentcategories = ["Ej Skickad","Skickad"]
    //*  Selection Component for a column x row */ 
    function SelectEditInputCell(props: GridRenderCellParams) {
        const { id, value, field } = props;
     
        const apiRef = useGridApiContext();
      
        const handleChange = async (event: SelectChangeEvent) => {
            
          await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
          apiRef.current.stopCellEditMode({ id, field });
         
        };
      
        return (
          <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
            autoFocus
          >
            {paymentcategories?.map((category,i) => <option key={`option${category}${i}`}>{category}</option> )}         
                           
          </Select>
        );
    }
    const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {      
        return <SelectEditInputCell {...params} />
    };

    const columns: GridColDef[] = [
       /*  { field: 'id', headerName: 'ID', width: 90 }, */
        {
            field: 'infobutton',
            headerName: 'More info',
            width: 120,
            editable: true,            
            renderEditCell: renderSelectEditInputCell,
            renderCell: (params: any) =>  {     
                         
                return (                    
                    <Chip icon={ <InfoIcon /> } color="primary" label={"More Info"} variant="outlined" size='small' sx={{cursor:"pointer"}} onClick={()=>HandleOpenInfoDialog(params?.row)}/>
                    
                )
            }                     
        },  
        {
            field: 'OrderSend',
            headerName: 'Order send',
            width: 120,
            editable: true,            
            renderEditCell: renderSelectEditInputCell,
            renderCell: (params: any) =>  {
                const value = params.value.replace("_"," ")              
                const state = value === "Skickad" ? true : false
                return (                    
                    <Chip icon={state ? <TaskAltIcon /> : <HighlightOffIcon /> } color={state ? "success" : "warning" } label={value} variant="outlined" size='small'/>
                    
                )
            }                                 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            editable: false,
            renderCell: (params: any) =>  {
                const value = params.value                
                const state = value === "paid" ? true : false
                let color:any = "info"
                switch (value) {
                    case "paid":
                        color = "success"
                        break;                    
                    case "unpaid":
                        color = "warning"
                        break;                                    
                    default:
                        color ="error"
                        break;
                }
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        {/* <span className="table-cell-trucate">{params.value}</span> */}
                        <Chip icon={state ? <TaskAltIcon /> : <HighlightOffIcon /> }  color={color} label={value} variant="outlined" size="small" sx={{textTransform:"capitalize"}}/>
                    </Tooltip>
                )
            }
        
        
        },      
        {
            field: 'firstName',
            headerName: 'First name',
            width: 120,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        
          
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
         {
            field: 'email',
            headerName: 'Email',          
            width: 200,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
       {
            field: 'CreatedOn',
            headerName: 'Date',                    
            type: 'date',
            
            width: 200,
            editable: false,
            valueGetter: ({ value }) => value && new Date(value),//{console.log("value",value*1000);value && new Date(value).toLocaleDateString()},
            renderCell: (params: any) =>  {                         
                let displayVal =  new Date(params.value).toLocaleString("sv-SE", {timeZone: 'Europe/Stockholm' })                
                return (
                    <Tooltip title={displayVal} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{displayVal}</span>
                    </Tooltip>
                    )
            }
        },
        {
            field: 'phone',
            headerName: 'Phone',          
            width: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        },
      
        {
            field: 'totalAmount',
            headerName: 'Amount',          
            width: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value} SEK</span> 
                    </Tooltip>
                    )
            }
        
        },
        {
            field: 'id',
            headerName: 'id',          
            minWidth: 110,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }
        }, 
        {
            field: 'expired_status',
            headerName: 'expired',          
            minWidth: 50,
            editable: false,            
            type:"string"
        }, 

        
       
    ];
    const columnsPurchasedItems: GridColDef[] = [        
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 150,            
            editable: false,
     
        },  
        {
            field: 'price',
            headerName: 'Price',
            width: 80,
            editable: false,
     
        },  
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 80,
            editable: false,
     
        },          
        {
            field: 'sk',
            headerName: 'Type',          
            minWidth: 110,
            editable: false,
         
        },
        {
            field: 'id',
            headerName: 'id',          
            minWidth: 110,
            editable: false,
         
        },                   
    ];

    return (
        <Fragment>

            {/* Save and Reset Button */}
            <Container                                
                sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
            >
                <Button
                    sx={{width: '180px'}}
                    variant="contained"
                    color='success'
                    disabled={loading}
                    onClick={SaveChanges}
                    endIcon={<SaveIcon />}
                >
                {info?.Payments?.Save}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "teal",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />
                )}
                </Button>

                <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={ResetAll}>
                    {info?.Payments?.Reset}
                    </Button>
               
            </Container>

            <CssBaseline /> 


            {/* Toolbar with Name of the table, selected items and delete button */}
            <Toolbar
        
                sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(rowSelectionModel.length > 0 && {
                    bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                }}
            >
                {rowSelectionModel.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {rowSelectionModel.length} {info?.Payments?.Selected}
                    
                </Typography>
                
                ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {info?.Payments?.MainTitle}              
                
                </Typography>
                
                )}
            
            {rowSelectionModel.length > 0 && (
                <Tooltip title={info?.Payments?.DeleteTooltip}>
                    <IconButton  onClick={RemoveItems}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            )}
        
            </Toolbar>

            {/* Data grid */}
            <Box sx={{ height: "auto", width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        sorting: {
                            sortModel:  [{ field: 'CreatedOn', sort: "desc" }],                            
                        },
                        filter:{
                            filterModel: {
                                items: [{ field: 'expired_status', operator: 'contains', value: "complete" }]
                            },
                        }
                    }}
                    pageSizeOptions={[5,10,100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    {...rows}
                    processRowUpdate={ (newRow: any, oldRow: any) => {
                        
                        let rowId = rows?.findIndex( row => row.id === newRow?.id)
                        let rowIdUpdated = updatedRows?.findIndex( row => row.id === newRow?.id)
                        let tempUpdatedRowArr = [...updatedRows]                            
                        rowIdUpdated === -1 ? tempUpdatedRowArr.push(newRow) : tempUpdatedRowArr[rowIdUpdated] = newRow                                                                            
                        setUpdatedRows([...tempUpdatedRowArr])

                        let tempRowArr = [...rows]
                        tempRowArr[rowId] = newRow
                        setRows([...tempRowArr])
                        return newRow
                    }}
                    onProcessRowUpdateError={(error: any) => console.log("error",error) }
                />
            </Box>


            {/* open information for a single item */}
            <Dialog                
                open={openInfoDialog}
                onClose={HandleCloseInfoDialog}
                //sx={{height:"100%"}}
                fullWidth
                //fullScreen
            >
                <Box     sx={{p:"1rem"}}>
                    <Box className="flex__center-c" alignItems="flex-start">
                        <Typography variant='h5'>
                            id:  {selectedInfo?.id}
                        </Typography>

                        <Typography>
                            First name:  {selectedInfo?.firstName}
                        </Typography>
                                            
                        <Typography>
                            Last name:  {selectedInfo?.lastName}
                        </Typography>

                        <Typography>
                            Email:  {selectedInfo?.email}
                        </Typography>

                        <Typography>
                            Phone:  {selectedInfo?.phone}
                        </Typography>

                        <Typography>
                            Status:  {selectedInfo?.status}
                        </Typography>

                        <Typography>
                            Order send:  {selectedInfo?.OrderSend}
                        </Typography>

                        <Typography>
                            Date:  {new Date((selectedInfo?.CreatedOn)).toLocaleString("sv-SE", {timeZone: 'Europe/Stockholm' })     }
                        </Typography>
                        
                    </Box>
                    {Object.keys(selectedInfo).map((key:string,i) => {
                        const keysToInclude = [""]
                        const value = selectedInfo[key]
                        
                        if( (typeof value === "string" || typeof value === "number" ||typeof value === "boolean") && keysToInclude.includes(key) ){
                        
                            return(
                                <Box key={`paymentsdetails${key}${i}`}>
                                    <Typography>
                                        {key}:  {value}
                                    </Typography>
                                    
                                </Box>
                            )
                        }else if(Array.isArray(value) && key === "purchased_Items"){
                        
                            const tempRow:GridColDef[] = []
                            value.forEach((arritem,j) => {
                                arritem["id"] = arritem?.pk
                                tempRow.push(arritem)

                            })
                            return(
                                <Box sx={{m:"2rem 0",width:"100%"}} key={`paymentsdetails${key}${i}`}>
                                    <Typography variant='h4'>
                                        Items Purchased
                                    </Typography>
                                    
                        
                                    <DataGrid
                                        autoHeight
                                        sx={{width:"100%"}}
                                        hideFooter
                                        rows={tempRow}
                                        density="compact"
                                        columns={columnsPurchasedItems}                                                    
                                    />                               
                                                
                                            
                                            
                                        
                                </Box>
                            )
                        }
                        
                    })}

                   
                </Box>
            </Dialog>
      

            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />


        </Fragment>   
    )
}

export default Payments



