// mui
import Box from '@mui/material/Box'


// REDUX
import { InfoTable } from '../../components/index'


import { Iinfo, Ieditinfo } from '../../types/interface'

interface Iprops {
    info: Iinfo | undefined
    editInfo: Ieditinfo
}
const Info = (props:Iprops) => {
    const { info, editInfo } = props



    return (
        <Box className="flex__center-c" justifyContent="flex-start">      
            <InfoTable editInfo={editInfo} info={info} />
        </Box>
    )
}

export default Info