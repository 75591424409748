import { useEffect, Fragment, useState, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid'

// mui
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper'
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

// mui icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

// REACT ICONS


/* Data grid imports */
import { DataGrid, GridColDef, GridRowSelectionModel, GridRenderCellParams,useGridApiContext } from '@mui/x-data-grid';

/* Drag and drop  */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'


// REDUX
import { UpdateMenu,UpdateShop } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

import { TokenRefresh } from '../../functions/Token';
import { Imenu } from '../../types/interface';
import { SnackbarComponent, DragAndDropItemsDialog } from '../index'



dayjs.extend(weekOfYear)


const defaultWeek = dayjs().week()
const dynamodbList = ["takeaway","leftover"]
const lunchList = ["lunch","lunchweekend"]
const s3List = ["lunch","lunchweekend","menu"]

interface Iprop {
    info: {[key:string] : string} | undefined
    TypeOfMenu: string
    week?: number
    ChangeWeek?(props:number): void 
   
    parentMenu: Imenu [] 
    parentCategories:string []
    readOnlyCategories?:string []
    failedFetch: boolean
    fetchLoading: boolean

   
}
//* array with week numbers 1 - 52 for chosing week to see specific lunch menu
const allWeekNumbersArray = Array.from({length: 52}, (_, i) => (i + 1).toString())

const MenuTable = (props:Iprop) => {
    const { info, TypeOfMenu, parentMenu, parentCategories, week, ChangeWeek, failedFetch, fetchLoading} = props
            
            
  
    const dispatch: AppDispatch = useDispatch()


    // Rerenders the states from parent
    useEffect(() => {
        setMenu( parentMenu)
        setCategories( parentCategories )
        setMenuReadOnly( parentMenu)
        setReadOnlyCategories( parentCategories )
        //setColumnState(columns)
    
        
        if(failedFetch) {       
            setMessage(info?.FailedFetch)
            setAlert("error")                        
        }
        setLoading(fetchLoading)
        return () => {
            
        }
    }, [parentMenu,parentCategories,failedFetch,fetchLoading,info?.FailedFetch]);
    
  
    
 
              
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [loading, setLoading] = useState(fetchLoading)    
    const [menu, setMenu] = useState<Imenu []>(parentMenu)    
    const [menuReadOnly, setMenuReadOnly] = useState<Imenu [] | undefined>(parentMenu)    
        
    const [categoryTextField, setCategoryTextField] = useState("")
    const [message, setMessage] = useState<string | undefined>("")    
    const [alert, setAlert] = useState<string>("")
    const [categories, setCategories] = useState<string []>(parentCategories)
    const [readOnlyCategories, setReadOnlyCategories] = useState<string []>(parentCategories)

    const [updateDialog, setUpdateDialog] = useState(false)  
    
    const [newMenuItems, setNewMenuItems] = useState<Imenu []>([])
    const [updatedMenuItems, setUpdatedMenuItems] = useState<Imenu []>([])
    const [removedMenuItems, setRemovedMenuItems] = useState<GridRowSelectionModel>([])
       

    //console.log("menu",menu)
    //console.log("removedItems",removedMenuItems)
    //console.log("newItems",newMenuItems)
    
    //*  Selection Component for a column x row */ 
    function SelectEditInputCell(props: GridRenderCellParams) {
        const { id, value, field } = props;
        
        const apiRef = useGridApiContext();
        const tempCatArr = [...categories,""]
        
        const handleChange = async (event: SelectChangeEvent) => {
        
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };
      
        return (
          <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
            autoFocus
          >
            {tempCatArr?.map((category,i) => <option key={`option${category}${i}`}>{category}</option> )}                        
          </Select>
        );
    }
    const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {         
        return <SelectEditInputCell {...params}  />;
    };
  
    //* Uploads Changed data to serer
    const SaveChanges = async() => {
        setLoading(true)
        setMessage("")        

        const DispatchSelector = () => {

            // lunch / lunchweekend / menu
            if(s3List.includes(TypeOfMenu)){
                return dispatch(UpdateMenu({menu, categoryArray:categories, week, typeOfMenu: TypeOfMenu}))

            // leftover or takeaway
            }else if(dynamodbList.includes(TypeOfMenu)){
                return dispatch(UpdateShop({newMenuItems, updatedMenuItems, removedMenuItems,  categoryArray:categories, typeOfMenu: TypeOfMenu}))            

            // menu
            }else{
                return dispatch(UpdateMenu({menu, categoryArray:categories, week:0, typeOfMenu: TypeOfMenu}))
            }
        }
    
        const res = await TokenRefresh()                                         
        if(res !== 200) {setLoading(false) ;return}

        

        await DispatchSelector().unwrap()
            .then((res:any) => {               
                if(res?.statusCode === 200){               		
                    ResetAllButTheMenu()				                                                           
                    setMessage(info?.UpdateSuccess || "Success")
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    //* Resets Everything to before
    const ResetAll = () => {
        setMenu([...menuReadOnly || []])
        setCategories([...readOnlyCategories])
        setCategoryTextField("")
        setNewMenuItems([])
        setUpdatedMenuItems([])
        setRemovedMenuItems([])
    }
    const ResetAllButTheMenu = () => {        
        setCategoryTextField("")
        setNewMenuItems([])
        setUpdatedMenuItems([])
        setRemovedMenuItems([])
    }

    const columns:any = {
        "takeaway" :[
            /*  { field: 'id', headerName: 'ID', width: 90 }, */
                {
                    field: 'title',
                    headerName: 'Title',
                    minWidth: 200,
                    editable: true,
                    
                    renderCell: (params: any) =>  {               
                        return (
                            <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                                <span className="table-cell-trucate">{params.value}</span>
                            </Tooltip>
                            )
                    }
                
                
                },
                {
                    field: 'category',
                    headerName: 'Category',
                    minWidth: 200,
                    editable: true,
                    renderEditCell: renderSelectEditInputCell,
                },
                {
                    field: 'info',
                    headerName: 'Info',          
                    minWidth: 200,
                    editable: true,
                    renderCell: (params: any) =>  {               
                        return (
                            <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                                <span className="table-cell-trucate">{params.value}</span>
                            </Tooltip>
                            )
                    }
                },
                {
                    field: 'price',
                    type: 'number',
                    headerName: 'Price',                                
                    minWidth: 200,
                    editable: true,            
                    renderCell: (params: any) =>  {               
                        return (
                            <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                                <span className="table-cell-trucate">{params.value}</span>
                            </Tooltip>
                            )
                    }
                },
                {
                    field: 'quantity',
                    type: 'number',
                    headerName: 'Quantity',                                 
                    minWidth: 200,
                    editable: true,            
                    renderCell: (params: any) =>  {               
                        return (
                            <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                                <span className="table-cell-trucate">{params.value}</span>
                            </Tooltip>
                            )
                    }
                },
                {
                    field: 'active',
                    type: 'boolean',
                    headerName: 'Active',                                
                    minWidth: 200,
                    editable: true,                               
                }    
        ],
        "lunchweekend":[
            {
                field: 'title',
                headerName: 'Title',
                minWidth: 200,
                editable: true,
                
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            
            
            },
            {
                field: 'category',
                headerName: 'Category',
                minWidth: 200,
                editable: true,
                renderEditCell: renderSelectEditInputCell,
            },
            {
                field: 'info',
                headerName: 'Info',          
                minWidth: 200,
                editable: true,
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'price',
                type: 'number',
                headerName: 'Price',                                
                minWidth: 200,
                editable: true,            
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
        ],
        "menu": [
            {
                field: 'title',
                headerName: 'Title',
                minWidth: 200,
                editable: true,
                
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            
            
            },
            {
                field: 'category',
                headerName: 'Category',
                minWidth: 200,
                editable: true,
                renderEditCell: renderSelectEditInputCell,
            },
            {
                field: 'info',
                headerName: 'Info',          
                minWidth: 200,
                editable: true,
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'price',
                type: 'number',
                headerName: 'Price',                                
                minWidth: 200,
                editable: true,            
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
        ],
        "leftover":[
            {
                field: 'title',
                headerName: 'Title',
                minWidth: 200,
                editable: true,
                
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            
            
            },
            {
                field: 'category',
                headerName: 'Category',
                minWidth: 200,
                editable: true,
                renderEditCell: renderSelectEditInputCell,
            },
            {
                field: 'info',
                headerName: 'Info',          
                minWidth: 200,
                editable: true,
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'price',
                type: 'number',
                headerName: 'Price',                                
                minWidth: 200,
                editable: true,            
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'oldPrice',
                type: 'number',
                headerName: 'Old Price',                                
                minWidth: 200,
                editable: true,            
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'quantity',
                type: 'number',
                headerName: 'Quantity',                                 
                minWidth: 200,
                editable: true,            
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'active',
                type: 'boolean',
                headerName: 'Active',                                
                minWidth: 200,
                editable: true,                               
            } 
        ],
        "lunch":[
            {
                field: 'title',
                headerName: 'Title',
                minWidth: 200,
                editable: true,
                
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            
            
            },
            {
                field: 'category',
                headerName: 'Category',
                minWidth: 200,
                editable: true,
                renderEditCell: renderSelectEditInputCell,
            },
            {
                field: 'info',
                headerName: 'Info',          
                minWidth: 200,
                editable: true,
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
            {
                field: 'price',
                type: 'number',
                headerName: 'Price',                                
                minWidth: 200,
                editable: true,            
                renderCell: (params: any) =>  {               
                    return (
                        <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                            <span className="table-cell-trucate">{params.value}</span>
                        </Tooltip>
                        )
                }
            },
        ]
    }
   

  
    //* If TypeOfMenu === "lunch" then when a week is changed, fetch the new lunchmenu  */
   /*  const FetchMenu = async(week:string) => {
        try {                        
            setLoading(true)
            if(TypeOfMenu === "lunch"){        
                await dispatch(GetLunch({week:week}))
                    .unwrap()
                    .then((data:any) => {                     
                        setLoading(false)                                                   
                        setMenu([...data?.Menu || []])                                                                
                        setMenuReadOnly([...data?.Menu || []])
                        setCategories([...data?.Category || []])
                        setReadOnlyCategories([...data?.Category || []])        
                  
                
                })
            }else{
                await dispatch(GetMenu({}))
                    .unwrap()
                    .then((data:any) => {                     
                        setLoading(false)                                                   
                        setMenu([...data?.Menu || []])                                                                
                        setMenuReadOnly([...data?.Menu || []])
                        setCategories([...data?.Category || []])
                        setReadOnlyCategories([...data?.Category || []])           
                
                })
            }

            
        } catch (error) {
            setMessage(info?.FailedFetch)
            setAlert("error")
            setLoading(false)
        }
    } */
        
   
    //* Removes items from menu
    const RemoveItems = () => {
        let tempRow = [...menu || [] ]
        let tempNewMenuItems = [...newMenuItems || [] ]
        let tempUpdatedMenuItems = [...updatedMenuItems || [] ]
        let tempSelect = [...rowSelectionModel]        
        

        // loop through all items which has been selected for removal
        for (let i = 0; i < tempSelect.length; i++) {
            const selectedId = tempSelect[i];
            const foundMenuIdIndex = tempRow?.findIndex((row) => row.id === selectedId)

            // if it is takeaway or other menu which uses dynamodb, remove items from a separate list
            if(dynamodbList.includes(TypeOfMenu)) {
                
                const foundNewItemIdIndex = tempNewMenuItems?.findIndex((row) => row.id === selectedId)
                
                // if it is a newly added in newMenuItem state then remove that from that state array
                if(foundNewItemIdIndex !== -1) {
                    tempNewMenuItems?.splice(foundNewItemIdIndex,1)
                    setNewMenuItems([...tempNewMenuItems])

                // if it is a old item in menu state then add the id to the remove list
                }else if(foundMenuIdIndex !== -1){
                    setRemovedMenuItems(prev => ([...prev, selectedId]))
                    const foundUpdatedItemIdIndex = tempUpdatedMenuItems?.findIndex((row) => row.id === selectedId)

                    // if this is an old item which has been updated then remove it from the updateditem list
                    if(foundUpdatedItemIdIndex !== -1){
                        tempUpdatedMenuItems?.splice(foundUpdatedItemIdIndex,1)
                        setUpdatedMenuItems([...tempUpdatedMenuItems])
                    }
                }
            }
                        
            // if it is standard menu or lunch which uses s3 , remove item from menu list
            if(foundMenuIdIndex !== -1) {                         
                tempRow?.splice(foundMenuIdIndex,1)                        
            }
            
        }                
       
        setMenu([...tempRow])

        
    }       
    //* Add New Menu Item
    const AddNewMenuItem = () => {
        const tempId = uuidv4()
        const newItem: Imenu = {
            id: tempId,
            title:"",
            info:"",
            category:"",
            price:0,
            quantity:0,
            active:false,
        }
        // if it is takeaway or other menu which uses dynamodb, add items to a separate list
        if(dynamodbList.includes(TypeOfMenu)) {
            if(TypeOfMenu === "leftover" ) {newItem["oldPrice"] = 0}
            setNewMenuItems([newItem,...newMenuItems || [] ])

        }


        setMenu([newItem,...menu || [] ])
    }
    
    


    //* Category Section | add new category, rearange the order, remove an old category
    //* Drag and Drop functions */
    const reorder = (list:any, startIndex:any, endIndex:any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };
    const handleDrop = (droppedItem: any) => {
        
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;


        const sourceIndex = droppedItem.source.index;
        const destIndex = droppedItem.destination.index;
        if(droppedItem.type === "MENUTYPES"){    
            const newMenu:any = reorder(categories, sourceIndex, destIndex)
            setCategories([...newMenu]);
           
        
        }

    };
    const changeOrder = (dir:string,i:number) =>{   
        var tempArr:any = categories
        var value = categories[i]
        tempArr.splice(i,1)
        if(dir==="up"){
        tempArr.splice(i+1,0,value)
        }else{
        tempArr.splice(i-1,0,value)
        }  
        setCategories([...tempArr])
        
    } 
    const AddNewCategory = (newCategory:string) => {
        const stringNewCategory = newCategory.toString()
        if(!stringNewCategory) return
        if(categories?.includes(stringNewCategory)) return
        let tempCategoryArray = [...categories]
        tempCategoryArray.push(stringNewCategory)                                      
        
        setCategories([...tempCategoryArray])
        setCategoryTextField("")
    }
    const DeleteCategory = (index:number) => {
        let tempCategoryArray = [...categories]
        tempCategoryArray.splice(index,1)
        setCategories([...tempCategoryArray])
    }

    const CloseUpdateDialog = () => {        
        setUpdateDialog(false)
    };



    const CompareSimpleObjects = (oldRow:Imenu,newRow:Imenu) => {
     
        let filteredObject = {} as Imenu
        filteredObject["id"] = oldRow.id
        Object.keys(oldRow).map((oldKey,i) => {
            if(oldKey === "id") return
            const oldItem = oldRow[oldKey]
            Object.keys(newRow).map((newKey,i) => {
            const newItem = newRow[newKey]
            if(newKey === "id") return
            
            if(oldItem !== newItem && oldKey === newKey) {
                filteredObject[newKey] = newItem
            }
            
            })	
        })

     
        return filteredObject 
    }

    return (
        <Fragment>

            {/* Save, Reset Button, Select week */}
            <Container                                
                sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
            >   
                {/* Save Button */}
                <Button
                    sx={{minWidth: '180px'}}
                    variant="contained"
                    color='success'
                    disabled={loading}
                    onClick={SaveChanges}
                    endIcon={<SaveIcon />}
                >
                {info?.Save}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "teal",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />
                )}
                </Button>

                {/* Reset Button */}
                <Button variant="outlined" color="warning" sx={{minWidth: '180px'}} onClick={ResetAll}>
                    {info?.Reset}
                </Button>

                {/*  Pick Which Week is selected */}
                { lunchList.includes(TypeOfMenu) &&
                <Box className="flex__center-c" sx={{margin:"1rem 0"}}>
                    <Autocomplete
                        color="text.secondary"
                        disablePortal                        
                        sx={{label:{color:"text.primary", minWidth: "25rem"}}}
                        options={allWeekNumbersArray}
                        onChange={(event: React.SyntheticEvent, value: string | null , reason: string, details?: any):void => ChangeWeek && ChangeWeek(value ? parseInt(value) : defaultWeek)  }
                        value={week ? week.toString() : defaultWeek.toString() }                        
                        renderInput={(params) => <TextField {...params} label="Week Number" />}
                    />
                    
                </Box>}

            
            </Container>

            <CssBaseline /> 


            {/* Toolbar with Name of the table, selected items and delete button */}
            <Toolbar
        
                sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(rowSelectionModel.length > 0 && {
                    bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                }}
            >
                {rowSelectionModel.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {rowSelectionModel.length} {info?.Selected}
                    
                </Typography>
                
                ) : (
                <Box className="flex__center-r">
                        
                    <Typography
                        //sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {info?.MainTitle}              
                    
                    </Typography>
                    <Button onClick={AddNewMenuItem} sx={{marginLeft:"1rem"}} variant="outlined" size="small" startIcon={<AddIcon />}>
                        {info?.AddNewItem}
                    </Button>
                    <Button onClick={()=>setUpdateDialog(true)} sx={{marginLeft:"0.5rem",width:"100%",textWrap:"nowrap"}} variant="outlined" size="small" >
                        {info?.ChangeOrder}
                    </Button>
                </Box>
                
                
                )}
            
            {rowSelectionModel.length > 0 && (
                <Tooltip title={info?.DeleteTooltip}>
                    <IconButton  onClick={RemoveItems}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            )}
        
            </Toolbar>

            {/* Data grid */}
            <Box sx={{ height: "100%", width: '100%' }}>
                <DataGrid
                    rows={menu || [] }
                    columns={columns[TypeOfMenu]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5,10,100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    {...menu}
                    processRowUpdate={ (newRow: any, oldRow: any) => {          
                        //console.log("oldRow",oldRow)
                        //console.log("newRow",newRow)
                        let rowId = menu?.findIndex( row => row.id === newRow?.id)

                        // if it is takeaway or other menu which uses dynamodb, change correct item in a separate list
                        if(dynamodbList.includes(TypeOfMenu)) {
                            let readOnlylMenuId = menuReadOnly?.findIndex( row => row.id === newRow?.id)
                            //console.log("readOnlylMenuId",readOnlylMenuId)
                            let newItemId = newMenuItems?.findIndex( row => row.id === newRow?.id)
                            let updatedItemId = updatedMenuItems?.findIndex( row => row.id === newRow?.id)

                            // if it is a new item then change that item in the newMenuItems list
                            if(newItemId !== -1){                                
                                let tempNewMenuItemsArr = [...newMenuItems || [] ]
                                tempNewMenuItemsArr[newItemId ? newItemId : 0 ] = newRow
                                setNewMenuItems([...tempNewMenuItemsArr])        


                            // if it is an old item which have been changed before
                            }else if(updatedItemId !== -1 && readOnlylMenuId !== -1 && menuReadOnly ){                                
                                let tempUpdateMenuItemsArr = [...updatedMenuItems || [] ]
                                let tempNewRow = CompareSimpleObjects(menuReadOnly[readOnlylMenuId ? readOnlylMenuId : 0] ,newRow)

                                // if the old item has more keys than just id then add it to the updatedMenuItems list
                                if(Object.keys(tempNewRow).length > 1){
                                    tempUpdateMenuItemsArr[updatedItemId ? updatedItemId : 0 ] = tempNewRow
                                    setUpdatedMenuItems([...tempUpdateMenuItemsArr])        

                                // if the old item has only ONE key Which should be id then remove it from the updatedMenuItems list
                                }else if(Object.keys(tempNewRow).length === 1){
                                    tempUpdateMenuItemsArr.splice(updatedItemId,1)
                                    setUpdatedMenuItems([...tempUpdateMenuItemsArr])
                                }

                            // if it is an old item which has NOT been changed before
                            }else if(menuReadOnly){                                
                                let tempNewRow = CompareSimpleObjects(menuReadOnly[readOnlylMenuId ? readOnlylMenuId : 0] ,newRow)
                                setUpdatedMenuItems([tempNewRow,...updatedMenuItems || [] ])
                            }

                            

                        }
                        
                        let tempRowArr = [...menu || [] ]
                        tempRowArr[rowId ? rowId : 0 ] = newRow
                        setMenu([...tempRowArr])
                        return newRow
                    }}
                    onProcessRowUpdateError={(error: any) => console.log("error",error) }
                
                />
            </Box>


    
            {/* Drag and drop array for categories */}
            <Paper sx={{marginTop:"2rem",padding:"1rem 0"}} className="flex__center-c">

                {/* Title */}
                <Typography variant='h3' paragraph>
                    {info?.CategoriesTitle}
                </Typography>

                {/* Add a new Category */}
                <Box className="flex__center-r">
                    <TextField 
                        sx={{label:{color:"text.primary"}}}
                        label="Add A New Category"
                        size='small'
                        value={categoryTextField}                    
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCategoryTextField(event?.target?.value) }
                    />                                                
                    <Button onClick={()=>AddNewCategory(categoryTextField)} sx={{marginLeft:"1rem"}} variant="outlined" size="small" startIcon={<AddIcon />}>
                        {info?.AddNewItem}
                    </Button>
                </Box>


                {/* Drag and drop elements */}
                <DragDropContext onDragEnd={handleDrop} >
                    <Droppable droppableId="Droppable-container" type='MENUTYPES'>
                    {(provided:any) => (
                        <div
                        
                        
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        {categories.map((item:string, i) => (              
                            <Draggable key={`draggable${i}`} draggableId={`draggableId${i.toString()}`} index={i} > 
                            {(provided) => (
                            
                                    <div

                                    
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                    >
                                
                                        <Paper sx={{margin:"0.5rem", padding:"0.5rem", minWidth:"15rem",justifyContent:"space-between"}} className='flex__center-r'>
                                            <Box>
                                                <KeyboardArrowDownIcon className="ImArrow" onClick={()=>changeOrder("up",i)}/>
                                                <KeyboardArrowUpIcon className="ImArrow" onClick={()=>changeOrder("down",i)}/>
                                            </Box>
                                            
                                            {item}
                                            <IconButton sx={{alignSelf:"flex-end"}} onClick={()=>DeleteCategory(i)}>
                                                <DeleteIcon />
                                            </IconButton>
                                            
                                        </Paper>
                                                                                                                                                    
                                    </div>
                            
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>     
    
            </Paper>

            <DragAndDropItemsDialog
                rows={menu}
                setRows={setMenu}
                info={info as { [key:string]: string}}
                updateDialog={updateDialog}
                CloseUpdateDialog={CloseUpdateDialog}
            />

        

          <SnackbarComponent alert={alert} setAlert={setAlert} message={message} setMessage={setMessage} />

        </Fragment>   

    )
}

export default MenuTable