import { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'


import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

import { Iinfo } from '../../types/interface'

interface Iprops {
    info: Iinfo
    date: number// Dayjs | null
    setDate: (p: React.SetStateAction<number>) => void
    open: boolean
    close: (p:React.SetStateAction<boolean>) => void
 
}
const TimeDatePicker = (props:Iprops) => {
    const { info, setDate, date, open, close } = props

    const [value, setValue] = useState<Dayjs | null>(dayjs(date).hour(0).minute(0).second(0).millisecond(0));    
    const Submit = () => {
        
        setDate( dayjs(value).valueOf() )
        close(false)
    }
    return (
        <Dialog  
            open={open}
            onClose={()=>close(prev => !prev)}>
            <Box sx={{mt:"1rem"}} className="flex__center-c">

                <Box>               
                    <StaticDateTimePicker value={ value } onChange={(newValue) => setValue(newValue)} ampm={false}
                        //onAccept={Submit}
                        slotProps={{
                            actionBar: {
                                // The actions will be the same between desktop and mobile
                                actions: []
                            }
                        }}
                    
                    />
                </Box>
                <Box className="flex" justifyContent={"space-between"}>
                    <Button onClick={()=>close(false)}>Close</Button>
                    <Button onClick={Submit}>Accept</Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default TimeDatePicker