// REDUX
import { GetLunch, GetLunchWeekend,GetMenu, UpdatePdf, GetPdf, GetShop } from '../slices/tokenReducer'; 
import store from '../store'

import { ReStructureMenu } from './ReStructureMenu'
import { Ifoundcategories } from '../types/interface'

export const FetchMenuData =  async(type:string,week:number | null = null) => {
    try {
        var category:string [] = [],
            menu = {},
            statusCode,
            alert = "",
            message = ""
        
        const DispatchSelector =  () => {
            switch (type) {
                case "lunch":
                    return store.dispatch(GetLunch({week:week}))            
                case "lunchweekend":
                    return store.dispatch(GetLunchWeekend({week:week}))            
                case "menu":
                    return store.dispatch(GetMenu({}))            
                case "takeaway":
                case "leftover":
                    return store.dispatch(GetShop({typeOfMenu:type}))                                                    
                default:
                    return store.dispatch(GetMenu({}))                                    
            }
        }

        await DispatchSelector().unwrap()
            .then((res) => {      

                if(res?.statusCode === 200){
                   
                    category = res?.body?.data?.Category
                    
                    
                    if(type === "takeaway" || type === "leftover"){
                        const foundItems = res?.body?.data?.filter((item:any) => item.sk === "takeaway" && item.pk !== "category" )
                        const foundCategory:Ifoundcategories = res?.body?.data.filter((item:any) => item.sk === "takeaway" && item.pk === "category" )[0]

                        
                        menu = ReStructureMenu(foundItems, foundCategory.categories)
                        category = foundCategory.categories
                        statusCode = res?.statusCode                            
                            

                        //setMenu(ReStructureMenu(foundItems, foundCategory[0].categories))
                        //setMarginArray(createArray( foundCategory[0].categories ) )
                        //setCategory(foundCategory[0].categories)
                    }else{

                        
                        menu = ReStructureMenu(res?.body?.data?.Menu, res?.body?.data?.Category)
                        statusCode = res?.statusCode

                        //setMenu(ReStructureMenu(res?.body?.data?.Menu, res?.body?.data?.Category))                                                
                        //setMarginArray(createArray( res?.body?.data?.Category))
                        //setCategory(res?.body?.data?.Category)
                    }
                    
                }else{
                                        
                    statusCode = res?.statusCode  
                    alert = "warning"
                    message = "This type of menu is not created yet"
                    

                    //setAlert("error")
                    //setMessage(info?.Pdf?.FailedFetch)
                    //setFailedFetch(true)            
                }
                //setFetchLoading(false)
                
        })              
    } catch (error) {            
        console.log(error)
        return {
            menu: {} ,
            category: [] as string [],
            statusCode: 500,
            alert: "error",
            message: "Failed to fetch data"
        }
        //setAlert("error")
        //setMessage(info?.Pdf?.FailedFetch)
        //setFailedFetch(true)
    } 
    return {
        menu,
        category,
        statusCode,
        alert,
        message
    }
}