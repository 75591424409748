import React, { useEffect, useState } from 'react';

//mui
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';

//redux
import { UpdateReservationStatus } from '../../slices/tokenReducer'
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

import { Icalendar, Iinfo, Ireservation } from '../../types/interface';
import { GetTimeRead } from '../../functions/GetTime';
import { TokenRefresh } from '../../functions/Token';
import SnackBarComponent from '../SnackbarComponent/SnackbarComponent';




interface SimpleDialogProps {
    open: boolean
    selectedValue: Ireservation
    setOpenDialog: (p:React.SetStateAction<boolean>)=> void
    info: Iinfo
    setRows:(p:React.SetStateAction<Icalendar []>) => void
    readOnly?: boolean
}
const ReservationDialog = (props: SimpleDialogProps) => {
    const {  selectedValue, open,setOpenDialog,info,setRows, readOnly=false} = props;
    
    const dispatch: AppDispatch = useDispatch()

    const [itemLoading, setItemLoading] = useState<boolean>(false)
    const [data, setData] = useState<Ireservation>(selectedValue as Ireservation)
    //console.log("data",data)
    const [message, setMessage] = useState<string>("")
    const [alert, setAlert] = useState<string>("")

    useEffect(()=>{
        setData(selectedValue)
        return
    },[selectedValue])

    const startTime = GetTimeRead(selectedValue?.time)
    const endTime = GetTimeRead(selectedValue?.time+selectedValue?.duration)

    const SaveChanges = async() => {
        setItemLoading(true)
        setMessage("")
       
        const res = await TokenRefresh()                                 
        if(res !== 200) {setItemLoading(false);return}

        
        let dataToSend = {
            status: !data?.status,
            pk: data?.pk
        }
        
        await dispatch(UpdateReservationStatus({updatedRow: dataToSend }))
            .unwrap()
            .then((res) => {
               
                if(res?.statusCode === 200){               					
                    setRows(prev => (
                        prev.map(item => item?.resource?.pk === dataToSend.pk ? {
                            id:item?.id,
                            title:item?.title,
                            start: item?.start,
                            end:item?.end, 
                            resource: {
                                time: item?.resource?.time,
                                date: item?.resource?.date,
                                firstName: item?.resource?.firstName,
                                lastName: item?.resource?.lastName,
                                phone: item?.resource?.phone,
                                email: item?.resource?.email,
                                type: item?.resource?.type,
                                other: item?.resource?.other,
                                people: item?.resource?.people,
                                id: item?.resource?.id,
                                pk: item?.resource?.pk,
                                duration: item?.resource?.duration,
                                status: !item?.resource?.status
                                
                            }
                        } : item)        
                    ))    
                    setData(prev => ({...prev, status: dataToSend?.status}))	                                                           
                    setMessage(info?.TakeawayAuto?.UpdateSuccess || "Success")
                    setAlert("success")                    
                    setItemLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.TakeawayAuto?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setItemLoading(false)
                }
                
            })
    }

    if(selectedValue?.pk?.split("#")[0] === "closed" ){
        return (
            <Dialog onClose={()=>setOpenDialog(false)} open={open}>
                <DialogTitle variant='h4' >{`Restaurant Closed`}</DialogTitle>                
                <List sx={{ pt: 0 }}>                           
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_TimeStart} secondary={`${ new Date(selectedValue?.time).toLocaleString() } `} />
                    </ListItem>                
                    <ListItem >
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_TimeEnd} secondary={`${ new Date(selectedValue?.time+selectedValue?.duration).toLocaleString() } `} />                        
                    </ListItem>                                                            
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Message} secondary={`${data?.other || ""}`} />                                    
                    </ListItem>            
                
                </List>

                <SnackBarComponent alert={alert} setAlert={setAlert} message={message} setMessage={setMessage} />
                
            </Dialog>
        )
    }else{    
        return (
            <Dialog onClose={()=>setOpenDialog(false)} open={open}>
                <DialogTitle variant='h4' >{`${data?.firstName} ${data?.lastName}`}</DialogTitle>
                {!readOnly && 
                <Button
                    sx={{alignSelf:"center"}}
                    variant="contained"
                    color='success'
                    disabled={itemLoading}
                    onClick={SaveChanges}
                    endIcon={<SaveIcon />}
                >
                    {info?.ReservationsCalendar?.Button_Save}
                    {itemLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: "teal",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                </Button>
                }

                <List sx={{ pt: 0 }}>       
                    <ListItem >              
                        <Typography>
                            Status:  
                            <Chip icon={data?.status  ? <TaskAltIcon /> : <HighlightOffIcon /> }
                                color={data?.status ? "success" : "warning" } 
                                label={data?.status ? info?.ReservationsCalendar?.Dialog_Status : info?.ReservationsCalendar?.Dialog_Status_No} variant="outlined" size="small" sx={{textTransform:"capitalize"}}
                            />
                        </Typography>
                    </ListItem>  
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Time} secondary={`${startTime} - ${endTime}`} />
                    </ListItem>                
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Guests} secondary={`${data.people} ${info?.TimeTable?.ToolTip_Guests}`} />
                    </ListItem>
                    
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Email} secondary={`${data.email}`} />
                    </ListItem>
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Phone} secondary={`${data.phone}`} />
                    </ListItem>
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Type} secondary={`${data.type}`} />
                    </ListItem>
                    <ListItem >              
                        <ListItemText  primary={info?.ReservationsCalendar?.Dialog_Message} secondary={`${data?.other}`} />                                    
                    </ListItem>            
                
                </List>

                <SnackBarComponent alert={alert} setAlert={setAlert} message={message} setMessage={setMessage} />
                
            </Dialog>
        );
    }
}

export default ReservationDialog