import { useEffect, Fragment, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

// mui
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import { darken, lighten, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import SaveIcon from '@mui/icons-material/Save';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

/* Data grid imports */
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar  } from '@mui/x-data-grid';

// REDUX
import { GetPayments, UpdatePaymentsFast } from '../../slices/tokenReducer'; 
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface';
import { SnackbarComponent } from '../../components';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .row-bg--Ej_skickad': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
        backgroundColor: getHoverBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode,
        ),
        },
        '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode,
            ),
        },
        },
    },
    '& .row-bg--Skickad': {
        backgroundColor: getBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
        ),
        '&:hover': {
        backgroundColor: getHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        },
        '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
            ),
        },
        },
    },
    '& .row-bg--PartiallyFilled': {
        backgroundColor: getBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
        ),
        '&:hover': {
        backgroundColor: getHoverBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        },
        '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
            ),
        },
        },
    },
    '& .row-bg--Rejected': {
        backgroundColor: getBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
        ),
        '&:hover': {
        backgroundColor: getHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        },
        '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
            ),
        },
        },
    },
}));


interface Iprops {
    info: Iinfo
}
const TakeawayAuto = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()

    const [loading, setLoading] = useState(false)          

    interface ObjectAll {[key:string]:string|number|boolean}
    const [rows, setRows] = useState<Ipaymentinfo []>([])    
    const [oldData, setOldData] = useState<Ipaymentinfo []>([])


    const [message, setMessage] = useState<string | undefined>("")    
    const [alert, setAlert] = useState<string>("")    
    const [openInfoDialog, setOpenInfoDialog] = useState(false)
    const [selectedInfo, setSelectedInfo] = useState<Ipaymentinfo>({} as Ipaymentinfo)
    const [itemLoading, setItemLoading] = useState(false)
    

    //* GetPayments from db */
    useEffect(() => {
        setLoading(true)
        try {
           
            FetchData()
        } catch (error) {
            setMessage(info?.TakeawayAuto?.FailedFetch)
            setAlert("error")
            setLoading(false)
        }
        
        return () => {
        
        }
    }, [])
    useEffect(() => {
        const intervall = setInterval(() => FetchData(),30000)    
        return () => {
            clearInterval(intervall)
        }
    }, [])
    const FetchData = async() => {
                
        const res = await TokenRefresh()
        if(res !== 200) {
            setLoading(false)
            return     
        }           
        await dispatch(GetPayments({}))
        .unwrap()
        .then((res:any) => {
            
            if(res?.statusCode === 200){
                const filteredData  = res?.body?.data?.filter((item:Ipaymentinfo) => item?.id)
                setLoading(false)
                setOldData([...filteredData || []])
                setRows([...filteredData || []])                                                                
            }else{                
                setMessage(res?.body?.message || "Failed to fetch data")
                setAlert("error")
                setLoading(false)
            }
            
        })
        
    }


    interface Ipaymentinfo {    
        [key:string]: string|number|boolean | {[key:string]:string|number|boolean}
        "CreatedOn": number
        "lastName": string
        "status": string
        "paymentIntent": string
        "email": string
        "firstName": string
        "OrderSend": string
        "stripeInfo": {},
        "store_data": boolean,
        "id": string
        "pk": string
        "swishInfo": {
            [key:string]: string|number|boolean  
            "amount": number
            "datePaid":string
            "paymentReference":string
            "errorMessage":string
            "errorCode":string
            "message":string
            "payeePaymentReference":string
            "dateCreated":string
            "payerAlias":string
            "callbackUrl":string
            "currency":string
            "id":string
            "payeeAlias":string
            "status":string
        },
        "approveNewsletter": boolean,
        "phone": string
        "type": string
        
    }
    //* More info dialog
    const HandleOpenInfoDialog = (row:Ipaymentinfo) => {
        setOpenInfoDialog(true)
        setSelectedInfo(row)

    }
    const HandleCloseInfoDialog = () => {
        setOpenInfoDialog(false)
        setSelectedInfo({} as Ipaymentinfo)

    }
  

    const SaveChanges = async() => {
        setItemLoading(true)
        setMessage("")
       
        const res = await TokenRefresh()                                 
        if(res !== 200) {setItemLoading(false);return}

        const orderToSend = selectedInfo?.OrderSend === "Ej_skickad" ? "Skickad" : "Ej_skickad"
        const tempSelectedInfo = structuredClone(selectedInfo)
        tempSelectedInfo["OrderSend"] = orderToSend
        let dataToSend = {
                OrderSend: orderToSend,
                pk: selectedInfo?.pk
            }
        

        
        await dispatch(UpdatePaymentsFast({updatedRow: dataToSend }))
            .unwrap()
            .then((res) => {
               
                if(res?.statusCode === 200){               					
                    setRows(prev => (
                        prev.map(item => item.pk === tempSelectedInfo.pk ? tempSelectedInfo : item)        
                    ))    
                    setSelectedInfo(prev => ({...prev, OrderSend: orderToSend}))	                                                           
                    setMessage(info?.TakeawayAuto?.UpdateSuccess || "Success")
                    setAlert("success")                    
                    setItemLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.TakeawayAuto?.FailedUpdate || "Failed to Update data")
                    setAlert("error")
                    setItemLoading(false)
                }
                
            })
    }
    





    const columns: GridColDef[] = [       
        {
            field: 'OrderSend',
            headerName: 'Order send',
            width: 120,
            editable: false,            
           // renderEditCell: renderSelectEditInputCell,
            renderCell: (params: any) =>  {
                const value = params.value.replace("_"," ")                
                const state = value === "Skickad" ? true : false
                return (                    
                    <Chip icon={state ? <TaskAltIcon /> : <HighlightOffIcon /> } color={state ? "success" : "warning" } label={value} variant="outlined" size='small'/>                    
                )
            }                                 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            editable: false,
            renderCell: (params: any) =>  {
                const value = params.value                
                const state = value === "paid" ? true : false
                let color:any = "info"
                switch (value) {
                    case "paid":
                        color = "success"
                        break;                    
                    case "unpaid":
                        color = "warning"
                        break;                                    
                    default:
                        color ="error"
                        break;
                }
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        {/* <span className="table-cell-trucate">{params.value}</span> */}
                        <Chip icon={state ? <TaskAltIcon /> : <HighlightOffIcon /> }  color={color} label={value} variant="outlined" size="small" sx={{textTransform:"capitalize"}}/>
                    </Tooltip>
                )
            }
        
        
        },      
        {
            field: 'firstName',
            headerName: 'Name',
            minWidth: 120,
            editable: false,
            flex:1,
            renderCell: (params: any) =>  {                           
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value} {params?.row?.lastName}</span>
                    </Tooltip>
                    )
            }
        
          
        },          
       {
            field: 'CreatedOn',
            headerName: 'Date',                    
            //type: 'number',
            flex:1,
            minWidth: 200,
            editable: false,
            valueGetter: ({ value }) =>  new Date(parseInt(value)).toLocaleString("sv-SE", {timeZone: 'Europe/Stockholm' }), //value && new Date(value),//{console.log("value",value*1000);value && new Date(value).toLocaleDateString()},
            renderCell: (params: any) =>  {                         
                let displayVal =  new Date(params.value).toLocaleString("sv-SE", {timeZone: 'Europe/Stockholm' })                
                return (
                    <Tooltip title={displayVal} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{displayVal}</span>
                    </Tooltip>
                    )
            }
        },              
        {
            field: 'totalAmount',
            headerName: 'Amount',          
            minWidth: 110,
            flex:1,
            editable: false,
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value} SEK</span> 
                    </Tooltip>
                    )
            }
        
        }                
    ];
    const columnsPurchasedItems: GridColDef[] = [        
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 150,            
            editable: false,
     
        },  
        {
            field: 'price',
            headerName: 'Price',
            width: 80,
            editable: false,
     
        },  
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 80,
            editable: false,
     
        },          
        {
            field: 'sk',
            headerName: 'Type',          
            minWidth: 110,
            editable: false,
         
        },
        {
            field: 'id',
            headerName: 'id',          
            minWidth: 110,
            editable: false,
         
        },                   
    ];

    return (
        <Fragment>          

            <CssBaseline /> 


           

            {/* Data grid */}
            <Box sx={{ height: "auto", width: '100%' }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    density="comfortable"
                    slots={{ toolbar: GridToolbar }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 20,
                            },
                        },
                        sorting: {
                            sortModel:  [{ field: 'CreatedOn', sort: "desc" }],                            
                        },
                        filter:{
                            filterModel: {
                                items: [{ field: 'expired_status', operator: 'contains', value: "complete" }]
                            },
                        }
                    }}
                    pageSizeOptions={[20,50,100]}
                    onRowClick={(params)=>HandleOpenInfoDialog(params?.row)}
                    getRowClassName={(params) => `row-bg--${params.row.OrderSend}`}                                      
                    disableRowSelectionOnClick                  
                    {...rows}                  
                />
            </Box>


            {/* open information for a single item */}
            <Dialog                
                open={openInfoDialog}
                onClose={HandleCloseInfoDialog}
                //sx={{height:"100%"}}
                fullWidth
                //fullScreen
            >
                <Box sx={{p:"1rem"}}>
                    <Box className="flex__center-c" alignItems="flex-start">
                        <Button
                            sx={{alignSelf:"center"}}
                            variant="contained"
                            color='success'
                            disabled={itemLoading}
                            onClick={SaveChanges}
                            endIcon={<SaveIcon />}
                        >
                            {info?.TakeawayAuto?.Save_Button}
                            {itemLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    color: "teal",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                        <Typography variant='h5'>
                            id:  {selectedInfo?.id}
                        </Typography>

                        <Typography>
                            Name:  {selectedInfo?.firstName} {selectedInfo?.lastName}
                        </Typography>                                                                    

                        <Typography>
                            Email:  {selectedInfo?.email}
                        </Typography>

                        <Typography>
                            Phone:  {selectedInfo?.phone}
                        </Typography>

                        <Typography>
                            Order send: 
                            <Chip icon={selectedInfo?.OrderSend === "Skickad" ? <TaskAltIcon /> : <HighlightOffIcon /> } color={selectedInfo?.OrderSend === "Skickad" ? "success" : "warning" } label={selectedInfo.OrderSend ? selectedInfo.OrderSend.replace("_"," ") : ""} variant="outlined" size='small'/>                            
                        </Typography>

                        <Typography>
                            Status:  
                            <Chip icon={selectedInfo?.status === "paid" ? <TaskAltIcon /> : <HighlightOffIcon /> }  color={selectedInfo?.status === "paid" ? "success" : "warning" } label={selectedInfo?.status} variant="outlined" size="small" sx={{textTransform:"capitalize"}}/>
                        </Typography>

                        
                        <Typography>
                            Date:  {new Date((selectedInfo?.CreatedOn)).toLocaleString("sv-SE", {timeZone: 'Europe/Stockholm' })     }
                        </Typography>
                        
                    </Box>
                    {Object.keys(selectedInfo).map((key:string,i) => {
                        const keysToInclude = [""]
                        const value = selectedInfo[key]
                        
                        if( (typeof value === "string" || typeof value === "number" ||typeof value === "boolean") && keysToInclude.includes(key) ){
                        
                            return(
                                <Box key={`paymentsdetails${key}${i}`}>
                                    <Typography>
                                        {key}:  {value}
                                    </Typography>
                                    
                                </Box>
                            )
                        }else if(Array.isArray(value) && key === "purchased_Items"){
                            
                            const sum = value.reduce((sum, item) => parseInt(sum) + parseInt(item?.price)*parseInt(item?.quantity),0)                            
                            const tempRow:GridColDef[] = []
                            value.forEach((arritem,j) => {
                                arritem["id"] = arritem?.pk
                                tempRow.push(arritem)

                            })
                            return(
                                <Box sx={{m:"2rem 0",width:"100%"}} key={`paymentsdetails${key}${i}`}>
                                    <Typography variant='h4'>
                                        {info?.TakeawayAuto?.Text_Items_Purchased}                                        
                                    </Typography>
                                    <Typography variant='h4' sx={{fontWeight:900}}>                                        
                                        {info?.TakeawayAuto?.Total_Sum} {sum} {info?.TakeawayAuto?.Currency_Sum}
                                    </Typography>
                                    <DataGrid
                                        autoHeight
                                        sx={{width:"100%"}}
                                        hideFooter
                                        rows={tempRow}
                                        density="compact"
                                        columns={columnsPurchasedItems}                                                    
                                    />                                                                                                                                                                                                               
                                </Box>
                            )
                        }
                        
                    })}

                   
                </Box>
            </Dialog>
      

            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />


        </Fragment>   
    )
}

export default TakeawayAuto



