import { useEffect, useState, forwardRef, memo, useMemo } from 'react';
import Resizer from "react-image-file-resizer";
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

//mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

//self
import { Iinfo, Iimage } from '../../types/interface';



/* Dropzone Style */
const dropzoneStyle = {
    baseStyle: {
        flex: 1,
        display: 'flex',
        FlexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    focusedStyle: {
        borderColor: '#2196f3'
    },
    acceptStyle: {
        borderColor: '#00e676'
    },
    rejectStyle: {
        borderColor: '#ff1744'
    }

}
interface Iprops {
    setMessage: (str:string) => void
    setAlert: (str:string) => void
    setImage: (str:Iimage) => void
    info: Iinfo
    width: number
    height: number
    type: string
    quality: number
    category: string
    image: Iimage
}
const DragDrop = (props:Iprops) => {
    const { setMessage, setAlert, setImage, info, width, height, type, quality, category, image } = props
   

    const ClearImages = () =>{
        setImage({ key:"",file:"",type:""})
 
    }
    

    //* Resize and covert image to webp via drag and drop
    const resizeFile = (file:any) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        width,            // max width
        height,            //max height
        type,         // file output
        quality,             // 100 best quality, 50, middle, 1 lowest quality
        0,
        (uri) => {
        resolve(uri);
        },
        "base64"
    );
    });
    const onDrop = async (acceptedFiles:any) => {      
        try {          

            for (let i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                const compressedImage:any = await resizeFile(file);             
                const type_lowercase = type.toLowerCase()
                let newFile = {
                    key: `${category}/${uuidv4()}.${type_lowercase}`,
                    file: compressedImage,
                    type: `image/${type_lowercase}`
                }
                setImage(newFile)                                       
            }
                
            
            
        } catch (error) {           
            setMessage(info?.Gallery?.FileError)
            setAlert("error")                
        }  
    }
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {'image/*': []}});
    const style = useMemo(() => ({
        ...dropzoneStyle?.baseStyle,
        ...(isFocused ? dropzoneStyle?.focusedStyle : {}),
        ...(isDragAccept ? dropzoneStyle?.acceptStyle : {}),
        ...(isDragReject ? dropzoneStyle?.rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    

      

    return (     
        <Box className='flex__center-c'>
            <div>
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p>{info?.Newsletter?.DropAndDrag}</p>
                </div>
            </div>
            {(image?.file || image?.key) &&
            <Box className="flex__center-c">

                <img
                    src={image?.file || `https://${process.env.REACT_APP_CDN_URL}/${image?.key}`}            
                    style={{ width: '10rem', height: '10rem', objectFit:'cover'}}
                    alt={"newsletter"}
                    loading="lazy"
                />

                <Button variant='contained' color='warning' onClick={ClearImages}>
                    {info?.DragDrop?.Button_Remove}
                </Button>

            </Box>
            }
            

        </Box>
    )
}

export default DragDrop